import React from 'react'
import './index.less'


export default function TypeModal(props) {
  const { options, visible } = props
  const {
    type = '',
    data = [],
    onChecked = () => {
    },
    onClick = () => {
    }
  } = options || {}

  return (
    <div className="type-modal-wrapper">
      <div
        className={`modal-wrapper ${visible ? 'show' : ''} ${type === 'memType' ? 'memType' : type === 'soft' ? 'soft' : type === 'levelType' ? 'levelType' : 'member'}`}
        onClick={(event) => onClick(event)}
      >
        <div className={`modal ${visible ? 'show' : ''}`}>
          {data.map((it) => (
            <div className={` ${type === 'member' ? 'member_item' : 'checked_item'}`} key={it.value}
                 onClick={() => onChecked(it.value)}>
              <div className={`${type === 'member' ? 'checked_item_wrapper_member' : 'checked_item_wrapper'}`}>
                <span className={it.isChecked?'active':'default'}>{it.label}</span>
                {/*{*/}
                {/*  it.isChecked ?*/}
                {/*    <div>*/}
                {/*      <img src="https://cdn.kangarooread.com/mp-assets/m-web/checked.png"*/}
                {/*           className={`${type === 'member' ? 'checked_member' : 'checked'}`}*/}
                {/*           alt=""/>*/}
                {/*    </div> : null*/}
                {/*}*/}
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>
  )
}
