import React, { useEffect, useState } from 'react'
import styles from '../index.module.less'
import Item from '../components/Item'
import serv from '../../../lib/api'
import { Util } from '../../../lib/util'
import List from '../../../components/List'

export default function MaterialList() {
	const [tabList, setTabList] = useState([])
	const [sedTabList, setSedTabList] = useState([])
	const [selectedId, setSelectedId] = useState(0)
	const [selectedSedId, setSelectedSedId] = useState(0)
	const [list, setList] = useState([])
	const [hasMore, setHasMore] = useState(false)
	const [pageNum, setPageNum] = useState(1)
	const [loaded, setLoaded] = useState(false)

	const size = 10
	const parent = 'MATERIAL_LIST'

	const getTabList = async () => {
		try {
			const res = await serv.libraryTagList()
			if (res) {
				const { data } = res
				setTabList(data)
				setSelectedId(data && data[0] && data[0].id)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const getSedTabList = async () => {
		if (!selectedId) return
		try {
			const res = await serv.getSecondTagList(selectedId)
			if (res) {
				const { data } = res
				const tab = [{ id: selectedId, name: '全部' }, ...(data || [])]
				setSedTabList(tab)
				setSelectedSedId(selectedId)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const getList = async () => {
		if (!selectedSedId) return
		Util.showLoading('加载中...')
		const params = {
			pageNum,
			size,
			tagId: selectedSedId
		}
		try {
			const res = await serv.getLibraryContentBySecondTag(params)
			if (res) {
				const { data, hasMore } = res
				setList(list.concat(data || []))
				setHasMore(hasMore)
				setPageNum(hasMore ? pageNum + 1 : pageNum)
			}
		} catch (err) {
			console.log(err)
		} finally {
			Util.hideLoading()
		}
	}

	useEffect(() => {
		if (tabList.length === 0) {
			getTabList()
		}
		setLoaded(true)
	}, [tabList]) // eslint-disable-line

	const switchTab = (id) => {
		if (id === selectedId) return
		setPageNum(1)
		setList([])
		setSedTabList([])
		setSelectedId(id)
		setSedTabList([])
		const params = {
			parent,
			current: 'MATERIALLIST_TAB',
			content: JSON.stringify({ id })
		}
		serv.dataTrackingStorage(params)
	}

	useEffect(() => {
		getSedTabList()
	}, [selectedId]) // eslint-disable-line

	const switchSedTab = (id) => {
		if (id === selectedSedId) return
		setPageNum(1)
		setList([])
		setSelectedSedId(id)
		const params = {
			parent,
			current: 'MATERIALLIST_SEDTAB',
			content: JSON.stringify({ id })
		}
		serv.dataTrackingStorage(params)
	}

	useEffect(() => {
		getList()
	}, [selectedSedId]) //eslint-disable-line

	return (
		<div className={styles.list_wrapper}>
			<div className={styles.tab}>
				{tabList &&
					tabList.map((it) => (
						<span
							className={`${styles.item} ${
								it.id === selectedId && styles.active
							}`}
							key={it.id}
							onClick={() => switchTab(it.id)}>
							{it.content} <span className={styles.dot} />
						</span>
					))}
			</div>
			<div className={styles.sed_tab}>
				{sedTabList &&
					sedTabList.map((it) => (
						<div
							className={`${styles.item} ${
								it.id === selectedSedId && styles.active
							}`}
							onClick={() => switchSedTab(it.id)}
							key={it.id}>
							{it.name}
						</div>
					))}
			</div>
			<div className={styles.list}>
				<List
					loaded={loaded}
					dataSource={list}
					hasMore={hasMore}
					next={getList}>
					{list &&
						list.map((it) => (
							<div key={it.id}>
								<div className={styles.list_item_wrapper}>
									<Item item={it} parent={parent} />
								</div>
								<div className={styles.line} />
							</div>
						))}
				</List>
			</div>
		</div>
	)
}
