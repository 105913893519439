import React from 'react'
import styles from './index.module.less'
import { AWARD_TYPE } from '../../../../lib/conf'
import { env } from '../../../../lib/conf'

export default function EarningsItem(props) {
	const { data, type } = props

	return (
		<>
			{data ? (
				<div className={styles.item}>
					<div className={styles.info}>
						<div className={styles.title}>{data.remark || '--'}</div>
						<div className={styles.desc}>
							<div className={`${styles.desc_item}`}>
								{type === 1 ? (
									<img
										className={`${styles.icon1}`}
										src={`${env.IMG}m-web/record_online_icon.png`}
										alt=''
									/>
								) : (
									<img
										alt=''
										className={`${styles.icon1}`}
										src={`${env.IMG}m-web/record_offline_icon.png`}
									/>
								)}

								{(AWARD_TYPE.find((it) => it.value === data.type) || {}).label || ''}
							</div>
							<div className={`${styles.desc_item_last}`}>
								<img
									alt=''
									className={`${styles.icon2}`}
									src={`${env.IMG}m-web/record_date.png`}
								/>
								{data.date || '--'}
							</div>
						</div>
					</div>
					<div className={styles.earnings}>
						{data.operation === 1 ? (
							<span>+{data.balance || '--'}</span>
						) : (
						  data.balance>0?
							<span style={{ color: ' #FFBD34' }}>-{data.balance || '--'}</span>:
                <span style={{ color: ' #FFBD34' }}>{data.balance || '--'}</span>
						)}
					</div>
					<div className={styles.split_line} />
				</div>
			) : null}
		</>
	)
}
