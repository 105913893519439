import React, { Component } from 'react'
import DefaultDatePicker from 'rmc-date-picker'
import PopupDatePicker from 'rmc-date-picker/lib/Popup'
import ZhCn from 'rmc-date-picker/lib/locale/zh_CN'
import moment from 'moment'
import 'rmc-picker/assets/index.css'
import 'rmc-date-picker/assets/index.css'
import 'rmc-picker/assets/popup.css'
import './index.less'

class DateTimePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: null
    }
  }

  onDismiss = () => {
    const { onClose } = this.props
    onClose && onClose()
  }

  onSelect = (date) => {
    const { onChange, format } = this.props
    this.setState({ date })
    onChange && onChange(moment(date).format(format || 'YYYY-MM-DD'))
  }

  render() {
    const { visible, mode, children, title,minDate } = this.props
    const { date } = this.state
    const datePicker = (
      <DefaultDatePicker
        minDate={minDate ? new Date(minDate) : new Date('2020-01-01')}
        maxDate={new Date()}
        defaultDate={new Date()}
        mode={mode || 'datetime'}
        locale={ZhCn}
      />
    )
    return (
      <PopupDatePicker
        className="date-picker"
        transitionName="rmc-picker-popup-slide-fade"
        maskTransitionName="rmc-picker-popup-fade"
        visible={visible}
        datePicker={datePicker}
        title={title || '请选择'}
        date={date ? new Date(date) : new Date()}
        onDismiss={this.onDismiss}
        onChange={this.onSelect}
        okText="确定"
        dismissText="取消"
      >
        {children || null}
      </PopupDatePicker>
    )
  }
}

export default DateTimePicker
