import React, { useState, useEffect } from 'react'
import { useStore } from 'reto'
import dsBridge from 'dsbridge'
import { HashRouter, Route } from 'react-router-dom'
import {
	KeepaliveRouterSwitch,
	addKeeperListener
} from 'react-keepalive-router'
import serv from '../lib/api'
import { nativeReturnMsg, storage } from '../lib/conf'
import { Util } from '../lib/util'
import { bridge } from '../lib/bridge'
import wx from 'weixin-js-sdk'
import { CommonStore } from '../store'

// pages
import Dashboard from '../views/Dashboard'
import Earnings from '../views/Earnings'
import Login from '../views/Login'
import PerformanceDetail from '../views/PerformanceDetail'
import ReturnVisit from '../views/ReturnVisit'
import BranchManagement from '../views/AffiliateBranchManagement'
import PromoterManagement from '../views/AffiliatePromoterManagement'
import MemberManagement from '../views/AffiliateMemberManagement'
import Achievement from '../views/Achievement'
import InvitationMember from '../views/Dashboard/InvitationMember'
import AbnormalDetail from '../views/AffiliateMemberManagement/components/AbnormalDetail'
import Explain from '../views/Explain'
import OverseasStudyTour from '../views/Event/OverseasStudyTour'
import LeadReaderList from '../views/LeadReaderList'
import ReaderInfo from '../views/Dashboard/readerInfo'
import LevelUserInfo from '../views/LevelUserInfo'
import GroupManagement from '../views/GroupManagement'
import GroupDetail from '../views/GroupManagement/detail'
import MaterialCenter from '../views/MaterialCenter/Index'
import MaterialList from '../views/MaterialCenter/List'
import MaterialCardList from '../views/MaterialCenter/CardList'
import LeadReaderProgress from '../views/LeadReaderProgress'
import InternshipLeadReaderDetail from '../views/InternshipLeadReader/detail'
import InternshipLeadReader from '../views/InternshipLeadReader'
import LeadReaderPerformance from '../views/LeadReaderProgress/detail'
import NoticeOfMotion from '../views/NoticeOfMotion'
import MaterialSearch from '../views/MaterialCenter/Search'
import MemberUserInfo from '../views/MemberUserInfo'
import MemberRemark from '../views/MemberRemark'
import UserBagInfo from '../views/UserBagInfo'
import InviteLeadReader from '../views/InviteLeadReader'

// components
import Toast from '../components/Toast'
import Loading from '../components/Loading'
import Modal from '../components/Modal'
import TypeModal from '../components/TypeModal'

const App = () => {
	const [loaded, setLoaded] = useState(false)
	const { modalState, updateEnv, typeModalState, updateUserInfo } =
		useStore(CommonStore)

	const jsSetUserInfo = () => {
		dsBridge.register('jsSetUserInfo', (res) => {
			try {
				if (res) {
					Util.setLocalStorage(storage.userInfo, res)
					updateUserInfo(JSON.parse(res || '{}'))
					return nativeReturnMsg.SUCCESS
				}
				return nativeReturnMsg.ERROR
			} catch (err) {
				console.log(err)
				return nativeReturnMsg.ERROR
			}
		})
	}

	/**
	 * @description 获取用户信息
	 */
	const getUserInfo = () => {
		serv.getDashboardUserInfo().then((res) => {
			if (res) {
				const { data } = res
				Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
				updateUserInfo(data || null)
			}
		})
	}

	/**
	 * @description 获取运行环境-用户信息
	 */
	const getEnv = () => {
		if (navigator.userAgent.indexOf('MicroMessenger') === -1) {
			if (bridge.isAppEnv()) {
				updateEnv('app')
				bridge.getUserInfoFromApp().then((_user) => {
					if (_user) {
						Util.setLocalStorage(storage.userInfo, JSON.stringify(_user || {}))
						updateUserInfo(_user || null)
					}
				})
			} else {
				updateEnv('h5')
			}
		} else {
			wx.miniProgram.getEnv((res) => {
				if (res.miniprogram) {
					updateEnv('mp')
				} else {
					updateEnv('h5')
				}
			})
		}
		const token = Util.getParams('token')
		if (token) {
			Util.setLocalStorage(
				storage.userInfo,
				JSON.stringify({ accountVo: { token: token || '' } })
			)
			getUserInfo()
		}
	}

	useEffect(() => {
		addKeeperListener((history, cacheKey) => {
			if (history) console.log('当前激活状态缓存组件：' + cacheKey)
		})
		jsSetUserInfo()
		getEnv()
		setLoaded(true)
		// eslint-disable-next-line
	}, [])

	return (
		<div className='main'>
			{/* toast */}
			<Toast />
			{/* loading */}
			<Loading />
			{/*Modal*/}
			<Modal options={modalState.options} visible={modalState.visible} />
			{/*TypeModal*/}
			<TypeModal
				options={typeModalState.options}
				visible={typeModalState.visible}
			/>
			{loaded ? (
				<HashRouter basename='/'>
					<KeepaliveRouterSwitch>
						<Route component={Dashboard} path='/' exact />
						<Route component={Dashboard} path='/dashboard' />
						<Route component={Earnings} path='/earnings-list/:id' />
						<Route
							component={PerformanceDetail}
							path='/performance-detail/:id/:month'
						/>
						<Route component={Login} path='/login' />
						<Route component={ReturnVisit} path='/return-visit/:level' />
						<Route
							component={BranchManagement}
							path='/branch-management/:level'
						/>
						<Route
							component={PromoterManagement}
							path='/promoter-management/:level'
						/>
						<Route
							component={MemberManagement}
							path='/member-management/:level'
						/>
						<Route component={Achievement} path='/achievement/:id' />
						<Route component={InvitationMember} path='/invitation-member' />
						<Route component={AbnormalDetail} path='/abnormal-detail' />
						<Route component={Explain} path='/explain/:id' />
						<Route component={OverseasStudyTour} path='/overseas-study-tour' />
						<Route component={LeadReaderList} path='/lead-reader-list/:role' />
						<Route component={ReaderInfo} path='/reader-info' />
						<Route component={LevelUserInfo} path='/leader-user-info/:id' />
						<Route component={GroupManagement} path='/group-list' />
						<Route component={GroupDetail} path='/group-detail/:id' />
						<Route component={MaterialCenter} path='/material-center-home' />
						<Route component={MaterialList} path='/material-center-list' />
						<Route
							component={MaterialCardList}
							path='/material-center-card-list'
						/>
						<Route
							component={LeadReaderProgress}
							path='/lead-reader-progress/:level'
						/>
						<Route
							component={LeadReaderPerformance}
							path='/lead-reader-performance'
						/>
						<Route
							component={InternshipLeadReaderDetail}
							path='/internship-lead-reader-detail/:id/:date'
						/>
						<Route
							component={InternshipLeadReader}
							path='/internship-lead-reader'
						/>
						<Route component={NoticeOfMotion} path='/notice-of-motion' />
						<Route component={MaterialSearch} path='/material-center-search' />
						<Route component={MemberUserInfo} path='/member-user-info/:id' />
						<Route component={MemberRemark} path='/member-remark/:id/:userId' />
						<Route component={InviteLeadReader} path='/invite-lead-reader'></Route>
						<Route
							component={UserBagInfo}
							path='/user-bag-info/:bagOrderId/:sequence'
						/>
					</KeepaliveRouterSwitch>
				</HashRouter>
			) : null}
		</div>
	)
}

export default App
