import React from 'react'
import styles from './index.module.less'
import { env } from '../../../../lib/conf'

export default function MyTeamItem(props) {
  const { data, redirectUrl } = props
  return (
    <>
      {data ? (
        <div
          className={styles.item_wrapper}
          onClick={redirectUrl}>
          <div className={styles.item}>
            <div className={`${styles.wrapper} ${styles.nickname}`}>{data.realName}</div>
            <div className={styles.wrapper}>
              {data.totalAchievement}
            </div>
            <div className={styles.wrapper}>
              {data.monthlyAchievement}
            </div>
            <div className={styles.wrapper}>
              {data.monthlyPerformance}
            </div>
            <div className={styles.go} onClick={redirectUrl}>
              <img src={`${env.IMG}m-web/achi_go_icon.png`} alt=""/>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
