import { useState } from 'react'

export function CommonStore() {
	const [modalState, setModalState] = useState({})
	const [typeModalState, setTypeModalState] = useState({})
	const [runtimeEnv, setRuntimeEnv] = useState('h5')
	const [userInfo, setUserInfo] = useState(null)
	const [qrcode1, setQrcode1] = useState('')
	const [qrcode2, setQrcode2] = useState('')
	const [qrcode3, setQrcode3] = useState('')

	const showModal = (options = {}) => {
		setModalState({
			options,
			visible: true
		})
	}

	const closeModal = () => {
		setModalState({
			options: {},
			visible: false
		})
	}
	const showTypeModal = (options = {}) => {
		setTypeModalState({
			options,
			visible: true
		})
	}

	const closeTypeModal = () => {
		setTypeModalState({
			options: {},
			visible: false
		})
	}

	const updateEnv = (env = '') => {
		setRuntimeEnv(env)
	}

	const updateUserInfo = (userInfo) => {
		setUserInfo(userInfo)
	}

	const updateQrcode1 = (url) => {
		setQrcode1(url)
	}

	const updateQrcode2 = (url) => {
		setQrcode2(url)
	}

	const updateQrcode3 = (url) => {
		setQrcode3(url)
	}

	return {
		runtimeEnv,
		updateEnv,
		modalState,
		showModal,
		closeModal,
		typeModalState,
		showTypeModal,
		closeTypeModal,
		userInfo,
		updateUserInfo,
		qrcode1,
		updateQrcode1,
		qrcode2,
		updateQrcode2,
		qrcode3,
		updateQrcode3
	}
}
