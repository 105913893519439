import React, {  useState } from 'react'
import Item from '../components/Item'
import styles from '../index.module.less'
import serv from '../../../lib/api'
import List from '../../../components/List'
import { Util } from '../../../lib/util'

export default function MaterialSearch(props) {
	const [keyword, setKeyword] = useState('')
	const [list, setList] = useState([])
	const [hasMore, setHasMore] = useState(false)
	const [pageNum, setPageNum] = useState(1)
	const [loaded, setLoaded] = useState(false)
	const size = 10
	const parent = 'MATERIAL_SEARCH'

	const queryList = async () => {
		Util.showLoading('数据加载中...')
		const params = {
			pageNum, size, keyword
		}
		try {
			const res = await serv.getLibraryContentByKeyword(params)
			if (res) {
				const { data, hasMore } = res
				setList(data || [])
				setPageNum(hasMore ? pageNum + 1 : pageNum)
				setHasMore(hasMore)
			}
		} catch (err) {
			console.log(err)
		} finally {
			setLoaded(true)
			Util.hideLoading()
		}
	}

	const handleInput = (e) => {
		const { value } = e.target
		setKeyword(value)
	}

	const handleSearch = async () => {
		setPageNum(1)
		setList([])
		setLoaded(false)
		await queryList()
	}

	return <div className={styles.wrapper}>
		<div className={styles.page_head_wrapper}>
			<input className={styles.search_input} placeholder="输入关键字查询" onChange={handleInput}/>
			<div className={styles.search_btn} onClick={handleSearch}>搜索</div>
		</div>
		<div className={styles.content}>
			<div className={styles.list}>
				<List
					loaded={loaded}
					dataSource={list}
					hasMore={hasMore}
					next={queryList}
				>
					{list && list.map(it => <div key={it.id}>
						<div className={styles.list_item_wrapper}>
							<Item item={it} parent={parent}/>
						</div>
						<div className={styles.line}/>
					</div>)}
				</List>
			</div>
		</div>
	</div>
}
