import React, { useEffect, useState } from 'react'
import { Util } from '../../lib/util'
import styles from './index.module.less'
import serv from '../../lib/api'
import moment from 'moment'

export default function LeadReaderProgress(props) {
  const [saleMama, setSaleMama] = useState({})
  const [list, setList] = useState([])
  const [totalAchievement, setTotalAchievement] = useState({})
  const [targetLevel, setTargetLevel] = useState()
  const [updateProgress, setUpdateProgress] = useState({})
  const tabList = [
    { value: 1, label: '未达成' },
    { value: 2, label: '已达成' }
  ]
  const [currentTab, setCurrentTab] = useState(1)

  useEffect(() => {
    initData()
  }, []) //eslint-disable-line

  const initData = () => {
    const l = props.match.params.level
    const levelInfo = Util.getLevel(l * 1)
    document.title = `${levelInfo === 'normal' ? '资深领读者' : levelInfo === 'senior' ? '领读者导师' : ''}`
    let level
    if (levelInfo === 'normal') {
      level = 4
    } else if (levelInfo === 'senior') {
      level = 6
    }
    setTargetLevel(level)
    getMemberUpdateProgress(level)
  }

  /**
   * @description 升级进度
   * @returns {Promise<void>}
   */
  const getMemberUpdateProgress = async (level) => {
    try {
      const res = await serv.getMemberUpdateProgress(level)
      if (res) {
        const { data } = res || {}
        const { saleMama: sa, incompleteList: inComp, totalAchievement: total } = data
        setUpdateProgress(data)
        setSaleMama(sa)
        setList(inComp)
        setTotalAchievement(total)
      }
    } catch (e) {
      console.log(e)
    }
  }


  const toDetail = () => {
    const { history } = props
    history.push('/lead-reader-performance')
  }

  const changeTab = (v) => {
    const { incompleteList, completeList } = updateProgress
    setCurrentTab(v)
    if (v === 1) {
      setList(incompleteList)
    } else {
      setList(completeList)
    }
  }

  return <div className={styles.l_r_progress_wrapper}>
    {
      targetLevel === 4 ? <div className={styles.l_r_first}>
        <div className={styles.l_r_title}>
          <div className={styles.title}>
            <div className={styles.label}>个人累计业绩</div>
            <div className={styles.bg_line}/>
          </div>
          <div className={styles.desc}>(需≥5万)</div>
        </div>
        <div className={styles.l_r_content} onClick={() => toDetail()}>
          <div className={styles.name}>已累计</div>
          <div className={styles.value}>{(totalAchievement && totalAchievement.finishCount) || 0}</div>
          <div className={styles.label}>明细</div>
          <div className={styles.l_r_right_icon}/>
        </div>
      </div> : null
    }
    {
      targetLevel === 4 ? <div className={styles.l_r_second}>
        <div className={styles.l_r_title}>
          <div className={styles.title}>
            <div className={styles.label}>售卖故事妈妈</div>
            <div className={styles.bg_line}/>
          </div>
          <div className={styles.desc}>(需≥8单)</div>
        </div>
        <div>
          <div className={styles.l_r_content}>
            <div className={styles.name}>已售卖</div>
            <div className={styles.value}>{(saleMama && saleMama.finishCount) || 0}</div>
          </div>
          <div className={styles.line}/>
          <div className={styles.list}>
            {
              saleMama.itemList && saleMama.itemList.map((it) => (
                <div className={styles.list_item}>
                  <div className={styles.label}>{it.title}</div>
                  <div className={styles.date}>{moment(it.finishTime).format('YYYY-MM-DD HH:mm')}</div>
                </div>
              ))
            }
          </div>
        </div>
      </div> : null
    }
    {
      targetLevel === 6 &&
      <div className={styles.l_r_third}>
        <div className={styles.l_r_info_wrapper}>
          <div className={`${styles.l_r_title} ${styles.senior}`}>
            <div className={styles.title}>
              <div className={styles.label}>培育资深领读者</div>
              <div className={styles.bg_line}/>
            </div>
            <div className={`${styles.desc} ${styles.senior}`}>(成为资深：需个人业绩 ≥ 5万，且销售故事妈妈 ≥ 8单)</div>
          </div>
          <div className={`${styles.l_r_content} ${styles.senior}`}>
            <div className={styles.name}>已达成</div>
            <div
              className={styles.value}><span
              className={styles.current}>{(updateProgress && updateProgress.current) || 0}</span>/{(updateProgress && updateProgress.total) || 0}位
            </div>
          </div>
        </div>
        <div className={styles.list_wrapper}>
          <div className={styles.header_info}>
            {
              tabList.map((it) => (
                <div className={`${styles.head_item} ${currentTab === it.value ? styles.active : ''}`} key={it.value}
                     onClick={(v) => changeTab(it.value)}>
                  {it.label}
                  {
                    it.value === currentTab && <div className={styles.h_line}/>
                  }
                </div>
              ))
            }
          </div>
          <div className={styles.list}>
            {
              list && list.map((it) => (
                <div className={styles.list_item}>
                  <div className={styles.title_wrapper}>
                    <div className={styles.item_title}>{it.title}</div>
                    <div className={styles.date}>{moment(it.finishTime).format('YYYY-MM-DD')}</div>
                  </div>
                  <div className={styles.item_content}>
                    <div className={styles.item_left}>
                      <div className={styles.label}>个人业绩</div>
                      {
                        it.finishAchievement ? <div className={styles.icon}/>
                          : <>
                            <div className={styles.value}>{it.achievement || 0}</div>
                          </>
                      }
                    </div>
                    <div className={styles.item_left}>
                      <div className={styles.label}>销售故事妈妈</div>
                      {
                        it.finishMama ? <div className={styles.icon}/>
                          : <>
                            <div className={styles.value}>{it.saleMama || 0}</div>
                          </>
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    }
  </div>
}