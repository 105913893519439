import React, { useState } from 'react'
import styles from './index.module.less'
import { env } from '../../../../lib/conf'

export default function PromoterItem(props) {
  const { data, level } = props
  const [itemVisible, setItemVisible] = useState(false)

  const equalAvatarLength = (level) => {
    if (level && level > 0) {
      if (data.referRealName && data.referRealName.length > 3) {
        return `${data.referRealName.substring(0, 2)}...`
      } else {
        return data.referRealName
      }
    } else {
      if (data.equalNickname && data.equalNickname.length > 3) {
        return `${data.equalNickname.substring(0, 2)}...`
      } else {
        return data.equalNickname
      }
    }
  }

  const toLeaderInfo = () => {
    props.history.push(`/leader-user-info/${data.userId}`)
  }

  return (
    <>
      {data ? (
        <div className={styles.item} onClick={() => toLeaderInfo()}>
          <div className={styles.user} onClick={() => setItemVisible(!itemVisible)}>
            {
              level && level > 0 ? <div className={styles.left_new}>
                  <img className={styles.user_img}
                       src={data.avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'} alt=""/>
                  {data.equalId ?<div>
                      <div className={styles.username}>{data.realName || '--'}</div>
                      <div className={styles.info}>
                        <div className={styles.achi}>累计业绩{data.totalAchievement}</div>
                        <div className={styles.line}/>
                        <div className={styles.l_num}>售卖故事妈妈{data.saleMama}</div>
                      </div>
                    </div> :
                    <div>
                      <div><span>{data.realName || '--'}</span></div>
                      <div className={styles.info}>
                        <div className={styles.achi}>累计业绩{data.totalAchievement}</div>
                        <div className={styles.line}/>
                        <div className={styles.l_num}>售卖故事妈妈{data.saleMama}</div>
                      </div>
                    </div>
                  }
                </div> :
                <div className={styles.left}>
                  <img className={styles.user_img}
                       src={data.avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'} alt=""/>
                  {data.equalId ? <div className={styles.username}>{data.realName || '--'}</div> :
                    <div>{data.realName || '--'}</div>}
                </div>
            }
            <div className={styles.right}>
              {
                level && level > 0 ?
                  data.referUserId &&
                  <div className={styles.rem_wrapper}>
                    <img className={styles.rem_img}
                         src={data.referAvatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}
                         alt=""/>
                    <div className={styles.rem_bg}><span className={styles.rem_name}>{equalAvatarLength()}
                  </span><span className={styles.rem_name_r}>推荐</span></div>
                  </div> :
                  data.equalId &&
                  <div className={styles.rem_wrapper}>
                    <img className={styles.rem_img}
                         src={data.equalAvatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}
                         alt=""/>
                    <div className={styles.rem_bg}><span className={styles.rem_name}>{equalAvatarLength()}
                  </span><span className={styles.rem_name_r}>推荐</span></div>
                  </div>
              }
              {
                (!level || level === 0) && <div className={styles.right_icon}>
                  {
                    itemVisible ? <img
                      src={`${env.IMG}m-web/top_arrow.png`}
                      className={styles.bottom_arrow}
                      alt=""
                    /> : <img
                      src={`${env.IMG}m-web/bottom_arrow.png`}
                      className={styles.top_arrow}
                      alt=""
                    />
                  }
                </div>
              }
            </div>
          </div>

          {
            level && level > 0 ? <div className={`${styles.record_new}`}>
                <div className={styles.record_line}>
                  <div className={styles.record_it_item}>
                    <div className={styles.record_it_name}>
                      <div className={styles.record_label}>履约会员</div>
                      <div className={styles.record_value}>{data.totalMemberCount || 0}</div>
                    </div>
                    <div className={`${styles.record_it_name} ${styles.last}`}>
                      <div className={styles.record_label}>上月新增</div>
                      <div className={styles.record_value}>{data.lastMonthlyMemberCount || 0}</div>
                    </div>
                  </div>
                  <i className={styles.board_it_line}/>
                  <div className={styles.record_it_item}>
                    <div className={styles.record_it_name}>
                      <div className={styles.record_label}>上月收益</div>
                      <div
                        className={styles.record_value}>{data.revenue.lastMonthly && (data.revenue.lastMonthly || 0)}</div>
                    </div>
                    <div className={`${styles.record_it_name} ${styles.last}`}>
                      <div className={styles.record_label}>本月收益</div>
                      <div className={styles.record_value}>{data.revenue.monthly && (data.revenue.monthly || 0)}</div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className={`${styles.record} ${styles.dashboard}`}>
                <div className={styles.record_line}>
                  <div className={styles.record_it_item}>
                    <div className={styles.record_it_value}>
                      {data.commonCnt || 0}
                    </div>
                    <div className={styles.record_it_name}>总会员数</div>
                    <div className={styles.record_add}>
                      本月新增{data.commonCntInr || 0}
                    </div>
                  </div>
                  <i className={styles.board_it_line}/>
                  <div className={styles.record_it_item}>
                    <div className={styles.record_it_value}>
                      {data.referCnt || 0}
                    </div>
                    <div className={styles.record_it_name}>软绑定用户</div>
                    <div className={styles.record_add}>本月新增{data.referCntInc}</div>
                  </div>
                </div>
                <div className={styles.record_line}>
                  <div className={styles.record_it_item}>
                    <div className={styles.record_it_value}>
                      {data.subCommonCnt || 0}
                    </div>
                    <div className={styles.record_it_name}>订阅会员</div>
                    <div className={styles.record_add}>
                      本月新增{data.subCommonCntInr || 0}
                    </div>
                  </div>
                  <i className={styles.board_it_line}/>

                  <div className={styles.record_it_item}>
                    <div className={styles.record_it_value}>
                      {data.worthyCommonCnt || 0}
                    </div>
                    <div className={styles.record_it_name}>正价课会员</div>
                    <div className={styles.record_add}>
                      本月新增{data.worthyCommonCntInr || 0}
                    </div>
                  </div>
                </div>
              </div>
          }
          {itemVisible && (!level || level === 0) &&
          <div className={styles.record}>
            <div className={styles.record_line}>
              <div className={styles.record_it_item}>
                <div className={styles.record_it_num}>{data.totalAchieve || 0}</div>
                <div className={styles.record_it_name}>累计个人消费额</div>
              </div>
              <i className={styles.board_it_line}/>
              <div className={styles.record_it_item}>
                <div className={styles.record_it_num}>
                  {data.monthlyTotalAchieve || 0}
                </div>
                <div className={styles.record_it_name}>本月个人消费额</div>
              </div>
            </div>
            <div className={styles.record_line}>
              <div className={styles.record_it_item}>
                <div className={styles.record_it_num}>  {data.totalAward || 0}</div>
                <div className={styles.record_it_name}>
                  总收入
                </div>
              </div>
              <i className={styles.board_it_line}/>
              <div className={styles.record_it_item}>
                <div className={styles.record_it_num}>
                  {data.monthlyAward || 0}
                </div>
                <div className={styles.record_it_name}>本月收入</div>
              </div>
            </div>
          </div>
          }
        </div>
      ) : null}
    </>
  )
}
