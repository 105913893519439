import user from './api/user'
import goods from './api/goods'
import distribution from './api/distribution'
import order from './api/order'
import thirdParty from './api/thirdParty'
import common from './api/common'
import datacenter from './api/datacenter'

const serv = {
	...user,
	...goods,
	...distribution,
	...order,
	...thirdParty,
	...common,
	...datacenter
}

export default serv
