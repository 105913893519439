import React from 'react'
import styles from '../../index.module.less'
import { bridge } from '../../../../lib/bridge'
import { useStore } from 'reto'
import { CommonStore } from '../../../../store'
import Card from '../../../../components/Card'

export default function CardStock(props) {
  const { runtimeEnv } = useStore(CommonStore)

  //跳转----->app卡包
  const showAppCardDetail = () => {
    if (runtimeEnv === 'app') {
      bridge.appNavigate({ id: 109 })
    }
  }

  // 跳转----->谷粒详情
  const showAppCerealDetail = () => {
    if (runtimeEnv === 'app') {
      bridge.appNavigate({ id: 133 })
    }
  }

  //跳转----->绩效奖金
  const showAchiDetail = () => {
    const { history } = props
    history.push(`/achievement/${props.id}`)
  }


  //跳转----->app钱包
  const showAppWalletDetail = () => {
    if (runtimeEnv === 'app') {
      bridge.appNavigate({ id: 110 })
    }
  }


  return<Card>
    {props.loaded ?
      <div className={styles.data_center}>
        <div className={styles.item} onClick={showAppCardDetail}>
          <div className={styles.value}>{props.homeData.cardStock || 0}</div>
          <div className={styles.label}>卡包（张)</div>
        </div>
        <div className={styles.item}>
          <div className={styles.value}
               onClick={showAppCerealDetail}>{props.cerealDetailData.availableCerealAmount || 0}</div>
          <div className={styles.label}>谷粒</div>
        </div>
        <div className={styles.item} onClick={showAppWalletDetail}>
          <div className={styles.value}>{props.homeData.availableAward || 0}</div>
          <div className={styles.label}>奖励</div>
        </div>
        {
          props.level && props.level > 0 ? <div className={styles.item} onClick={showAchiDetail}>
            <div className={styles.value}>{props.homeData.totalPerformance || 0}</div>
            <div className={styles.label}>绩效</div>
          </div> : null
        }
      </div>
      : <div className={styles.default_card1}/>}
  </Card>
}