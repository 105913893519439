import fetch from '../fetch'

const order = {
	/**
	 * @description 获取抽奖订单记录列表
	 * @returns {*}
	 */
	getLotteryOrderList() {
		return fetch('/order/mp/points_goods_order/lottery_order_list')
	},

	/**
	 * @description 补充用户收货地址
	 * @param params
	 * @returns {*}
	 */
	commitUserAddress(params = {}) {
		return fetch('/order/mp/points_goods_order/commit_user_address', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取抽奖获奖名单
	 * @returns {*}
	 */
	getLotteryRollList() {
		return fetch('/order/mp/points_goods_order/get_lottery_roll_list')
	},

	/**
	 * 获取看板可用谷粒
	 * @param {*} params
	 */
	getCerealDetail(params = {}) {
		return fetch('/order/crm/cereal/detail', {
			params: { ...params },
			v2: true
		})
	},

	/**
	 * @description 转增积分
	 * @param {*} params
	 */
	transferPoints(params = {}) {
		return fetch('/order/app/points_shop/transfer_points', {
			method: 'POST',
			data: { ...params },
			v2: true
		})
	},

	/**
	 * @description 获取书袋中的绘本列表
	 * @param {*} param0
	 * @returns
	 */
	getSimpleDetail({ bagOrderId, excludeBox }) {
		return fetch('/order/bag_order_page_info/get_simple_detail', {
			method: 'POST',
			data: { bagOrderId, excludeBox },
			v2: true
		})
	},

	/**
	 * @description 邀请领读者页面信息
	 * @param {*} id
	 * @returns
	 */
	getReaderIntroductionInfo(id) {
		return fetch(`/order/app/annual_fee/order/reader_introduction/${id}`, {
			v2: true
		})
	}
}

export default order
