import React, { useEffect, useState } from 'react'
import styles from './index.module.less'

export default function Explain(props) {
  const [id, setId] = useState('')

  useEffect(() => {
    document.title = '用户状态说明'
    const { id: typeId } = props.match.params
    setId(typeId)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {id === '1' ? <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.item_title}>实时奖励
          </div>
          <div className={styles.line}/>
          <p className={styles.content_P}>每个内容产品都会设置基础奖励，基础奖励在支付行为发生时发放至好奇说奖励账户。
          </p>
        </div>
        <div className={styles.item}>
          <div className={styles.item_title}>消费额
          </div>
          <div className={styles.line}/>
          <p className={styles.content_P}>团队产品消费总和，即销售给终端用户的产品售价之和。
          </p>
          <ul>
            <li>1、我的消费额：我自己的产品消费金额 + 我的直属会员的产品消费金额
            </li>
            <li>2、直属团队消费额：我的消费额+我的直属团队成员的消费额
            </li>
          </ul>
          <p className={styles.content_P}>消费额统计范围：
          </p>
          <ul>
            <li className={styles.content_P}>1、服务销售、服务自用。
            </li>
            <li className={styles.content_P}>即邀请好友成为分会长、邀请好友注册20张卡推广人、服务商店预购买服务不计入消费额。预购买的服务直到用户领取时才会产生消费额。
            </li>
          </ul>
          <p className={styles.content_P}>消费额用途：
          </p>
          <ul>
            <li className={styles.content_P}>1、推广人满7万消费额可申请成为分会长
            </li>
            <li className={styles.content_P}>2、分会长的每月奖励档位定档
            </li>
          </ul>
        </div>
        <div className={styles.item}>
          <div className={styles.item_title}>奖励档位
          </div>
          <div className={styles.line}/>
          <p className={styles.content_P}>分会长及服务中心团队销售除了获得基础奖励外，还会根据绩效情况获得绩效奖励。本月绩效奖励=本月绩效*本月奖励档位。
          </p>
          <p className={styles.content_P}>奖励档位是由直属团队消费额+平级团队消费额的总额所决定的：
          </p>
          <ul>
            <li>1、注册未满2个月的分会长：当月总消费额决定本月奖励档位
            </li>
            <li>2、注册已满2个月的分会长：本月总消费额决定下月奖励档位
            </li>
            <li>3、特殊：2021年5月全部以当月总消费额评定，6月根据注册时间正常执行
            </li>
          </ul>
          <p className={styles.content_P}>以下为奖励档位明细：
          </p>
          <table className={styles.table_1} border={1}>
            <tr>
              <td></td>
              <td className={styles.td_1}></td>
              <td>分会</td>
              <td>奖励比例</td>
            </tr>
            <tr>
              <td rowspan={5}>直属+平级总消费额</td>
              <td className={styles.td_1}>第一档</td>
              <td>0-1万</td>
              <td>0%</td>
            </tr>
            <tr>
              <td className={styles.td_1}>第二档</td>
              <td>1万（含）-3万</td>
              <td>20%</td>
            </tr>
            <tr>

              <td className={styles.td_1}>第三档</td>
              <td>3万（含）-6万</td>
              <td>60%</td>
            </tr>
            <tr>

              <td className={styles.td_1}>第四档</td>
              <td>6万（含）-10万</td>
              <td>80%</td>
            </tr>
            <tr>

              <td className={styles.td_1}>第五档</td>
              <td>10万（含）+</td>
              <td>100%</td>
            </tr>
          </table>
        </div>
        <div className={styles.item}>
          <div className={styles.item_title}>绩效
          </div>
          <div className={styles.line}/>
          <p className={styles.content_P}>绩效与（三、奖励档位）一起决定了可获得的绩效奖励金额。
            <br/>绩效用于统计团队消费额中可分配的奖励基数，故区别于消费额：
          </p>
          <ul>
            <li>1、绩效根据每个内容产品单独设置的值进行求和，而非售价之和；
            </li>
            <li>2、不统计平级团队，仅计算直属团队；
            </li>
            <li>3、谷粒支付部分不计算绩效；
            </li>
            <li>4、服务商店预购买的服务不计算绩效，会员领取使用后也不计算绩效；
            </li>
          </ul>
          <p className={styles.content_P}>以下为目前正价内容产品的绩效值：
          </p>
          <table className={styles.table_2} border={1}>
            <tr>
              <td></td>
              <td>订阅卡</td>
              <td>故事妈妈</td>
              <td>研学妈妈</td>
            </tr>
            <tr>
              <td>零售价</td>
              <td>688</td>
              <td>2680</td>
              <td>2680</td>
            </tr>
            <tr>
              <td>推广人</td>
              <td>/</td>
              <td>/</td>
              <td>/</td>
            </tr>
            <tr>
              <td>分会</td>
              <td>20</td>
              <td>400</td>
              <td>180</td>
            </tr>

          </table>
          <ul>
            <li>注:&nbsp;分会长的会员现金在线购买了故事妈妈，则分会长计绩效400；分会长自己购买了1张订阅年卡使用了50%现金，50%谷粒，计绩效10；
            </li>
          </ul>
        </div>
        <div className={styles.item}>
          <div className={styles.item_title}>绩效奖励
          </div>
          <div className={styles.line}/>
          <p className={styles.content_P}>本月绩效奖励=本月直属团队绩效*本月奖励档位
          </p>
          <p className={styles.content_P}>(上月总消费额决定了本月奖励档位)
          </p>
        </div>
      </div> : null}
      {
        id === '2' ? <div className={styles.content}>
          <div className={styles.item}>
            <div className={styles.item_title}>预绑定用户
            </div>
            <div className={styles.line}/>
            <p className={styles.content_P}>也可理解为"预锁粉"，用户点开你的链接/输入你的邀请码注册好奇说后，该用户成为你的预绑定用户。
            </p>
            <p className={styles.content_P}>以下方式可以使TA从你的「预绑定用户」变为「绑定会员」
            </p>
            <ul>
              <li>1、直接在小程序中支付金额购买任意平台产品
              </li>
              <li>2、线下转账支付，领取你发送的正价商品（订阅体验卡、订阅年卡、故事妈妈赋能营、研学妈妈赋能营）
              </li>
            </ul>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>绑定会员
            </div>
            <div className={styles.line}/>
            <p className={styles.content_P}>绑定会员即此会员与你的关系在一定时间段内为坚固的推荐关系。
              在此时间段内，用户无论购买任何产品都与你有关，若有奖励设置，则由你获得。
              在此时间段内，除三方协商同意的转关系申请外，推荐关系无法变更。
            </p>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>会员绑定关系时长
            </div>
            <div className={styles.line}/>
            <p className={styles.content_P}>用户成交后，根据成交产品的类型不同，有不同的绑定时长。
              会员的绑定期=该会员享受平台服务的有效期。可在APP的会员管理中查看会员的服务有效期，服务有效期不足3个月时，该会员会显示在「须回访」列表中。
            </p>
            <p className={styles.content_P}>以下为不同产品的服务有效期
            </p>
            <ul>
              <li>1、非正价商品成交：支付时间+3个月,包含但不限于：体验卡、Get课、圈子…
              </li>
              <li>2、订阅年卡：订阅卡服务有效期 <br/>(注：未激活期间也属于绑定，激活后书袋以书袋最终的有效期为最长绑定期
                )
              </li>
              <li>3、 故事妈妈赋能营：长期
              </li>
              <li>4、研学妈妈赋能营：长期
              </li>
              <li>5、无忧妈妈科学养育指导师：长期
              </li>
            </ul>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>会员解除绑定后会如何
            </div>
            <div className={styles.line}/>
            <p className={styles.content_P}>绑定会员解除绑定后，会回归到与你预绑定的状态，直至下次付费行为转化。
            </p>
          </div>
        </div> : null
      }
      {
        id === '3' && <div className={styles.content}>
          <div className={styles.item}>
            <div className={styles.item_title}>续费状态</div>
            <div className={styles.line}/>
            <div className={styles.bold}>1、已开通自动续费
            </div>
            <p className={styles.content_P}>用户已签约并开通自动续费，在该用户的固定扣款日内会完成自动扣费。
            </p>
            <div className={styles.bold}>2、到期需签约新服务
            </div>
            <p className={styles.content_P}> +30老升新服务最后一个月，领读者可在会员服务到期前5天联系用户，服务过期后将影响“履约会员数”。
            </p>
            <div className={styles.bold}> 3、已取消自动续费
            </div>
            <p className={styles.content_P}> 取消自动续费用户，服务到期前5天，在“我的-我的订阅”中展示“开通自动续费”按钮。
            </p>
            <div className={styles.bold}> 4、已签约，待扣费
            </div>
            <p className={styles.content_P}> 费扣款日“29、30、31号”签约的用户，次月初进行扣费。
            </p>
            <div className={styles.bold}> 5、已签约，扣费失败n次
            </div>
            <p className={styles.content_P}> 用户固定扣款日，扣费失败后，次日发送失败通知给用户，并在发通知后的未来 3 天每天进行一次扣款直到成功，如果 4
              次扣款均失败，用户需重新签约。</p>
            <div className={styles.bold}> 6、特殊地</div>
            <p
              className={styles.content_P}> 该用户如果为+30老生新用户，因取消自动续费、连续4次扣款失败导致服务到期，如果688年卡未过期且仍有书袋，将继续年卡服务，且不能再次+30升级新服务。</p>

            <div className={styles.item_title}>服务类型</div>
            <div className={styles.line}/>
            <div className={styles.bold}> 1、+30升级
            </div>
            <p className={styles.content_P}> 原688年卡会员，每月+30元升级为新服务，每月不限借阅次数，直到年卡书袋使用完，需重新签约168服务。
            </p>
            <div className={styles.bold}> 2、168订阅
            </div>
            <p className={styles.content_P}> 168订阅服务，每月不限借阅次数。
            </p>

            <div className={styles.item_title}>新用户奖励类型</div>
            <div className={styles.line}/>

            <div className={styles.bold}> 1、直招订阅
            </div>
            <div className={styles.bold}> 2、会员推荐
            </div>

          </div>
        </div>
      }

    </>
  )
}
