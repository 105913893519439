import React, { useEffect } from 'react'
import { env } from '../../lib/conf'
import styles from './index.module.less'

export default function ReaderInfo() {
  useEffect(()=>{
    document.title = '身份介绍'
  },[])
  return <div>
    <div className={styles.reader_info}>每月10号左右公布升降星结果，遇节假日可能延后</div>
    <img src={`${env.IMG}m-web/reader_info_v2.png `} className={styles.reader_info_img} alt={''}/>
  </div>
}