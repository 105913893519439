import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import List from '../../components/List'
import moment from 'moment'
import DatePicker from '../../components/DataPicker'
import { Util } from '../../lib/util'
import serv from '../../lib/api'

export default function InternshipLeadReader(props) {
  const [currentTab, setCurrentTab] = useState(1)
  const [list, setList] = useState({
    data: [],
    size: 20,
    pageNum: 1,
    loading: false,
    hasMore: false
  })
  const [loaded, setLoaded] = useState(false)
  const [datePickerVisible, setDatePickerVisible] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM'))

  useEffect(() => {
    handleSetList('data', [])
    handleSetList('pageNum', 1)
    getList()
  }, [date, currentTab]) //eslint-disable-line

  const handleSetList = (key, value) => {
    list[key] = value
    setList({ ...list })
  }

  useEffect(() => {
    document.title = '实习领读者'
  }, []) //eslint-disable-line

  const getList = async () => {
    Util.showLoading()
    let api
    const params = {
      pageNum: list.pageNum,
      size: list.size
    }

    if (currentTab === 1) {
      params.keyword = keyword
      api = 'getInternMemberList'
    } else {
      params.month = date
      api = 'getInternRevenueList'
    }

    try {
      const res = await serv[api](params)
      if (res) {
        const { data, hasMore } = res
        handleSetList('data', list.data.concat(data || []))
        handleSetList('hasMore', hasMore)
        if (hasMore) {
          handleSetList('pageNum', list.pageNum + 1)
        }
        setLoaded(true)
      }
    } catch (err) {
      console.log(err)
    } finally {
      Util.hideLoading()
    }
  }

  const changeTab = (v) => {
    setCurrentTab(v)
    setDate(moment(new Date()).format('YYYY-MM'))
    setLoaded(false)
  }

  const onChangeDate = (date) => {
    setDate(date)
    setDatePickerVisible(false)
  }

  const toDetail = (id) => {
    const { history } = props
    if (currentTab === 1) {
      history.push(`/internship-lead-reader-detail/${id}/-1`)
    } else {
      history.push(`/internship-lead-reader-detail/${id}/${date}`)

    }
  }

  const changeInput = (e) => {
    const { value } = e.target
    setKeyword(value)
  }

  /**
   * @description input回车搜索
   * @param {*} e
   */
  const onKeyUp = async (e) => {
    if (e.keyCode === 13) {
      handleSetList('data', [])
      handleSetList('pageNum', 1)
      await getList()
    }
  }

  return <div className={styles.internship_l_r_wrapper}>
    <div className={styles.tab_wrapper}>
      <div className={styles.tab_item} onClick={() => changeTab(1)}>
        <div className={`${styles.label} ${currentTab === 1 ? styles.active : ''}`}>成员</div>
        {
          currentTab === 1 && <div className={styles.line}/>
        }
      </div>
      <div className={styles.tab_item} onClick={() => changeTab(2)}>
        <div className={`${styles.label}  ${currentTab === 2 ? styles.active : ''}`}>发放权益</div>
        {
          currentTab === 2 && <div className={styles.line}/>
        }
      </div>
    </div>

    {
      currentTab === 1 ? <div className={styles.search_wrapper}>
        <div className={styles.search}>
          <div className={styles.search_icon}/>
          <input
            className={styles.input}
            placeholder={'请输入昵称或手机号'}
            onKeyUp={onKeyUp}
            onChange={changeInput}/>
        </div>
      </div> : <div className={styles.search_date_picker_wrapper}>
        <DatePicker
          visible={datePickerVisible}
          title="请选择月份"
          mode="month"
          format="YYYY-MM"
          onClose={() => setDatePickerVisible(false)}
          onChange={onChangeDate}>
          <div className={styles.date_picker} onClick={() => setDatePickerVisible(true)}>
            {date}&nbsp;
            <i className={`iconfont crm-down-arrow ${styles.crm_down_arrow}`}/>
          </div>
        </DatePicker>
      </div>
    }

    <div className={styles.list_wrapper}>
      <List
        loaded={loaded}
        dataSource={list.data}
        hasMore={list.hasMore}
        next={getList}>
        {list.data && list.data.map((it, idx) => (
          <div>
            <div className={styles.list_item} onClick={() => toDetail(it.userId)}>
              <img className={styles.avatar}
                   src={it.avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'} alt={''}/>
              <div className={styles.item_content}>
                <div className={styles.item_top}>
                  <div className={styles.name}>{it.nickname || ''}</div>
                  {
                    currentTab === 1 &&
                    <div className={styles.date}>{moment(it.internTime).format('YYYY-MM-DD')} 开始实习</div>
                  }
                </div>
                <div className={styles.item_bottom}>
                  <div className={styles.info}>累计收入 {(it.revenue && it.revenue.total) || 0} ｜
                    {currentTab === 1 ? '上月收入' : '本月收入'} {currentTab === 1 ? (it.revenue && it.revenue.lastMonthly) || 0 : (it.revenue && it.revenue.monthly) || 0}
                  </div>
                  <div className={styles.right_wrapper}>
                    <div className={styles.status}>{it.level === -1 ? '实习中' : '已升级'}</div>
                    <div className={styles.right_icon}/>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.line}/>
          </div>
        ))}
      </List>
    </div>
  </div>
}