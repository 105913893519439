import React, { useEffect, useState } from 'react'
import { useStore } from 'reto'
import { CommonStore } from '../../store'
import ApplyItem from './components/item'
import List from '../../components/List'
import { Util } from '../../lib/util'
import serv from '../../lib/api'
import styles from './index.module.less'

const NoticeOfMotion = () => {
  const { showModal, closeModal } = useStore(CommonStore)
  const [loaded, setLoaded] = useState(false)
  const [list, setList] = useState({
    data: [],
    size: 20,
    pageNum: 1,
    loading: false,
    hasMore: false
  })


  const handleSetList = (key, value) => {
    list[key] = value
    setList({ ...list })
  }

  document.title = '待办'

  const getList = async () => {
    Util.showLoading('加载中...')
    const params = {
      pageNum: list.pageNum,
      size: list.size,
    }
    try {
      const res = await serv.internshipApplyList(params)
      if (res) {
        const { data, hasMore } = res
        handleSetList('data', list.data.concat(data || []))
        handleSetList('hasMore', hasMore)
        if (hasMore) {
          handleSetList('pageNum', list.pageNum + 1)
        }
        setLoaded(true)
      }
    } catch (err) {
      console.log(err)
    } finally {
      Util.hideLoading()
    }
  }

  useEffect(() => {
    getList()
  }, [])    // eslint-disable-line

  const showConfirmAgree = (it) => {
    const { id, nickname, phone } = it
    showModal({
      title: '是否同意申请？',
      content: `申请人：${nickname}\n手机号：${phone}`,
      showCancelBtn: true,
      showModalCloseIcon: false,
      gravity: 1,
      onCancel: () => {
        closeModal()
      },
      onConfirm: () => {
        closeModal()
        if (id) {
          agreeInternshipApply(id)
        } else {
          Util.showToast('错误的申请人ID')
        }

      }
    })
  }

  const agreeInternshipApply = async (applyId) => {
    try {
      const res = await serv.agreeInternshipApply(applyId)
      if (res) {
        const { data } = res
        if (data) {
          handleSetList('data', [])
          handleSetList('pageNum', 1)
          await getList()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return <div className={styles.notice_motion_wrapper}>
    <List
      loaded={loaded}
      dataSource={list.data}
      hasMore={list.hasMore}
      next={getList}
    >
      {list.data.map(it => <div key={it.id}>
          <ApplyItem item={it} agree={() => showConfirmAgree(it)}/>
        </div>
      )}
    </List>
  </div>
}

export default NoticeOfMotion
