import React, { useEffect, useState } from 'react'
import { Util } from '../../../lib/util'
import serv from '../../../lib/api'
import styles from './index.module.less'
import moment from 'moment'

const RANGE = {
  1: '0 - 120,000',
  2: '120,000 - 240,000',
  3: '240,000 - 360,000',
  4: '> 360,000'
}

const DESC = [
  {
    title: '背景', children: [
      { desc: '奖励业绩活跃的分会长。' }
    ]
  },
  {
    title: '活动安排',
    children: [
      { desc: '出行时间为22年春季，找国内风景漂亮的地方，让大家一起放松的游学，拍美美哒照片。' }
    ]
  },
  {
    title: '奖励规则',
    children: [
      { desc: '①  2021年6月1日-12月31日，分会长直属团队销售额完成12万，奖励1个游学名额；完成24万，奖励2个名额，以此类推。3个名额封顶。' },
      { desc: '②  2021年6月1日-12月31日，招募分会长人数排行榜前十名，每人奖励1个游学名额。如排名相同，以达成时间较早的为准。' }
    ]
  },
  {
    title: '补充',
    children: [
      { desc: '①  以上不同方式获得的名额可以累加。' },
      { desc: '②  福利含食宿、景区游览费用，不含交通费。' },
      { desc: '③  本人名额不可转让，多个名额可邀请团队成员、家人出行' }
    ]
  }
]

function DataItem(props) {
  const { type, invitationCount, isMe, rank, realName, time, expenditureRange, showLine, userId } = props
  const userName = isMe ? realName : Util.formatName(realName || '')
  const icons = []
  if (expenditureRange && expenditureRange > 1) {
    for (let i = 0; i < expenditureRange - 1; i++) {
      icons.push(i + 1)
    }
  }
  return (<div className={`${styles.data_item} ${isMe ? styles.high_light : ''} ${!userId ? styles.no_info : ''}`}>
    {type === 1 && <>
      {/*<span className={styles.rank}>{rank}</span>*/}
      <span className={styles.name}>{userName || '-'}</span>
      <span className={styles.range}>{RANGE[expenditureRange] || '-'}</span>
      <span className={styles.extra}>
        {expenditureRange > 0 && expenditureRange === 1 ? '未达成' : icons.map(it => (
          <i className={styles.icon} key={it}/>))}
      </span>
    </>}
    {type === 2
      ? userId
        ? <>
            <span className={styles.rank}>
              {rank === 1 && <i className={`${styles.rank_icon} ${styles.rank_icon_1}`}/>}
              {rank === 2 && <i className={`${styles.rank_icon} ${styles.rank_icon_2}`}/>}
              {rank === 3 && <i className={`${styles.rank_icon} ${styles.rank_icon_3}`}/>}
              {rank > 3
                ? invitationCount > 0
                  ? rank
                  : '-'
                : ''
              }
            </span>
          <span className={styles.name}>{userName || '-'}</span>
          <span className={styles.recommend_count}>{invitationCount}&nbsp;人</span>
          <span className={styles.complete_time}>{time ? moment(time).format('MM月DD日') + '达成' : '未入围'}</span>
        </>
        : <>
          信息保护
        </>
      : null}
    {showLine && <div className={styles.line}/>}
  </div>)
}

export default function OverseasStudyTour() {
  const [currentTab, setCurrentTab] = useState(1)
  const [achieveList, setAchieveList] = useState([])
  const [equalCntList, setEqualCntList] = useState([])
  const [descVisible, setDescVisible] = useState(false)
  const [tabList, setTabList] = useState([
    { type: 1, label: '消费额', loaded: false },
    { type: 2, label: '推荐', loaded: false }
  ])

  const getStudyTourAchieve = async () => {
    try {
      const res = await serv.getStudyTourAchieve()
      if (res) {
        return res.data || {}
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getStudyTourEqualCnt = async () => {
    try {
      const res = await serv.getStudyTourEqualCnt()
      if (res) {
        return res.data || {}
      }
    } catch (err) {
      console.log(err)
    }
    return null
  }

  useEffect(() => {
    function initData() {
      Util.showLoading('加载中...')
      Promise.all([
        getStudyTourAchieve(),
        getStudyTourEqualCnt()
      ])
      .then(res => {
        const [achieveData, equalCntData] = res
        const { achieveItems: achieveList, description, expenditure, expenditureRange, rate, statisticsAs: achieveStatisticsAs } = achieveData || {}
        const { achieveItems: equalCntList, recommendCount, rank, statisticsAs: equalCntStatisticsAs } = equalCntData || {}
        const [achieveTab, equalCntTab] = tabList
        setTabList([
          { ...achieveTab, description, expenditure, expenditureRange, rate, achieveStatisticsAs, loaded: true },
          { ...equalCntTab, recommendCount, rank, equalCntStatisticsAs, loaded: true }
        ])
        // TODO: 暂时只展示自己的数据，后面会删除这个过滤逻辑
        const tempList = achieveList && achieveList.filter(it => it.isMe)
        setAchieveList(tempList || [])
        const tempCntList = equalCntList && equalCntList.filter(it => (
          !it.userId || (it.userId && (it.invitationCount > 0 || (it.isMe && it.invitationCount === 0)))
        ))
        setEqualCntList(tempCntList || [])
        Util.hideLoading()
      })
      .catch(err => {
        console.log(err)
        const [achieveTab, equalCntTab] = tabList
        setTabList([
          { ...achieveTab, loaded: true },
          { ...equalCntTab, loaded: true }
        ])
        Util.hideLoading()
      })
    }

    if (!tabList[0].loaded) {
      initData()
    }
  }, [tabList])

  const changeTab = (type) => {
    if (type === currentTab) return
    setCurrentTab(type)
  }

  const showDescModal = () => {
    setDescVisible(true)
  }

  const closeDescModal = () => {
    setDescVisible(false)
  }

  const [data1, data2] = tabList
  const { expenditure, expenditureRange, rate } = data1 || {}
  const { recommendCount } = data2 || {}
  const mineInfo = equalCntList.find(it => it.isMe)
  const myRank = mineInfo && mineInfo.invitationCount > 0 ? mineInfo.rank : 0
  const icons = ['default', 'default', 'default']
  if (expenditureRange) {
    if (expenditureRange === 2) {
      icons[0] = 'active'
      icons[1] = 'middle'
    }
    if (expenditureRange === 3) {
      icons[0] = 'active'
      icons[1] = 'active'
      icons[2] = 'middle'
    }
    if (expenditureRange === 4) {
      icons[0] = 'active'
      icons[1] = 'active'
      icons[2] = 'active'
    }
  }

  return (
    <div className={styles.event_tour_wrapper}>
      <img className={styles.event_ad} src="https://cdn.kangarooread.com/mp-assets/crm_youxue_ad_bg.png" alt=""
           onClick={showDescModal}/>
      <div className={styles.tab_list_wrapper}>
        {tabList.map(it => (
          <div
            className={`${styles.item} ${currentTab === it.type ? styles.active : ''}`}
            onClick={() => changeTab(it.type)}
            key={it.type}
          >
            {it.type === 1 && <>
              <div className={styles.value}>{expenditure || '0.00'}</div>
              <div className={styles.label}>{it.label}</div>
              <div className={styles.process_wrapper}>
                {icons.map((it, idx) => (
                  <i className={`${styles.p_icon} ${styles[it]}`} key={idx}/>
                ))}
                <div className={styles.process}>
                  <div className={styles.bar} style={{ width: rate * 100 + '%' }}/>
                </div>
                <span className={styles.rate_num}>{rate && (rate * 100).toFixed(0)}%</span>
              </div>
            </>}
            {it.type === 2 && <>
              <div className={styles.value}>{recommendCount || 0}<span className={styles.unit}>人</span></div>
              <div className={styles.label}>{it.label}</div>
              <div className={styles.cnt_wrapper}>
                <i className={styles.icon}/>
                <span className={styles.count}>{myRank || '未入围'}</span>
              </div>
            </>}
          </div>
        ))}
        <div className={styles.title_bg}/>
        <div className={styles.tab_bg}/>
        <div className={`${styles.bg_left} ${currentTab === 1 ? styles.show : ''}`}/>
        <div className={`${styles.bg_right} ${currentTab === 2 ? styles.show : ''}`}/>
      </div>
      <div className={styles.data_list_wrapper}>
        <div className={styles.statistics_time}>
          -&nbsp;数据更新时间：
          {currentTab === 1 && moment(tabList[0].achieveStatisticsAs).format('YYYY-MM-DD HH:mm')}
          {currentTab === 2 && moment(tabList[1].equalCntStatisticsAs).format('YYYY-MM-DD HH:mm')}
          &nbsp;-
        </div>
        <div className={styles.data_list}>
          {currentTab === 1 && <>
            {achieveList.map((it, idx) => (
              <DataItem type={1} {...it} key={idx} showLine={idx !== achieveList.length - 1}/>))}
          </>}
          {currentTab === 2 && <>
            {equalCntList.map((it, idx) => (
              <DataItem type={2} {...it} key={idx} showLine={idx !== equalCntList.length - 1}/>))}
          </>}
        </div>
      </div>

      {descVisible && <div className={styles.mask} onClick={closeDescModal}/>}
      <div className={`${styles.desc_modal_wrapper} ${descVisible && styles.desc_show}`}>
        <div className={styles.close_icon} onClick={closeDescModal}>+</div>
        {DESC.map((item, index) => (
          <div className={styles.desc_content} key={index}>
            <div className={styles.desc_title}>{item.title}</div>
            {item.children && item.children.map((it, idx) => (
              <div className={styles.desc_text} key={idx}>{it.desc}</div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
