import React, { useEffect, useState } from 'react'
import Item from '../components/Item'
import styles from '../index.module.less'
import serv from '../../../lib/api'
import List from '../../../components/List'
import { Util } from '../../../lib/util'
import { useStore } from 'reto'
import { CommonStore } from '../../../store'
import ActionSheet from '../../../components/ActionSheet'
import { bridge } from '../../../lib/bridge'

export default function MaterialCenter(props) {
	const [allowUpload, setAllowUpload] = useState(false)
	const [list, setList] = useState([])
	const [hasMore, setHasMore] = useState(false)
	const [pageNum, setPageNum] = useState(1)
	const [loaded, setLoaded] = useState(false)
	const [visible, setVisible] = useState(false)
	const {
		showModal,
		closeModal,
		userInfo,
		qrcode1,
		updateQrcode1,
		qrcode2,
		updateQrcode2,
		qrcode3,
		updateQrcode3
	} = useStore(CommonStore)
	const pageSize = 10
	const parent = 'H5CRM_MATERIAL'

	const channelList = [
		{
			id: 1,
			icon: 'https://cdn.kangarooread.com/resource/materialCenter/channle_faquan.png',
			label: '发圈素材',
			route: '/material-center-list?tagId=1'
		},
		{
			id: 2,
			icon: 'https://cdn.kangarooread.com/resource/materialCenter/channel_yinxiao.png',
			label: '营销话术',
			route: ''
		},
		{
			id: 3,
			icon: 'https://cdn.kangarooread.com/resource/materialCenter/channel_tuiguang.png',
			label: '推广材料',
			route: ''
		},
		{
			id: 4,
			icon: 'https://cdn.kangarooread.com/resource/materialCenter/channel_ziliao.png',
			label: '资料库',
			route: ''
		}
	]

	const getList = async () => {
		Util.showLoading('数据加载中...')
		const params = {
			pageNum,
			pageSize
		}
		try {
			const res = await serv.homepageLibraryContent(params)
			if (res) {
				const { data, hasMore } = res
				setList(list.concat(data || []))
				setPageNum(hasMore ? pageNum + 1 : pageNum)
				setHasMore(hasMore)
			}
		} catch (err) {
			console.log(err)
		} finally {
			setLoaded(true)
			Util.hideLoading()
		}
	}

	const getUserQrcode = async (type) => {
		const { id: userId } = userInfo || {}
		let params = {}
		switch (type) {
			case 1:
				params = {
					path: 'subPkg/main/pages/new-subscribe-detail/index',
					scene: `m;;;${userId || ''};1;200`,
					type: 23
				}
				break
			case 2:
				params = {
					path: 'subPkg/main/pages/price-get-detail/index',
					scene: `m;638;${userId || ''};CAMP;638`,
					type: 15
				}
				break
			case 3:
				params = {
					path: 'subPkg/extend/pages/reader-lead/index',
					scene: `m;${userId || ''}`,
					type: 24
				}
				break
			default:
				break
		}
		try {
			const res = await serv.getQrCode(params)
			if (res) {
				const { cdnUrl } = res.data || {}
				switch (type) {
					case 1:
						updateQrcode1(cdnUrl)
						break
					case 2:
						updateQrcode2(cdnUrl)
						break
					case 3:
						updateQrcode3(cdnUrl)
						break
					default:
						break
				}
			}
		} catch (err) {
			console.log(err)
		}
	}

	const getAllowUploadLibrary = async () => {
		try {
			const res = await serv.allowUploadLibrary()
			if (res) {
				const { data } = res
				setAllowUpload(data)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const initData = async () => {
		await getUserQrcode(1)
		await getUserQrcode(2)
		await getUserQrcode(3)
		getAllowUploadLibrary()
		getList()
	}

	useEffect(() => {
		initData()
	}, []) //eslint-disable-line

	const toTabList = (route) => {
		if (route) {
			props.history.push(route)
			serv.dataTrackingStorage({ parent, current: 'MATERIAL_LIST' })
		} else {
			showModal({
				content: `素材准备中，敬请期待~`,
				gravity: 1,
				okBtnText: '确定',
				showModalCloseIcon: false,
				onConfirm: () => {
					closeModal()
				}
			})
			// Util.showToast('素材准备中，敬请期待~')
		}
	}

	const toSearch = () => {
		props.history.push('/material-center-search')
		serv.dataTrackingStorage({ parent, current: 'MATERIAL_SEARCH' })
	}

	const toUpload = () => {
		bridge.appNavigate({ id: 148 })
	}

	const previewQrcode = (type) => {
		let list = []
		switch (type) {
			case 1:
				list = [qrcode1]
				break
			case 2:
				list = [qrcode2]
				break
			case 3:
				list = [qrcode3]
				break
			default:
				break
		}

		const params = {
			list,
			index: 0
		}
		bridge.appImagePreviewList(params).catch((_) => {
			Util.showToast('预览失败')
		})
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.page_head_wrapper}>
				{/*	<div className={styles.tip_wrapper}>*/}
				{/*		<span className={styles.icon}/>*/}
				{/*		<span className={styles.text}>查看更多</span>*/}
				{/*		<span className={styles.right_arrow}/>*/}
				{/*	</div>*/}
				<div className={styles.search_enter} onClick={toSearch}>
					<span className={styles.icon} />
					<span className={styles.text}>搜索</span>
				</div>
				<div
					className={styles.top_btn_wrapper}
					onClick={() => setVisible(true)}>
					<div className={styles.qrcode_icon} />
					<div className={styles.label}>下载个人码</div>
				</div>
				{allowUpload && (
					<div className={styles.top_btn_wrapper} onClick={toUpload}>
						<div className={styles.upload_icon} />
						<div className={styles.label}>上传素材</div>
					</div>
				)}
			</div>
			<div className={styles.content}>
				{/*<div className={styles.banner_wrapper}>*/}
				{/*	<img className={styles.banner} src="https://cdn.kangarooread.com/resource/materialCenter/enter_sucai_img.png"*/}
				{/*	     alt=""/>*/}
				{/*	<img className={styles.banner} src="https://cdn.kangarooread.com/resource/materialCenter/enter_chanpin_img.png"*/}
				{/*	     alt=""/>*/}
				{/*</div>*/}
				<div className={styles.channel_wrapper}>
					{channelList &&
						channelList.map((it) => (
							<div
								className={styles.item}
								key={it.id}
								onClick={() => toTabList(it.route)}>
								<img src={it.icon} className={styles.icon} alt='' />
								<p className={styles.label}>{it.label}</p>
							</div>
						))}
				</div>
				<div className={styles.line} />

				<div className={styles.list}>
					<List
						loaded={loaded}
						dataSource={list}
						hasMore={hasMore}
						next={getList}>
						{list &&
							list.map((it) => (
								<div key={it.id}>
									<div className={styles.list_item_wrapper}>
										<Item item={it} parent={parent} needTag />
									</div>
									<div className={styles.line} />
								</div>
							))}
					</List>
				</div>

				{/*<div className={styles.more_wrapper} onClick={toTabList}>*/}
				{/*	<span className={styles.text}>查看更多素材</span>*/}
				{/*	<span className={styles.right_arrow}/>*/}
				{/*</div>*/}
			</div>

			<ActionSheet
				visible={visible}
				showCloseIcon={false}
				onClose={() => setVisible(false)}>
				<div className={styles.a_content}>
					<div className={styles.a_item} onClick={() => previewQrcode(1)}>
						邀请订阅
					</div>
					<div className={styles.a_item} onClick={() => previewQrcode(2)}>
						邀请故事妈妈
					</div>
					<div className={styles.a_item} onClick={() => previewQrcode(3)}>
						邀请领读者
					</div>
				</div>
			</ActionSheet>
		</div>
	)
}
