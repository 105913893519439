import React from 'react'
import styles from './index.module.less'
import moment from 'moment'
import { bridge } from '../../../../lib/bridge'
import { useStore } from 'reto'
import { CommonStore } from '../../../../store'


const userType = [
  {
    value: 0,
    label: '新注册用户'
  },
  {
    value: 1,
    label: '新注册用户'
  },
  {
    value: 2,
    label: '订阅卡会员过期'
  },
  {
    value: 3,
    label: '订阅卡会员过期'
  },
  {
    value: 4,
    label: '订阅卡会员过期'
  },
  {
    value: 5,
    label: '引流付费未转化'
  },
  {
    value: 6,
    label: '正价服务'
  },
  {
    value: 7,
    label: '长期绑定'
  }
]

export default function SoftBindingItem(props) {
  const { showModal, closeModal,runtimeEnv } = useStore(CommonStore)
  const { data } = props

  const toMemberDetail = (data) => {
    const { type } = data
    if(type > 1){
      if (runtimeEnv === 'app') {
        bridge.appNavigate({ id: 111, params: `id=${data.userId}` })
      }
    }else{
      showModal({
        title: '此用户未付费，无法查看详情',
        gravity: 1,
        okBtnText: '知道了',
        onClose: () => {
          closeModal()
        },
        onConfirm: () => {
          closeModal()
        }
      })
    }

  }

  return (
    <>
      {data ? (
        <div className={styles.item} onClick={() => toMemberDetail(data)}>
          <div className={styles.item_con}>

            <img className={styles.avatar}
                 src={data.avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'} alt=""/>

            <div>
              <div className={styles.username}>{data.nickname || '--'}</div>
              <div className={styles.type_wrapper}>
                {data.type === 2 || data.type === 3 || data.type === 4 ? (
                  <div className={styles.type}>
                    {(userType.find((it) => it.value === data.type) || {}).label ||
                    '--'}
                  </div>
                ) : (
                  <div className={styles.type1}>
                    {(userType.find((it) => it.value === data.type) || {}).label ||
                    '--'}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.date}>
            {data.type === 0 || data.type === 1
              ? `注册时间：${moment(data.paidTime).format('YYYY-MM-DD')}`
              : data.type === 2 || data.type === 3 || data.type === 4
                ? `过期时间：${moment(data.paidTime).format('YYYY-MM-DD')}`
                : data.type === 5
                  ? `付费时间：${moment(data.paidTime).format('YYYY-MM-DD')}`
                  : null}
          </div>
        </div>
      ) : null}
    </>
  )
}
