import React from 'react'
import styles from '../../index.module.less'

export default function GroupManagement(props) {
  // 跳转----->群管理
  const toGroupList = () => {
    const { history } = props
    history.push('/group-list')
  }

  return <>
    {
      props.user.inGroup &&  <div className={styles.assistant_wrapper} onClick={() => toGroupList()}>
        <div className={styles.assistant}>
          <div className={styles.ass_it}>
            <div className={styles.ass_title}>企业微信群</div>
          </div>
          <div className={styles.ass_it}>
            <div className={styles.ass_label}>去管理</div>
            <i className={`iconfont crm-arrow-right ${styles.arrow_icon}`}/>
          </div>
        </div>
      </div>
    }

  </>


}