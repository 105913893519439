import React, { useState } from 'react'
import serv from '../../lib/api'
import styles from './index.module.less'
import { Util } from '../../lib/util'
import { storage } from '../../lib/conf'
import { useStore } from 'reto'
import { CommonStore } from '../../store'

const {
	showToast,
	showLoading,
	hideLoading,
	base64encode,
	setMD5,
	setLocalStorage
} = Util

export default function Login(props) {
	const [codeBtnText, setCodeBtnText] = useState('发送验证码')
	const [loginType, setLoginType] = useState(0)
	const [phone, setPhone] = useState('')
	const [password, setPassword] = useState('')
	const [authCode, setAuthCode] = useState('')
  const { updateUserInfo } = useStore(CommonStore)

	const changePhone = (e) => {
		const { value } = e.target
		setPhone(value.trim())
	}

	const changePassword = (e) => {
		const { value } = e.target
		setPassword(value.trim())
	}

	const changeAutCode = (e) => {
		const { value } = e.target
		setAuthCode(value.trim())
	}

	const checkPhone = async () => {
		if (!phone) {
			showToast('请输入手机号')
			return
		}
		showLoading()
		try {
			const res = await serv.checkPhone(phone)
			if (res) {
				const { data } = res
				setLoginType(data)
				if (data && data === 4) {
					showToast('您没有登录权限')
				}
			}
		} catch (err) {
			showToast(JSON.stringify(err) || '异常信息')
			console.log(err)
		}
		hideLoading()
	}

	const handleSendCode = async () => {
		const params = { mobile: phone, type: 2 }
		const res = await serv.sendCode(params)
		try {
			if (res) {
				let num = 60
				setCodeBtnText(num + 's后发送')
				let time = setInterval(() => {
					if (num > 1) {
						num--
						setCodeBtnText(num + 's后发送')
					} else {
						setCodeBtnText('重新发送')
						clearInterval(time)
					}
				}, 999)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const handleLogin = async () => {
		if (password) {
			const validFields =
				loginType === 2 ? { phone, password, authCode } : { phone, password }
			if (loginType === 2) {
				validFields.password = base64encode(
					`haoqibaobao_${validFields.password}_${new Date().getTime()}`
				)
			} else {
				validFields.password = setMD5(validFields.password)
			}
			await processLogin(validFields)
		} else {
			Util.showToast('密码不能为空')
		}
	}

	const processLogin = async (values) => {
		showLoading()
		try {
			const res = await serv.processLogin(values)
			if (res) {
				const { data } = res
				setLocalStorage(storage.userInfo, JSON.stringify({ ...data }))
        updateUserInfo(data || null)
				props.history.push('/')
				hideLoading()
			} else {
				showToast('密码错误')
			}
		} catch (err) {
			console.log(err)
		}
		hideLoading()
	}

	return (
		<div className={styles.container}>
			<div className={styles.login_form}>
				<div className={styles.title}>经营管理</div>
				<input
					className={styles.input}
					value={phone}
					onChange={changePhone}
					placeholder='请填写手机号'
				/>
				{loginType === 2 || loginType === 3 ? (
					<input
						className={styles.input}
						value={password}
						placeholder='请输入密码'
						type='password'
						onChange={changePassword}
					/>
				) : null}
				{loginType === 2 ? (
					<div className={styles.code}>
						<input
							className={`${styles.input} ${styles.input_code}`}
							value={authCode}
							placeholder='请输入验证码'
							onChange={changeAutCode}
						/>
						<button
							type='button'
							className={styles.code_btn}
							onClick={handleSendCode}>
							{codeBtnText}
						</button>
					</div>
				) : null}
				{loginType === 2 || loginType === 3 ? (
					<button type='button' className={styles.btn} onClick={handleLogin}>
						登 录
					</button>
				) : (
					<button type='button' className={styles.btn} onClick={checkPhone}>
						校 验
					</button>
				)}
			</div>
		</div>
	)
}
