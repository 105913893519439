import fetch from '../fetch'

const thirdParty = {
	/**
	 * @description 获取工猫签约状态
	 * @returns {*}
	 */
	checkContractStatus() {
		return fetch('/thirdparty/gongmall/check_contract_status', {
			method: 'POST'
		})
	},

	/**
	 * @description 同步员工信息
	 * @param params
	 * @returns {*}
	 */
	syncEmployeeInfo(params = {}) {
		return fetch('/thirdparty/gongmall/sync_employee_info', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 工猫电子签约
	 * @param authCode
	 * @returns {*}
	 */
	signContract(authCode = '') {
		return fetch('/thirdparty/gongmall/sign_contract', {
			params: { authCode }
		})
	},

	/**
	 * @description 获取qrcode
	 * @param params
	 * @returns {*}
	 */
	getQrCode(params = {}) {
		return fetch('/thirdparty/wechat/get_qr_code_v2', {
			params: { ...params }
		})
	}
}

export default thirdParty
