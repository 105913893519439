import React from 'react'
import styles from './index.module.less'
import { Util } from '../../../../lib/util'

export default function UpgradeModal({ visible, onClose, level }) {
  const closeModal = () => {
    onClose()
  }

  return <>
    {
      visible && <div className={styles.modal_wrapper}>
        <div className={styles.upgrade_bg}>
          <div className={styles.upgrade_info}>
            <div className={styles.tip}>恭喜你</div>
            <div className={styles.tip}> 感谢你对家庭教育的贡献，</div>
            <div className={styles.tip}> 你已完成升级考核。祝贺成为</div>
            <div className={styles.identity}>{
              Util.getLevel(level) === 'senior' ? '资深领读者' :
                Util.getLevel(level) === 'tutor' ? '领读者导师' : ''
            }</div>
            <div className={styles.tip}> 再接再厉！</div>
          </div>
        </div>
        <div className={styles.btn} onClick={closeModal}>确认</div>
      </div>
    }
  </>
}