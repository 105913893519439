import fetch from '../fetch'

const distribution = {
  // 获取评分页面基本信息
  getServiceScoreInfo() {
    return fetch('/distribution/mp/service_score/info')
  },
  /**
   * @description 收益明细列表
   * @param {*} params
   */
  getAwardList(params = {}) {
    return fetch('/distribution/crm/award/award_list', {
      params: { ...params },
      v2: true
    })
  },
  /**
   * 总收益信息
   * @returns
   */
  getAchieveOverview(month = '') {
    return fetch(`/distribution/crm/award/achieve_overview/${month}`)
  },
  /**
   *@description 团队
   * @param {*} userId
   * @returns
   */
  getAffiliationTeamStatistics(userId = '') {
    return fetch('/distribution/crm/team/affiliation/team_statistics', {
      params: { userId },
      v2: true
    })
  },
  /**
   * @description 收益总览
   * @returns
   */
  getAwardOverView() {
    return fetch('/distribution/crm/award/award_overview')
  },
  /**
   * 首页卡包钱包
   * @returns
   */
  getHomePage() {
    return fetch('/distribution/crm/award/homepage', {
      v2: true
    })
  },
  /**
   * 我的团队/平级消费额列表
   * @param {*} params
   * @returns
   */
  teamAchieveList(params = {}) {
    return fetch('/distribution/crm/award/team_achieve_list', {
      params: { ...params }
    })
  },
  /**
   * 消费额明细列表
   * @param {*} params
   * @returns
   */
  userAchieveList(params = {}) {
    return fetch('/distribution/crm/award/user_achieve_list', {
      params: { ...params }
    })
  },
  /**
   * @description 会员列表
   * @param {*} params
   * @returns
   */
  getCommonList(params = {}) {
    return fetch('/distribution/crm/team_member/get_common_list', {
      params: { ...params },
      v2: true
    })
  },
  /**
   * @description 分会推广人
   * @param {*} params
   * @returns
   */
  getPartnerList(params = {}) {
    return fetch('/distribution/crm/team_member/get_partner_list', {
      params: { ...params },
      v2: true
    })
  },

  /**
   * @description 活动接口-2021分会长游学消费额达成情况
   * @returns {*}
   */
  getStudyTourAchieve() {
    return fetch('/distribution/mp/award/study_tour_achieve')
  },

  /**
   * @description 活动接口-2021分会长游学邀请分会人数
   * @returns {*}
   */
  getStudyTourEqualCnt() {
    return fetch('/distribution/mp/award/study_tour_equal_cnt')
  },

  /**
   * @description 获取升级为领读者的推广人、分会
   * @param params
   * @returns {*}
   */
  getReaderPartner(params = {}) {
    return fetch(`/distribution/crm/reader/member/get_reader_partner`, {
      params: { ...params },
      v2: true
    })
  },
  /**
   * @description 获取团队数据
   * @returns {*}
   */
  getTeamService() {
    return fetch(`/distribution/crm/reader/member/team_service`, {
      v2: true
    })
  },

  /**
   * @description 获取普通、资深令读者列表
   * @param params
   * @returns {*}
   */
  getMemberReaderList(params = {}) {
    return fetch(`/distribution/crm/reader/member/reader_list`, {
      method: 'POST',
      data: { ...params },
      v2: true
    })
  },

  /**
   * @description 领读者列表详情
   * @param userId
   * @returns {*}
   */
  getMemberReaderDetail(userId = '') {
    return fetch(`/distribution/crm/reader/member/reader_detail`, {
      params: { userId },
      v2: true
    })
  },

  /**
   * @description 领读者KPI进度
   * @returns {*}
   */
  getReaderKpiProgress() {
    return fetch(`/distribution/app/reader/upgrade/get_reader_kpi_progress`, {
      v2: true
    })
  },

  /**
   * @description
   获取履约会员增长日历
   * @param params
   * @returns {*}
   */
  getCommonIncrCalendar(params = {}) {
    return fetch(`/distribution/app/reader/upgrade/get_common_incr_calendar`, {
      params: { ...params },
      v2: true
    })
  },

  /**
   * @description 获取服务质量分列表
   * @returns {*}
   */
  getServiceScoreList() {
    return fetch(`/distribution/app/reader/upgrade/service_score_list`, {
      v2: true
    })
  },

  /**
   * @description 获取三星领读者列表
   * @returns {*}
   */
  getSpecialLevelList() {
    return fetch(`/distribution/app/reader/upgrade/special_level_list`, {
      v2: true
    })
  },

  /**
   * @description获取升星信息接口
   * @returns {*}
   */
  getUpgradeResult() {
    return fetch(`/distribution/app/reader/upgrade/get_upgrade_result`, {
      v2: true

    })
  },

  /**
   * @description 获取群成员列表
   * @param params
   * @returns {*}
   */
  getGroupList(params = {}) {
    return fetch(`/distribution/crm/subscribe_member/group/member_group_list`, {
      params: { ...params },
      v2: true
    })
  },

  /**
   * @description 获取群成员
   * @param groupId
   * @returns {*}
   */
  getGroupDetail(params) {
    return fetch(`/distribution/crm/subscribe_member/group/member_list`, {
      params: { ...params },
      v2: true
    })
  },

  /**
   * @description 新政策业绩详情
   * @param params
   * @returns {*}
   */
  getAchievementDetail(params = {}) {
    return fetch(`/distribution/crm/performance/page/achievement_detail`, {
      method: 'POST',
      data: { ...params },
      v2: true
    })
  },

  /**
   * @description 新政策领读者月度业绩
   * @returns {*}
   * @param month
   */
  getTeamMonthlyAchievement(month = '') {
    return fetch(`/distribution/crm/performance/team_monthly_achievement`, {
      params: { month },
      v2: true
    })
  },

  /**
   * @description 新政策业绩详情
   * @param params
   * @returns {*}
   */
  getAchievementList(params = {}) {
    return fetch(`/distribution/crm/performance/page/achievement_list`, {
      method: 'POST',
      data: { ...params },
      v2: true
    })
  },

  /**
   * @description 升级进度
   * @param targetLevel
   * @returns {*}
   */
  getMemberUpdateProgress(targetLevel = '') {
    return fetch(`/distribution/crm/reader/member/update_progress`, {
      params: { targetLevel },
      v2: true
    })
  },

  /**
   * @description 实习领读者收入
   * @param params
   * @returns {*}
   */
  getInternRevenueList(params = {}) {
    return fetch(`/distribution/crm/performance/page/intern_revenue_list`, {
      method: 'POST',
      data: { ...params },
      v2: true
    })
  },

  /**
   * @description  实习领读者
   * @param params
   * @returns {*}
   */
  getInternMemberList(params = {}) {
    return fetch(`/distribution/crm/reader/member/page/intern_list`, {
      method: 'POST',
      data: { ...params },
      v2: true
    })
  },

  /**
   * @description  实习领读者详情
   * @param params
   * @returns {*}
   */
  getInternRevenueDetail(params = {}) {
    return fetch(`/distribution/crm/performance/page/intern_revenue_detail`, {
      method: 'POST',
      data: { ...params },
      v2: true
    })
  },

  /**
   * @description  确认升级弹窗
   * @returns {*}
   */
  confirmUpgrade() {
    return fetch(`/distribution/crm/reader/member/confirm_upgrade`, {
      v2: true
    })
  },
}

export default distribution
