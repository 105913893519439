import React, { useEffect, useState } from 'react'
import styles from '../index.module.less'
import Item from '../components/Item'

export default function MaterialCardList() {
	const [tabList, setTabList] = useState([])
	const [selectedId, setSelectedId] = useState(0)
	const [list, setList] = useState([])

	const getTabList = async () => {
		try {
			const list = []
			for (let i = 0; i < 6; i++) {
				list.push({
					id: i + 1,
					label: `菜单${i + 1}`
				})
			}
			list.unshift({
				id: 0,
				label: '全部'
			})
			setTabList(list)
		} catch (err) {
			console.log(err)
		}
	}

	const getList = async () => {
		try {
			const list = []
			for (let i = 0; i < 10; i++) {
				list.push({
					id: i + 1,
					// content: '晚安\n人总需要做些什么，\n才能体现出自己的价值，\n虽然做事请有时候会痛苦，\n虽然做事请有时候会痛苦，\n虽然做事请有时候会痛苦',
					content: '晚安，人总需要做些什么，才能体现出自己的价值，虽然做事请有时候会痛苦，虽然做事请有时候会痛苦，虽然做事请有时候会痛苦，虽然做事请有时候会痛苦，虽然做事请有时候会痛苦，',
					time: '2021-09-17 16:36',
					imgList: ['img1', 'img2', 'img3', 'img4', 'img5', 'img6', 'img7', 'img8', 'img9']
					// imgList: ['img1']
				})
			}
			setList(list)
		} catch (err) {
			console.log(err)
		}
	}

	const initData = async () => {
		await getTabList()
		await getList()
	}

	useEffect(() => {
		initData()
	}, []) //eslint-disable-line

	const switchTab = (id) => {
		if (id === selectedId) return
		setSelectedId(id)
	}

	return <div className={styles.card_list_wrapper}>
		<div className={styles.tab}>
			{tabList && tabList.map(it => <span className={`${styles.item} ${it.id === selectedId && styles.active}`}
			                                    key={it.id} onClick={() => switchTab(it.id)}>{it.label} <span
				className={styles.dot}/></span>)}
		</div>
		<div className={styles.sub_tab}>
			{tabList && tabList.map(it => <span className={`${styles.sub_tab_item} ${it.id === selectedId && styles.active}`}
			                                    key={it.id} onClick={() => switchTab(it.id)}>{it.label}</span>)}
		</div>
		<div className={styles.list}>
			{list && list.map(it => <>
				<div className={styles.card_wrapper}>
					<div className={styles.card_title}>
						<span className={styles.text}>· 跟关系近的好友聊天</span>
						<span className={styles.more_btn}>全部</span>
					</div>
					<Item item={it} needUser={false} key={it.id}/>
				</div>
				<div className={styles.sp_line}/>
			</>)}
		</div>
	</div>
}
