import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import serv from '../../lib/api'
import { Util } from '../../lib/util'
import ActionSheet from '../../components/ActionSheet'

export default function MemberRemark(props) {
  const [titleLength, setTitleLength] = useState(0)
  const [areaLength, setAreaLength] = useState(0)
  const [title, setTitle] = useState('')
  const [area, setArea] = useState('')
  const [id, setId] = useState('')
  const [userId, setUserId] = useState('')
  const [detail, setDetail] = useState('')
  const [edit, setEdit] = useState(false)
  const [visible, setVisible] = useState(false)
  const [selectList, setSelectList] = useState([])

  const [list, setList] = useState([])
  const [searchInfo, setSearchInfo] = useState('')
  useEffect(() => {
    document.title = '会员备注编辑'
    setId(props.match.params.id)
    setUserId(props.match.params.userId)
    if (props.match.params.id !== '-1') {
      getRecordDetail(props.match.params.id)
    }
  }, []) // eslint-disable-line

  /**
   * @description 获取备注详情
   * @param id
   * @returns {Promise<void>}
   */
  const getRecordDetail = async (id) => {
    try {
      const res = await serv.getRecordDetail(id)
      if (res) {
        const { data } = res
        setDetail(data || {})
        setTitle(data.node)
        setArea(data.content)
      }
    } catch (e) {
      console.log(e)
    }
  }

  //标题修改
  const changeTitleInput = (e) => {
    const { value } = e.target
    setTitleLength(value.length)
    setTitle(value)
  }

  //内容修改
  const changeContentArea = (e) => {
    const { value } = e.target
    setAreaLength(value.length)
    setArea(value)
  }

  //会员搜索
  const changeMember = (e) => {
    const { value } = e.target
    setSearchInfo(value)
  }

  //选择成员
  const changeSelect = (it) => {
    it.isSelect = !it.isSelect
    if (it.isSelect) {
      selectList.push(it)
      setSelectList([...selectList])
    } else {
      selectList.forEach((i, index) => {
        if (i.userId === it.userId) {
          selectList.splice(index, 1)
        }
      })
      setSelectList([...selectList])
    }
    setList([...list])
  }

  //删除成员
  const deleteUserItem = (i) => {
    selectList.splice(i, 1)
    setSelectList([...selectList])
  }

  /**
   * @description 创建备注
   * @param params
   * @returns {Promise<void>}
   */
  const createVisitRecord = async (params) => {
    try {
      const res = await serv.createVisitRecord(params)
      if (res) {
        const { data } = res
        if (data) {
          Util.showToast('已提交，前往会员详情查看')
          props.history.goBack()
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @description 更新备注（status -10删除）
   * @param params
   * @returns {Promise<void>}
   */
  const updateVisitRecord = async (params) => {
    try {
      const res = await serv.updateVisitRecord(params)
      if (res) {
        const { data } = res
        if (data) {
          Util.showToast('操作成功')
          props.history.goBack()
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @description 搜索成员
   * @returns {Promise<void>}
   */
  const searchMember = async () => {
    const params = {
      size: 100,
      pageNum: 1,
      keyword: searchInfo
    }
    try {
      const res = await serv.searchMember(params)
      if (res) {
        const { data } = res
        data && data.forEach((it) => {
          selectList.forEach(i => {
            if (it.userId === i.userId) {
              it.isSelect = true
            }
          })
        })
        setList(data || [])
      }
    } catch (e) {
      console.log(e)
    }
  }

  //提交信息
  const submitInfo = async (str) => {
    const { userId, id } = props.match.params
    if (!title) return Util.showToast('请填写标题')
    if (!area) return Util.showToast('请填写备注内容')
    if (userId === '-1' && selectList.length === 0) return Util.showToast('请选择会员')
    const params = {
      content: area,
      node: title
    }
    if (userId === '-1') {
      params.userIds = selectList.map((it) => it.userId)
    } else {
      params.userIds = [userId * 1]
    }

    if (id === '-1') {
      await createVisitRecord(params)
    } else {
      if (str) {
        console.log(str)
        params.status = -10
      }
      params.id = id
      await updateVisitRecord(params)
    }
  }

  // id === -1 会员页内新增备注
  // userId === -1 首页内新增备注

  return <div className={styles.member_remark_wrapper}>
    {
      id === '-1' || edit ? <div className={styles.title_wrapper}>
        <input placeholder={'请输入标题'} className={styles.input} defaultValue={detail.node} maxLength={20}
               onChange={changeTitleInput}/>
        <div className={styles.length}>{titleLength}/20</div>
      </div> : <div className={styles.title}>{detail.node}</div>
    }
    <div className={styles.line}/>
    <div className={styles.content_wrapper}>
      {
        id === '-1' || edit ? <div>
          <textarea placeholder={'请输入正文'} className={styles.area} defaultValue={detail.content} maxLength={200}
                    onChange={changeContentArea}/>
          <div className={styles.length}>{areaLength}/200</div>
        </div> : <div className={styles.content}>{detail.content}</div>
      }
    </div>

    {/*选择会员*/}
    {
      userId === '-1' && <div className={styles.add_more}>
        <div className={styles.line}/>
        <div className={styles.add_more_title} onClick={async () => {
          setVisible(true)
          await searchMember()
        }}>
          <div className={styles.add_icon}/>
          添加用户
        </div>
      </div>
    }

    {/*会员列表*/}
    {
      selectList.length > 0 &&
      <div className={styles.user_list_wrapper}>
        {
          selectList.map((it, index) => (
            <div className={styles.user_item}>
              <div className={styles.user_left}>
                <img src={it.avatar} alt={''} className={styles.user_avatar}/>
                {it.nickname}
                <div className={styles.user_line}/>
                {it.phone}
              </div>
              <i className="iconfont crm-del" onClick={() => deleteUserItem(index)}/>
            </div>
          ))
        }
      </div>
    }

    {/*提交按钮*/}
    <div className={`${styles.btn_wrapper} ${userId === '-1' ? styles.user_active : ''}`}>
      {
        id === '-1' || edit ? <div className={styles.submit_btn} onClick={()=>submitInfo()}>提交</div>
          : <div className={styles.m_btn}>
            <div className={styles.delete_btn} onClick={() => submitInfo('del')}>删除</div>
            <div className={styles.submit_btn} onClick={() => setEdit(true)}>修改</div>
          </div>
      }
    </div>

    {/*会员弹窗*/}
    <ActionSheet visible={visible} showCloseIcon={false} onClose={() => setVisible(false)}>
      <div className={styles.modal_wrapper}>
        <div className={styles.model_title_wrapper}>
          <div>选择添加备注的用户</div>
          <div className={styles.close_icon} onClick={() => setVisible(false)}/>
        </div>
        <div className={styles.search_wrapper}>
          <div className={styles.search_icon}/>
          <input className={styles.search_input} placeholder={'请输入昵称或手机号'} onChange={changeMember}/>
          <div onClick={searchMember}>搜索</div>
        </div>
        <div className={styles.list_wrapper}>
          {
            list && list.map((it) => (
              <div className={styles.list_item} key={it.userId} onClick={() => changeSelect(it)}>
                <img
                  src={`https://cdn.kangarooread.com/mp-assets/m-web/check_icon_${it.isSelect ? 'active' : 'default'}.png`}
                  alt={''}
                  loading="lazy"
                  className={styles.check_icon}/>
                <img src={it.avatar} alt={''} className={styles.avatar} loading="lazy"/>
                {it.nickname}
                <div className={styles.list_line}/>
                {it.phone}
              </div>
            ))
          }
        </div>
        <div className={styles.modal_btn} onClick={() => setVisible(false)}>确认</div>
      </div>
    </ActionSheet>
  </div>
}