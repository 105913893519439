import React from 'react'
import styles from '../../index.module.less'
import { bridge } from '../../../../lib/bridge'
import { useStore } from 'reto'
import { CommonStore } from '../../../../store'

export default function Assistant({ campId, assistantRole,assLoaded,user }) {
  const { runtimeEnv } = useStore(CommonStore)

  // 跳转----->助教管理
  const toAssistant = () => {
    if (runtimeEnv === 'app') {
      bridge.appNavigate({ id: 126, params: `id=${campId}` })
    }
  }

  return <>
    {
      assLoaded && user.role > 1 && user.haveApply === 1 ?
        <div className={styles.assistant_wrapper} onClick={() => toAssistant()}>
          <div className={styles.assistant}>
            <div className={styles.ass_it}>
              <div className={styles.ass_title}>助教管理系统</div>
              {assistantRole === 1 ? <div className={`${styles.ass_icon1}`}/>
                : assistantRole === 2 ? <div className={`${styles.ass_icon2}`}/>
                  : assistantRole === 3 ? <div className={`${styles.ass_icon3}`}/>
                    : assistantRole === 4 ? <div className={`${styles.ass_icon4}`}/> : null
              }
            </div>
            <div className={styles.ass_it}>
              <div className={styles.ass_label}>去管理</div>
              <i className={`iconfont crm-arrow-right ${styles.arrow_icon}`}/>
            </div>
          </div>
        </div>
        : null
    }
  </>


}