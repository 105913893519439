import React from 'react'
import styles from './index.module.less'

export default function Card(props) {
	const { title, extra, children, onClick } = props
	return (
		<div className={styles.card_wrapper}>
			<div className={styles.l_card}>
				<div className={styles.l_card_top_space}>
					<div className={styles.l_card_title}>{title}</div>
					<div className={styles.l_card_extra}>
						{extra ? (
							<div className={styles.l_card_e_right} onClick={onClick}>
								{extra}
							</div>
						) : null}
					</div>
				</div>
				<div className={styles.l_card_content}>{children}</div>
			</div>
		</div>
	)
}
