import React, { Component } from 'react'
import './index.less'

class Toast extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      text: ''
    }
    window.showToast = this.showToast = this.showToast.bind(this)
  }

  showToast(text, duration) {
    this.setState({
      text,
      visible: true
    })
    setTimeout(() => {
      this.setState({
        visible: false
      })
    }, duration)
  }

  render() {
    const { visible, text } = this.state
    return (
      <div className={`toast-wrapper ${visible ? 'show' : ''}`}>
        <div className={`toast ${visible ? 'show' : ''}`}>{text}</div>
      </div>
    )
  }
}


export default Toast
