import React, { useState, useEffect } from 'react'
import styles from './index.module.less'
import serv from '../../../../lib/api'
import moment from 'moment'
import List from '../../../../components/List'

export default function AbnormalDeatil() {
	const [list, setList] = useState({
		data: [],
		size: 20,
		pageNum: 1,
		hasMore: false
	})
	const [loaded, setLoaded] = useState(false)

	const handleSetList = (key, value) => {
		list[key] = value
		setList({ ...list })
	}

	useEffect(() => {
		document.title = '异常会员'
		setLoaded(false)
		getUnusualMemberData()
    // eslint-disable-next-line
	}, [])

	const getUnusualMemberData = async () => {
		const { size, pageNum } = list
		const res = await serv.getAllAbnormalUser({ size, pageNum })
		try {
			if (res) {
				const { data, hasMore } = res
				handleSetList('hasMore', hasMore)
				handleSetList('data', list.data.concat(data  || []))
				if (hasMore) {
					handleSetList('pageNum', list.pageNum + 1)
				}
				setLoaded(true)
			}
		} catch (err) {
			console.log(err)
		}
	}
	return (
		<div className={styles.container}>
			<List
				next={getUnusualMemberData}
				loaded={loaded}
				dataSource={list.data}
				hasMore={list.hasMore}>
				{list.data.map((it, idx) => (
				  <div>
					<div className={styles.item} key={idx}>
						<div className={styles.title}>
							<img className={styles.img} src={it.avatar} alt=''/>
							<div>
								<div className={styles.nickname}>{it.nickname}</div>
								<div className={styles.date}>
									{moment(it.updateTime).format('yyyy-MM-DD')}
								</div>
							</div>
						</div>
						<div className={styles.remark}>{it.description}</div>
					</div>
            <div  className={styles.line}/>
          </div>
				))}
			</List>
		</div>
	)
}
