import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import List from '../../components/List'
import { Util } from '../../lib/util'
import serv from '../../lib/api'
import moment from 'moment'

export default function InternshipLeadReaderDetail(props) {
	const [loaded, setLoaded] = useState(false)
	const [date, setDate] = useState('')
	const [internId, setInternId] = useState('')
	const [total, setTotal] = useState(0)
	const [month, setMonth] = useState(null)
	const [list, setList] = useState({
		data: [],
		size: 20,
		pageNum: 1,
		loading: false,
		hasMore: false
	})

	useEffect(() => {
		const id = props.match.params.id
		setInternId(id)
		setDate(props.match.params.date)
		getList(id, props.match.params.date)
		document.title = '实习奖励明细'
	}, []) //eslint-disable-line

	const handleSetList = (key, value) => {
		list[key] = value
		setList({ ...list })
	}

	const getList = async (id, d) => {
		Util.showLoading()
		const params = {
			pageNum: list.pageNum,
			size: list.size,
			internId: id || internId
		}

		if (d !== '-1' && date !== '-1') {
			console.log(d, date)
			params.month = date || d
		}

		try {
			const res = await serv.getInternRevenueDetail(params)
			if (res) {
				const { data, hasMore } = res
				const { itemList, total: t, month: m } = data || {}
				setTotal(t)
				setMonth(m)
				handleSetList('data', list.data.concat(itemList || []))
				handleSetList('hasMore', hasMore)
				if (hasMore) {
					handleSetList('pageNum', list.pageNum + 1)
				}
				setLoaded(true)
			}
		} catch (err) {
			console.log(err)
		} finally {
			Util.hideLoading()
		}
	}

	const toMaterialCenter = () => {
		props.history.push('/material-center-home')
	}

	return (
		<div className={styles.internship_detail_wrapper}>
			<div className={styles.i_head_wrapper}>
				{date !== '-1' && <div className={styles.date}>{month}月收入</div>}
				<div className={styles.value}>{total || 0}</div>
				<div className={styles.icon} onClick={toMaterialCenter} />
			</div>
			<div className={styles.list_wrapper}>
				<List
					loaded={loaded}
					dataSource={list.data}
					hasMore={list.hasMore}
					next={getList}>
					{list.data &&
						list.data.map((it) => (
							<div key={it.id}>
								<div className={styles.list_item}>
									<div className={styles.item_left}>
										<div className={styles.title}>{it.title}</div>
										<div className={styles.date}>
											{moment(it.createTime).format('YYYY-MM-DD HH:ss')}
										</div>
									</div>
									<div className={styles.value}>
										{it.operation === 1 ? (
											<span className={styles.add_value}>{it.balance}</span>
										) : (
											<span className={styles.reduce_value}>{it.balance}</span>
										)}
									</div>
								</div>
								<div className={styles.line} />
							</div>
						))}
				</List>
			</div>
		</div>
	)
}
