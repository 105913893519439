import React from 'react'
import styles from '../../index.module.less'
import { env } from '../../../../lib/conf'
import { bridge } from '../../../../lib/bridge'
import { useStore } from 'reto'
import { CommonStore } from '../../../../store'

const FUNCTION_ICON = [
  { label: '经营协议', png: 'function1.png' },
  { label: '授权证书', png: 'function2.png' },
  { label: '企业微信名片', png: 'function3.png' },
  { label: '实名认证', png: 'function4.png' }
]
export default function BottomModule({loaded}) {
  const { runtimeEnv } = useStore(CommonStore)

  //跳转 ------> 底部功能模块
  const toAppDetail = (e, index) => {
    switch (index) {
      case 0:
        if (runtimeEnv === 'app') {
          console.log('id=138')
          bridge.appNavigate({ id: 138 })
        }
        break
      case 1:
        if (runtimeEnv === 'app') {
          console.log('id=139')
          bridge.appNavigate({ id: 139 })
        }
        break
      case 2:
        if (runtimeEnv === 'app') {
          console.log('id=140')
          bridge.appNavigate({ id: 140 })
        }
        break
      case 3:
        if (runtimeEnv === 'app') {
          console.log('id=141')
          bridge.appNavigate({ id: 141 })
        }
        break
      default:
        break
    }
  }

  return <>
    {loaded &&   <div className={styles.function_wrapper}>
      <div className={styles.function}>
        {
          FUNCTION_ICON.map((it, index) => (
            <div className={styles.function_item} key={index} onClick={(e) => toAppDetail(e, index)}>
              <img src={`${env.IMG}m-web/${it.png}`} alt={``} className={styles.function_icon}/>
              <div className={styles.function_label}>{it.label}</div>
            </div>
          ))
        }
      </div>
    </div>

    }
  </>



}