import React, { Component } from 'react'
import './index.less'
import { env } from '../../lib/conf'

class Loading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      text: ''
    }
    window.showLoading = this.showLoading = this.showLoading.bind(this)
    window.hideLoading = this.hideLoading = this.hideLoading.bind(this)
  }

  showLoading = (text) => {
    this.setState({
      text,
      visible: true
    })
  }

  hideLoading = () => {
    this.setState({
      text: '',
      visible: false
    })
  }

  render() {
    const { text, visible } = this.state

    return (
      <div className={`loading-wrapper ${visible ? 'show' : ''}`}>
        <div className="mask"/>
        <div className="loading">
          <img src={`${env.IMG}loading.png`} className="icon" alt=""/>
          {text ? <div className="text">{text}</div> : null}
        </div>
      </div>
    )
  }

}

export default Loading