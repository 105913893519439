import React from 'react'
import styles from '../../index.module.less'
import moment from 'moment'

const ApplyItem = (props) => {
  const { nickname, createTime, status } = props.item

  return <div className={styles.apply_item}>
    <div className={styles.item_info}>
      <div className={styles.desc}>{nickname}申请成为实习领读者</div>
      <div className={styles.time}>{moment(createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
    </div>
    {status === 0 && <button className={styles.btn} onClick={props.agree}>同意</button>}
    {status === 10 && <button className={`${styles.btn} ${styles.disabled}`}>已同意</button>}
    <div className={styles.line}/>
  </div>
}

export default ApplyItem
