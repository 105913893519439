import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import { env } from '../../lib/conf'
import VisitItem from './components/visitItem'
import SoftBindingItem from './components/softbinding'
import serv from '../../lib/api'
import { Util } from '../../lib/util'
import List from '../../components/List'
import { useStore } from 'reto'
import { CommonStore } from '../../store'


const TAB = [
  { value: 1, label: '须回访' },
  { value: 2, label: '预绑定' }
]

const NEW_TAB = [
  { value: 2, label: '服务即将到期' },
  { value: 1, label: '注册用户' },
  { value: 5, label: '引流付费用户' }
]

const dataType = [
  { value: 1, label: '新注册用户', isChecked: false },
  { value: 2, label: '订阅卡会员过期', isChecked: false },
  { value: 5, label: '引流付费未转化', isChecked: false }
]

const HEADTYPE = [
  { value: 1, label: '按时间' },
  { value: 2, label: '按解除绑定时间' }
]

export default function ReturnVisit(props) {
  const { showTypeModal, closeTypeModal } = useStore(CommonStore)
  const [typeValue, setTypeValue] = useState('全部用户')
  const [currentTab, setCurrentTab] = useState(1)
  const [readerCurrentTab, setReaderCurrentTab] = useState(2)
  const [headType, setHeadType] = useState(1)
  const [bindingOrd, setBindingOrd] = useState(1)
  const [expiredOrd, setExpiredOrd] = useState(1)
  const [level, setLevel] = useState(1) //区分新老政策

  const [list, setList] = useState({
    data: [],
    size: 20,
    pageNum: 1,
    hasMore: false,
    type: 1
  })
  const [loaded, setLoaded] = useState(false)

  const handleSetList = (key, value) => {
    list[key] = value
    setList({ ...list })
  }

  useEffect(() => {
    document.title = '须回访'
    handleSetList('data', [])
    handleSetList('pageNum', 1)
    window.addEventListener('hashchange', () => {
      closeTypeModal()
    })
    iniData()
    setLoaded(false)
// eslint-disable-next-line
  }, [currentTab, typeValue, headType, readerCurrentTab])

  const iniData = async () => {
    const level = props.match.params.level
    setLevel(level * 1)
    if (level && level > 0) {
      if (readerCurrentTab === 1) {
        await getTempBindingList(readerCurrentTab)
      } else if (readerCurrentTab === 5) {
        await getTempBindingList(readerCurrentTab)
      } else {
        await getExpireList()
      }
    } else {
      if (currentTab === 1 || currentTab === 0) {
        await getDueSoonList()
      } else {
        await getTempBindingList()
      }
    }
  }

  /**
   * @description tab改变时的回调
   * @param {*} value
   */
  const changeTab = async (value) => {
    setCurrentTab(value)
    closeTypeModal()
  }

  /**
   * @description tab改变时的回调
   * @param {*} value
   */
  const changeTabNew = async (value) => {
    switch (value) {
      case 2 :
        setReaderCurrentTab(value)
        break
      case 1:
        setReaderCurrentTab(value)
        break
      case 5 :
        setReaderCurrentTab(value)
        break
      default:
        break

    }
  }

  /**
   * @description 待回访选择类型的回调
   * @param {*} value
   */
  const changeHeadType = (value) => {
    setHeadType(value)
    setBindingOrd(value)
    setExpiredOrd(value)
  }


  /**
   * @description 软绑定type类型的回调
   */
  const changeSoftHeadType = () => {
    showTypeModal({
      top: '10%',
      data: dataType,
      type: 'soft',
      onChecked: (v) => {
        const a = dataType.find((it) => (it.value === v))
        const b = dataType.filter((it) => (it.value !== v))
        setTypeValue(a.label)
        if (a.value === v) {
          a.isChecked = true
          b.map((i) => (i.isChecked = false))
        }
        closeTypeModal()
      },
      onClick: (e) => {
        if (e.target === e.currentTarget) {
          closeTypeModal()
        }
      }
    })
  }

  /**
   * @description 匹配type类型
   * @returns
   */
  const valueType = () => {
    return dataType.find((it) => (it.label === typeValue))
  }
  /**
   * @description 软绑定接口
   * @export
   * @return {*}
   */
  const getTempBindingList = async (type) => {
    const params = {
      pageNum: list.pageNum,
      size: list.size
    }

    if (type) {
      params.type = type
    } else {
      if (valueType()) {
        handleSetList('type', valueType().value)
        params.type = list.type
      }
    }

    try {
      const res = await serv.getTempBindingList(params)
      if (res) {
        const { data, hasMore } = res
        handleSetList('data', list.data.concat(data || []))
        handleSetList('hasMore', hasMore)
        if (hasMore) {
          handleSetList('pageNum', list.pageNum + 1)
        }
        setLoaded(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 软绑定接口
   * @export
   * @return {*}
   */
  const getExpireList = async () => {
    const params = {
      pageNum: list.pageNum,
      size: list.size
    }
    try {
      const res = await serv.getExpireList(params)
      if (res) {
        const { data, hasMore } = res
        handleSetList('data', list.data.concat(data || []))
        handleSetList('hasMore', hasMore)
        if (hasMore) {
          handleSetList('pageNum', list.pageNum + 1)
        }
        setLoaded(true)
      }
    } catch (err) {
      console.log(err)
    }
  }


  /**
   * @description 须回访接口
   *
   */
  const getDueSoonList = async () => {
    const { pageNum, size } = list
    const params = {
      pageNum,
      size
    }
    if (headType === 1) {
      params.bindingOrd = bindingOrd
    } else {
      params.expiredOrd = expiredOrd
    }
    const res = await serv.getDueSoonList(params)
    try {
      if (res) {
        const { data, hasMore } = res
        handleSetList('data', list.data.concat(data || []))
        handleSetList('hasMore', hasMore)
        if (hasMore) {
          handleSetList('pageNum', list.pageNum + 1)
        }
        setLoaded(true)
      }
    } catch (err) {
      console.log(err)
      Util.showToast(JSON.stringify(err) || '异常错误')
    }
  }

  const showExplain = () => {
    const { history } = props
    history.push(`/explain/${2}`)
  }

  return (
    <div className={styles.container}>
      {
        level && level > 0 ? <div className={styles.tab_wrapper}>
            {NEW_TAB.map((i) => (
              <div
                className={`${styles.tab_item_new} ${
                  readerCurrentTab === i.value ? styles.active : ''
                }`}
                key={i.value}
                onClick={() => changeTabNew(i.value)}>
                {i.label}
                <div className={styles.tab_bar}/>
              </div>
            ))}
          </div> :
          <div className={styles.tab_wrapper}>
            {TAB.map((i) => (
              <div
                className={`${styles.tab_item} ${
                  currentTab === i.value ? styles.active : ''
                }`}
                key={i.value}
                onClick={() => changeTab(i.value)}>
                {i.label}
                <div className={styles.tab_bar}/>
              </div>
            ))}
          </div>
      }
      {
        level && level > 0 ?
          // readerCurrentTab === 2 ?
          //   <div className={`${styles.date_wrapper} ${readerCurrentTab === 2 ? styles.date_wrapper_3 : ''}`}>
          //     <div className={styles.date_user_status} onClick={() => showExplain()}>
          //       <span>用户状态</span>
          //       <img src={`${env.IMG}m-web/user_status.png`} alt=""/>
          //     </div>
          //   </div> :
          null :
          <div className={`${styles.date_wrapper} ${currentTab === 3 ? styles.date_wrapper_3 : ''}`}>
            {currentTab === 1 ? (
              <div className={styles.head}>
                {HEADTYPE.map((i) => (
                  <div
                    className={`${styles.data_picker} ${
                      headType === i.value ? styles.active : ''
                    }`}
                    key={i.value}
                    onClick={() => changeHeadType(i.value)}>
                    {i.label}
                  </div>
                ))}
              </div>
            ) : currentTab === 2 ? (
              <div className={styles.head}>
                <div
                  className={`${styles.data_picker} `}
                  onClick={() => changeSoftHeadType()}>
                  {typeValue}
                  <i className={`iconfont crm-down-arrow ${styles.down_arrow}`}/>
                </div>
              </div>
            ) : null}

            <div className={styles.date_user_status} onClick={() => showExplain()}>
              <span>用户状态</span>
              <img src={`${env.IMG}m-web/user_status.png`} alt=""/>
            </div>
          </div>
      }
      <div className={styles.list}>
        <List
          next={iniData}
          loaded={loaded}
          dataSource={list.data || []}
          hasMore={list.hasMore}>
          {list.data.map((it, idx) =>
            level && level > 0 ?
              <VisitItem data={it} key={it.userId} current={readerCurrentTab} level={level} history={props.history}/> :
              currentTab === 1 ? (
                <VisitItem data={it} key={it.userId}/>
              ) : (
                <SoftBindingItem data={it} key={it.userId}/>
              )
          )}
        </List>
      </div>
    </div>
  )
}
