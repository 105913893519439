import React from 'react'
import styles from '../../index.module.less'
import Card from '../../../../components/Card'

export default function OldEarning({ role, homeData, history, loaded, level }) {

  //跳转----->线上收益
  const showEarningOnDetail = () => {
    history.push(`/earnings-list/${1}`)
  }

  //跳转----->线下收益
  const showEarningOffDetail = () => {
    history.push(`/earnings-list/${2}`)
  }

  return <>
    {
      loaded && (!level || level === 0) && <Card title={`${loaded ? '收益' : ''}`}>
        {loaded ? role === 2 ? (
            <div className={styles.l_f_content}>
              <div className={styles.l_f_item} onClick={showEarningOnDetail}>
                <div className={styles.l_f_count}>
                  {homeData.thisMonthAward || 0}
                </div>
                <div className={styles.l_f_c_name}>线上收益</div>
              </div>
              <div className={styles.l_f_item} onClick={showEarningOffDetail}>
                <div className={styles.l_f_count}>
                  {homeData.thisMonthOffline || 0}
                </div>
                <div className={styles.l_f_c_name}>线下收益</div>
              </div>
            </div>
          ) : role === 3 || role === 4 ? (
            <div className={`${styles.l_f_content}  ${styles.role3}`}>
              <div
                className={`${styles.l_f_item}`}
                onClick={showEarningOnDetail}>
                <div className={styles.l_f_count}>
                  {homeData.thisMonthAward || 0}
                </div>
                <div className={styles.l_f_c_name}>线上收益</div>
              </div>
              <div className={styles.l_f_item} onClick={showEarningOffDetail}>
                <div className={styles.l_f_count}>
                  {homeData.thisMonthOffline || 0}
                </div>
                <div className={styles.l_f_c_name}>线下收益</div>
              </div>
            </div>
          ) : null
          : <div className={styles.default_card1}/>}
      </Card>
    }
  </>


}