import React from 'react'
import styles from '../../index.module.less'
import { bridge } from '../../../../lib/bridge'
import { useStore } from 'reto'
import { CommonStore } from '../../../../store'

export default function NoticeInfo(props) {
  const { runtimeEnv } = useStore(CommonStore)

  // 跳转----->政策通知
  const toNotice = () => {
    if (runtimeEnv === 'app') {
      bridge.appNavigate({ id: 128 })
    }
  }
  return <>
    <div className={styles.notice_wrapper} onClick={() => toNotice()}>
      <div className={styles.notice}>
        <div
          className={styles.info_title}>
          {props.lastNewsData.title && props.lastNewsData.title.length > 18 ? `${props.lastNewsData.title.substring(18, 0)}...` : props.lastNewsData.title}</div>
        <div className={styles.info_q}>查看全部</div>
      </div>
    </div>
  </>
}