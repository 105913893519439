import fetch from '../fetch'
const common = {
	checkPhone(phone = '') {
		return fetch(`/user/crm/user/check_user/${phone}`)
	},

	sendCode(params = {}) {
		return fetch('/thirdparty/ali/sms/code/send', {
			method: 'POST',
			data: { ...params }
		})
	},
	processLogin(params = {}) {
		return fetch('/user/app_login/by_phone', {
			method: 'POST',
			data: { ...params }
		})
	}
}
export default common
