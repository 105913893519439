import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import List from '../../components/List'
import serv from '../../lib/api'
import { Util } from '../../lib/util'
import moment from 'moment'

export default function LeadReaderList(props) {
  const [list, setList] = useState({
    data: [],
    size: 20,
    pageNum: 1,
    hasMore: false
  })
  const [loaded, setLoaded] = useState(false)
  const handleSetList = (key, value) => {
    list[key] = value
    setList({ ...list })
  }

  useEffect(() => {
    document.title = '经营管理'
    handleSetList('data', [])
    handleSetList('pageNum', 1)
    iniData()
    setLoaded(false)
// eslint-disable-next-line
  }, [])

  const iniData = async() => {
    await getList()
  }

  const getList = async () => {
    Util.showLoading('数据加载中...')
    const { size, pageNum } = list
    const role = props.match.params.role
    const params = {
      pageNum,
      size,
      role
    }
    console.log(role)
    try {
      const res = await serv.getReaderPartner(params)
      if (res) {
        const { data, hasMore } = res
        handleSetList('data', list.data.concat(data || []))
        handleSetList('hasMore', hasMore)
        if (hasMore) {
          handleSetList('pageNum', list.pageNum + 1)
        }
        setLoaded(true)
        Util.hideLoading()
      }
    } catch (e) {
      console.log(e)
    }

  }


  return (
    <div className={styles.container}>
      <List
        next={iniData}
        loaded={loaded}
        dataSource={list.data || []}
        hasMore={list.hasMore}>
        {
          list.data.map((it, index) => (
            <div className={styles.item_wrapper} key={index}>
              <div className={styles.item}>
                <div className={styles.left}>
                  <img src={it.avatar} alt={``} className={styles.avatar}/>
                  <div className={styles.name}>{it.nickname}</div>
                </div>
                <div className={styles.right}>{moment(it.readerTime).format('YYYY-MM')}成为领读者</div>
              </div>
              <div className={styles.line}/>
            </div>
          ))
        }
      </List>
    </div>
  )
}
