import React, { useState, useEffect } from 'react'
import styles from './index.module.less'
import ListItem from './components/listItem'
import serv from '../../lib/api'
import { Util } from '../../lib/util'
import List from '../../components/List'

const authVo = JSON.parse(Util.getLocalStorage('user_info') || '{}')
const id = authVo && authVo.id

export default function PerformanceDetail(props) {
  const [list, setList] = useState({
    data: [],
    size: 20,
    pageNum: 1,
    hasMore: false
  })
  const [loaded, setLoaded] = useState(false)
  const [showSource, setShowSource] = useState(false)

  const handleSetList = (key, value) => {
    list[key] = value
    setList({ ...list })
  }

  useEffect(() => {
    setLoaded(false)
    iniData()
    // eslint-disable-next-line
  }, [])

  const iniData = async () => {
    await userAchieveList()
  }

  /**
   * @description 获取明细列表接口
   * @export
   * @return {*}
   */
  const userAchieveList = async () => {
    Util.showLoading('数据加载中...')
    const userId = props.match.params.id
    const month = props.match.params.month
    const params = {
      size: list.size,
      pageNum: list.pageNum,
      month,
      readerId: userId
    }
    const res = await serv.getAchievementDetail(params)
    try {
      if (res) {
        const { data, hasMore } = res
        const { itemList, realName, showSource: isShow } = data || {}
        document.title = realName
        setShowSource(isShow)
        handleSetList('hasMore', hasMore)
        handleSetList('data', list.data.concat(itemList || []))
        if (hasMore) {
          handleSetList('pageNum', list.pageNum + 1)
        }
        setLoaded(true)
      }
    } catch (err) {
      console.log(err)
      Util.showToast(JSON.stringify(err) || '异常错误')
    } finally {
      Util.hideLoading()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <div className={` ${id === props.match.params.id * 1 ? styles.head_c_1_t : styles.head_c_1}`}>明细</div>
        {showSource ? <div className={styles.head_c_2}>来源</div> : <div className={styles.head_c_2}/>}
        <div className={` ${id === props.match.params.id * 1 ? styles.head_c_3_t : styles.head_c_3}`}>业绩</div>
        <div className={` ${id === props.match.params.id * 1 ? styles.head_c_4_t : styles.head_c_4}`}>绩效</div>
      </div>
      <div className={styles.list}>
        <List
          next={userAchieveList}
          loaded={loaded}
          dataSource={list.data}
          hasMore={list.hasMore}>
          {list.data.map((i, idx) => (
            <ListItem data={i} key={idx} id={id} userId={props.match.params.id * 1} showSource={showSource}/>
          ))}
        </List>
      </div>
    </div>
  )
}
