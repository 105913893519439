import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import { env } from '../../../../lib/conf'
import moment from 'moment'

export default function MemberItem(props) {
  const { level, current, history } = props
  let {  avatar, nickname, worthy, sub, time, userId, newTagList, subscribeStatusLabel, expireTime, level: l } = props.data || {}
  const [newNickname, setNewNickname] = useState('')
  /**
   * @description 跳转原生用户详情页面
   */
  const toUserDetail = () => {
    history.push(`/member-user-info/${userId}`)
  }

  useEffect(() => {
    if (nickname && nickname.length > 15 && (current === 1 || current === 2)) {
      const nick = nickname.substring(0, 15)
      setNewNickname(`${nick}...`)
    } else {
      setNewNickname(nickname)
    }
  }, []) //eslint-disable-line


  return (
    <>
      {props.data ? (
        <div className={styles.item_wrapper} onClick={toUserDetail}>
          {
            level && level > 0 ? current === 1 || current === 2 ?
              <div className={styles.item}>
                <img className={styles.img1}
                     src={avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}
                     alt=""/>
                <div className={styles.new_info}>
                  <div className={styles.new_name}>{newNickname || ''}</div>
                  <div className={styles.tag_wrapper}>
                    {
                      newTagList && newTagList.length > 0 && newTagList.map((it, index) => (
                        <div className={styles.new_tab} style={{ background: it.bgColor, color: it.color }}
                             key={index}>{it.label}</div>
                      ))
                    }
                  </div>
                  <div
                    className={styles.date}>
                    {
                      expireTime ? `${moment(expireTime).format('YYYY-MM-DD')}到期${subscribeStatusLabel ? `(${subscribeStatusLabel})` : ''}` : ''
                    }
                  </div>
                </div>
                <div className={styles.new_right_wrapper}>
                  {
                    time ? <>
                      <div className={styles.member_time}>{moment(time).format('MM-DD')}</div>
                      <div>成为会员</div>
                    </> : '未激活'
                  }
                </div>
              </div>
              :
              <div className={`${styles.course_item}`}>
                <div className={styles.left_wrapper}>
                  <img className={styles.img1}
                       src={avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}
                       alt=""/>
                  <div className={styles.info}>
                    <div className={styles.name}>{newNickname || ''}</div>
                    <div className={styles.tag_wrapper}>
                      {
                        l === -1 && <div className={styles.invitation_tab}>实习领读者</div>
                      }
                    </div>
                    {/*{*/}
                    {/*  tagList &&*/}
                    {/*  <div className={styles.bottom_info}>*/}
                    {/*    <span>已加我</span>*/}
                    {/*    <span className={styles.shu}>|</span>*/}
                    {/*    <span>已加群</span>*/}
                    {/*  </div>*/}
                    {/*}*/}
                  </div>
                </div>
                <div>
                  {current === 2 &&
                  <div className={styles.time}>{time ? `${moment(time).format('YYYY-MM-DD')}年卡到期` : '未激活'}</div>}
                  {current === 3 &&
                  <div className={styles.time}>{time ? `${moment(time).format('YYYY-MM-DD')}购买` : ''}</div>}
                </div>
              </div>
              :
              <div className={styles.item_old}>
                <img className={styles.img1}
                     src={avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}
                     alt=""/>
                <div className={styles.name}>{newNickname || ''}</div>
                {worthy && <img className={styles.type} src={`${env.IMG}m-web/member_type1.png`} alt=""/>}
                {sub && <img className={styles.type1} src={`${env.IMG}m-web/member_type2.png`} alt=""/>}
              </div>
          }
          <div className={styles.line}/>
        </div>
      ) : null}
    </>
  )
}
