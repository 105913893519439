import React from 'react'
import styles from '../../index.module.less'
import { env } from '../../../../lib/conf'
import { bridge } from '../../../../lib/bridge'
import { Util } from '../../../../lib/util'
import { useStore } from 'reto'
import { CommonStore } from '../../../../store'
import Card from '../../../../components/Card'

export default function LearnActivity({loaded}) {
  const { runtimeEnv } = useStore(CommonStore)

  //跳转----->get详情
  const toGetDetail = () => {
    if (runtimeEnv === 'app') {
      bridge.appNavigate({ id: 112 })
    } else {
      Util.showToast('请前往App查看')
    }
  }

  return <>
    <Card title={`${loaded ? '学习活跃率' : ''}`}>
      {loaded ? <div className={styles.l_learning_activity_content} onClick={() => toGetDetail()}>
        <div className={`${styles.l_le_ac_item} ${styles.nth2}`}>
          <img
            src={`${env.IMG}m-web/dashboard_study_icon_2.png`}
            alt=""/>
          <span>查看全部GET订单</span>
        </div>
      </div>: <div className={styles.default_get}/>
      }
    </Card>

  </>


}