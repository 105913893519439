import React from 'react'
import styles from '../GroupItem/index.module.less'

export default function GroupItem(props) {
	let { groupName, groupId, totalGroupCount, memberCount, cheersCount } = props.data || {}

	const toDetail = () => {
		props.history.push(`/group-detail/${groupId}`)
	}

	return (
		<>
			{props.data ? (
				<div className={styles.item_wrapper} onClick={toDetail}>
					<div className={styles.item}>
						<div>
							<div className={styles.title}>{groupName}</div>
							<div className={styles.desc}>总人数：{totalGroupCount || '-'}&nbsp;&nbsp;|&nbsp;&nbsp;群捧：{cheersCount || '-'}&nbsp;&nbsp;|&nbsp;&nbsp;会员：{memberCount || '-'}</div>
						</div>
						<div>
							<span className={styles.text}>详情</span>
							<i className={`iconfont crm-arrow-right ${styles.arrow_icon}`}/>
						</div>
					</div>
					<div className={styles.line}/>
				</div>
			) : null}
		</>
	)
}
