import React from 'react'
import styles from '../../index.module.less'
import { env } from '../../../../lib/conf'
import { Util } from '../../../../lib/util'
import { useStore } from 'reto'
import { CommonStore } from '../../../../store'
import copy from 'copy-to-clipboard'

export default function UserInfo(
  {
    loaded,
    user,
    level,
    role,
    history
  }) {

  const { showModal, closeModal } = useStore(CommonStore)

  //跳转 ------> 领读者进度
  const toLeadReaderProgress = () => {
    history.push(`/lead-reader-progress/${level}`)
  }

  const showInvitationModal = () => {
    showModal({
      title: '邀请码',
      content: <div className={styles.invitation_wrapper}>
        <div className={styles.code}>{user.readerCode}</div>
        <div className={styles.tip}>说明：无邀请关系的会员，注册时填入，与你产生绑定。你的手机号同样可以作为邀请码</div>
      </div>,
      gravity: 1,
      okBtnText: '复制',
      showModalCloseIcon: false,
      onCancel: () => {
        closeModal()
      },
      onConfirm: () => {
        copy(user.readerCode)
        Util.showToast('复制成功')
        closeModal()
      }
    })
  }


  return <>
    {loaded ?
      <div className={styles.user_info}>
        <img
          src={(user && user.avatar) || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}
          alt="avatar"
          className={styles.avatar}
        />
        {
          level && level > 0 ? <div className={styles.info}>
              <div className={styles.user_user_wrapper}>
                <div className={styles.user_wrapper}>
                  <div className={styles.nickname}>
                    <div>{(user && user.realName) || (user && user.nickname)}</div>
                    <img src={`${env.IMG}m-web/lead_reader_${Util.getLevel(level)}.png`} alt={``}
                         className={styles.read_role_icon}/>
                  </div>
                  {/*----新增政策用户信息区分----*/}
                  <div className={styles.user_date_wrapper}>
                    <div
                      className={styles.user_date}>{
                      Util.getLevel(level) === 'normal' ?
                        <div onClick={toLeadReaderProgress} className={styles.user_level_info}>成为资深领读者 <i
                          className={styles.right_arrow}/></div> :
                        Util.getLevel(level) === 'senior' ?
                          <div onClick={toLeadReaderProgress} className={styles.user_level_info}>成为领读者导师 <i
                            className={styles.right_arrow}/></div> : null
                    }
                    </div>
                  </div>
                  {/*----*/}
                </div>
                <div className={styles.days}>
                  已加入{user.partnerDays}天
                </div>
              </div>
              <div className={styles.code} onClick={showInvitationModal}>邀请码</div>
            </div> :

            <div className={styles.info}>
              {role === 2 ? (
                <div className={styles.user_user_wrapper}>
                  <div className={styles.user_wrapper}>
                    <div className={styles.nickname}>
                      <div>{(user && user.realName) || (user && user.nickname)}</div>
                      <img
                        src={`${env.IMG}role_tag_icon_2.png`}
                        alt=""
                        className={styles.role_icon}
                      />
                    </div>
                  </div>
                  <div className={styles.days}>
                    已加入{user.partnerDays}天
                  </div>
                </div>
              ) : role === 3 ? (
                <div className={styles.user_user_wrapper}>
                  <div className={styles.user_wrapper}>
                    <div className={styles.nickname}>
                      {(user && user.realName) || (user && user.nickname)}
                    </div>
                    <img
                      alt=""
                      src={`${env.IMG}role_tag_icon_3.png`}
                      className={styles.role_icon1}
                    />
                  </div>
                  <div className={styles.days}>
                    已加入{user.partnerDays}天
                  </div>
                </div>
              ) : role === 4 ? (
                <div className={styles.user_user_wrapper}>
                  <div className={styles.user_wrapper}>
                    <div className={styles.nickname}>
                      {(user && user.realName) || (user && user.nickname)}
                    </div>
                    <img
                      alt=""
                      src={`${env.IMG}role_tag_icon_4.png`}
                      className={styles.role_icon1}
                    />
                  </div>
                  <div className={styles.days}>
                    已加入{user.partnerDays}天
                  </div>
                </div>
              ) : null}
            </div>
        }
      </div> :
      <div className={styles.default_info}/>}

  </>


}