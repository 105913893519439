import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import moment from 'moment'

const userType = [
  {
    value: 0,
    label: '新注册用户'
  },
  {
    value: 1,
    label: '新注册用户'
  },
  {
    value: 2,
    label: '订阅卡会员'
  },
  {
    value: 3,
    label: '订阅卡会员'
  },
  {
    value: 4,
    label: '订阅卡会员'
  },
  {
    value: 5,
    label: '引流付费用户'
  }
]
export default function VisitItem(props) {
  const { data, key, current, level, history } = props
  const [newNickname, setNewNickname] = useState('')


  const toMemberDetail = () => {
    history.push(`/member-user-info/${data.userId}`)

  }

  useEffect(() => {
    if (data && data.nickname && data.nickname.length > 10) {
      const nick = data.nickname.substring(0, 10)
      setNewNickname(`${nick}...`)
    } else {
      setNewNickname(data.nickname)
    }
  }, [])    // eslint-disable-line


  return (
    <>
      {data ? (
        current === 2 && level && level > 0 ?
          <div className={styles.item} key={key} onClick={() => toMemberDetail()}>
            <div className={styles.item_title}>
              <div className={styles.title_left}>
                <img src={data.avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}
                     className={styles.box} alt=""/>
                <div className={styles.new_info_wrapper}>
                  <div className={styles.title_user_new}>
                    <div className={styles.new_user}>{newNickname || '--'}</div>
                    {
                      data.tagList && data.tagList.map((it) => (
                        <div className={styles.new_tab}>{it}</div>

                      ))
                    }
                  </div>
                  <div className={styles.new_bag_info}>已使用书袋数 {data.bagOrderUsedCount || 0}
                    <div className={styles.shu}/>
                    已订阅月数 {data.totalSubCount || 0}</div>
                </div>
              </div>
            </div>
            <div className={styles.new_content}>
              <div>{data.subscribeStatusLabel || ''}</div>
              <div>剩余服务天数：{data.expireDays || 0}天</div>
            </div>
          </div> :
          current === 1 && level && level > 0 ?

            <div className={styles.item} key={key} onClick={() => toMemberDetail()}>
              <div className={styles.item_title}>
                <div className={styles.title_left}>
                  <img src={data.avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}
                       className={styles.box} alt=""/>

                  <div className={styles.title_user}>
                    <div className={styles.user}>{data.nickname || '--'}</div>
                  </div>
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.content_item}>
                  <div className={styles.c_item_top}>{moment(data.paidTime).format('YYYY-MM-DD') || '--'}</div>
                  <div className={styles.c_item_bottom}>注册时间</div>
                </div>
                <div className={styles.content_item}>
                  <div className={styles.c_item_top}>
                    {data.expireDays || '--'}天
                  </div>
                  <div className={styles.c_item_bottom}>已注册天数</div>
                </div>
              </div>
            </div> :
            current === 5 && level && level > 0 ?
              <div className={styles.item} key={key} onClick={() => toMemberDetail()}>
                <div className={styles.item_title}>
                  <div className={styles.title_left}>
                    <img src={data.avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}
                         className={styles.box} alt=""/>

                    <div className={styles.title_user}>
                      <div className={styles.user}>{data.nickname || '--'}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.content_item}>
                    <div className={styles.c_item_top}>{moment(data.paidTime).format('YYYY-MM-DD') || '--'}</div>
                    {data.type === 2 || data.type === 3 || data.type === 4 ? (
                      <div className={styles.c_item_bottom}>订阅卡服务剩余</div>) : data.type === 5 ? (
                      <div className={styles.c_item_bottom}>最后一次付费</div>) : null}

                  </div>
                  <div className={styles.content_item}>
                    <div className={styles.c_item_top}>
                      {data.expireDays || '--'}天
                    </div>
                    <div className={styles.c_item_bottom}>解除绑定倒计时</div>
                  </div>
                </div>
              </div> : <div className={styles.item} key={key} onClick={() => toMemberDetail()}>
                <div className={styles.item_title}>
                  <div className={styles.title_left}>
                    <img src={data.avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}
                         className={styles.box} alt=""/>

                    <div className={styles.title_user}>
                      <div className={styles.user}>{data.nickname || '--'}</div>
                      <div className={styles.type_wrapper}>
                        {data.type === 2 || data.type === 3 || data.type === 4 ? (
                          <div className={styles.type}>
                            {(userType.find((it) => it.value === data.type) || {}).label ||
                            '--'}
                          </div>
                        ) : (
                          <div className={styles.type1}>
                            {(userType.find((it) => it.value === data.type) || {}).label ||
                            '--'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/*<img src={`${env.IMG}m-web/right_arrow.png`} alt=""/>*/}
                </div>
                <div className={styles.content}>
                  <div className={styles.content_item}>
                    <div className={styles.c_item_top}>{data.paidTime || '--'}</div>
                    {data.type === 2 || data.type === 3 || data.type === 4 ? (
                      <div className={styles.c_item_bottom}>订阅卡服务剩余</div>) : data.type === 5 ? (
                      <div className={styles.c_item_bottom}>最后一次付费</div>) : null}

                  </div>
                  <div className={styles.content_item}>
                    <div className={styles.c_item_top}>
                      {data.expireTime || '--'}天
                    </div>
                    <div className={styles.c_item_bottom}>解除绑定倒计时</div>
                  </div>
                </div>
              </div>
      ) : null}
    </>
  )
}
