import fetch from '../fetch'

const datacenter = {
	/**
	 * @description 数据埋点
	 * @param params
	 * @returns {*}
	 */
	dataTrackingStorage(params) {
		return fetch('/datacenter/base/data_tracking_storage', {
			method: 'POST',
			data: { ...params }
		})
	}
}

export default datacenter
