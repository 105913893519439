import React from 'react'
import { env } from '../../../lib/conf'
import styles from './index.module.less'
import { bridge } from '../../../lib/bridge'
import { Util } from '../../../lib/util'
import { useStore } from 'reto'
import { CommonStore } from '../../../store'

export default function InvitationMember() {
  const { showModal, closeModal, userInfo } = useStore(CommonStore)
  const { id, role } = userInfo || {}

  document.title = '邀请成员'

  /**
   * @description 申请在线转卡弹框
   */
  const showApplyOnlineModal = () => {
    showModal({
      title: '申请在线转卡',
      content: `请在企业微信中联系好奇说业务顾问`,
      gravity: 1,
      okBtnText: '知道了',
      onClose: () => {
        closeModal()
      },
      onConfirm: () => {
        closeModal()
      }
    })
  }

  /**
   * @description 申请在先支付弹框带转发
   */
  const showPayModal = () => {
    if (!id) {
      Util.showToast('错误的分享信息，请重新登录后操作')
      return
    }
    showModal({
      title: '申请在线支付',
      content: `点击知道了按钮后，发送注册链接给你的好友，你的好友购买并签署协议后将成为好奇说阅读推广人`,
      gravity: 1,
      okBtnText: '知道了',
      onClose: () => {
        closeModal()
      },
      onConfirm: () => {
        closeModal()
        bridge.appShareWechat({
          title: `邀请你成为好奇说推广人`,
          thumb: `${env.IMG}m-web/invite_promoter.png`,
          path: `subPkg/extend/pages/upgrade-20-cards/index?shareId=${id}`
        })
      }
    })
  }

  /**
   * @description 点击邀请成为推广人
   */
  const handleSharePromoter = () => {
    if (role && role === 2) {
      if (!id) {
        Util.showToast('错误的分享信息，请重新登录后操作')
        return
      }
      bridge.appShareWechat({
        title: `邀请你成为好奇说推广人`,
        thumb: `${env.IMG}m-web/invite_promoter.png`,
        path: `subPkg/extend/pages/upgrade-20-cards/index?shareId=${id}`
      })
    }
    if (role && (role === 3 || role === 4)) {
      showModal({
        title: '您可以选择以下两种方式',
        content: `1.直接邀请好友在线支付成为推广人\n2.向业务顾问申请转发卡包中的余卡`,
        showCancelBtn: true,
        gravity: 1,
        okBtnText: '在线支付',
        cancelBtnText: '申请转卡',
        onClose: () => {
          closeModal()
        },
        onCancel: () => {
          closeModal()
          showApplyOnlineModal()
        },
        onConfirm: () => {
          closeModal()
          showPayModal()
        }
      })
    }
  }

  const handleShareBranch = () => {
    if (!id) {
      Util.showToast('错误的分享信息，请重新登录后操作')
      return
    }
    showModal({
      title: '如何操作？',
      content: `点击知道了按钮后，发送注册链接给你的好友，你的好友购买并签署协议后将成立好奇说阅读分会`,
      gravity: 1,
      okBtnText: ' 知道了',
      onClose: () => {
        closeModal()
      },
      onConfirm: () => {
        closeModal()
        bridge.appShareWechat({
          title: `邀请你成为好奇说阅读分会`,
          thumb: `${env.IMG}m-web/invite_branch.png`,
          path: `subPkg/extend/pages/card-store-event/index?shareId=${id}`
        })
      }
    })
  }

  return userInfo ? <>
        <div className={styles.content}>
          <img
            src={`${env.IMG}m-web/inivite_pro.png`}
            alt=""
            onClick={handleSharePromoter}
          />
          {role === 3 || role === 4 ? (
            <img
              src={`${env.IMG}m-web/inivite_bran.png`}
              alt=""
              onClick={handleShareBranch}
            />
          ) : null}
          {/*<div>*/}
          {/*  New<br/>*/}
          {/*  当前UserId: {id}<br/>*/}
          {/*  当前邀请推广人链接：subPkg/extend/pages/upgrade-20-cards/index?shareId={id}<br/>*/}
          {/*  当前邀请分会长链接：subPkg/extend/pages/card-store-event/index?shareId={id}*/}
          {/*</div>*/}
        </div>
      </> : null
}
