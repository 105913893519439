import React, { useState, useEffect } from 'react'
import styles from './index.module.less'
import serv from '../../lib/api'
import List from '../../components/List'
import { Util } from '../../lib/util'
import GroupItem from './components/GroupItem'

export default function GroupManagement(props) {
  const [list, setList] = useState({
    data: [],
    pageSize: 20,
    pageNum: 1,
    loading: false,
    hasMore: false
  })
  const [loaded, setLoaded] = useState(false)


  const handleSetList = (key, value) => {
    list[key] = value
    setList({ ...list })
  }

  useEffect(() => {
    document.title = '会员管理'
    handleSetList('data', [])
    handleSetList('pageNum', 1)
    initData()
    setLoaded(false)
    // eslint-disable-next-line
  }, [])


  const initData = () => {
    getList()
  }


  /**
   * @description 新政策会员列表
   * @returns {Promise<void>}
   */
  const getList = async () => {
    Util.showLoading('数据加载中...')
    const params = {
      pageNum: list.pageNum,
      pageSize: list.pageSize,
    }
    try {
      const res = await serv.getGroupList(params)
      if (res) {
        const { data, hasMore } = res || []
        handleSetList('data', list.data.concat(data || []))
        handleSetList('hasMore', hasMore)
        if (hasMore) {
          handleSetList('pageNum', list.pageNum + 1)
        }
      }
      setLoaded(true)
      Util.hideLoading()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        <List
          loaded={loaded}
          dataSource={list.data}
          hasMore={list.hasMore}
          next={getList}>
          {list.data.map((i, idx) => (
            <GroupItem data={i} key={idx} history={props.history}/>
          ))}
        </List>
      </div>

    </div>
  )
}
