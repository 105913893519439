import fetch from '../fetch'

const user = {
	/**
	 * 获取用户信息
	 */
	getUserInfo() {
		return fetch('/user/member_page_info/get_user_info', {
			v2: true
		})
	},

	/**
	 * 获取首页用户信息
	 */
	getDashboardUserInfo() {
		return fetch('/user/crm/user/get_user_info', {
			v2: true
		})
	},

	/**
	 * @desc 获取新人专享主页信息
	 * @returns
	 */
	getNewbieInfo() {
		return fetch('/user/new_user_activity/get_new_user_activity_homepage')
	},

	/**
	 * @desc 提交任务完成
	 * @param {*} [params={}]
	 * @returns
	 */
	commitNewUserActivity(params = {}) {
		return fetch('/user/new_user_activity/commit_new_user_activity', {
			params: { ...params }
		})
	},

	/**
	 * @desc 检查绑定关系
	 */
	checkRefer() {
		return fetch('/user/p/my_info/check_refer', {
			method: 'POST'
		})
	},

	/**
	 * @desc 获取宝宝信息
	 */
	getBabyInfo() {
		return fetch('/user/p/my_info/getBabyInfo')
	},

	/**
	 * @desc 获取育儿社区体验相关信息
	 * @param {number} [babyAge=0]
	 * @returns
	 */
	getCommunityInfo(babyAge = 0) {
		return fetch(`/user/experience/plan/community/${babyAge}`)
	},

	/**
	 * @desc 获取app版本号
	 * @returns
	 */
	getAppVersion(platform = '') {
		return fetch(`/user/home/app_an_version`, {
			headers: { platform }
		})
	},

	/**
	 * @description 根据手机号查询用户信息
	 * @param userPhone number
	 * @returns {*}
	 */
	getUserinfoByPhone(userPhone) {
		return fetch('/user/user_homepage/get_userinfo_by_phone', {
			params: { userPhone }
		})
	},

	/**
	 * @description 获取活动绑定关系
	 * @returns {*}
	 */
	getHomeRelation() {
		return fetch('/user/user_homepage/get_home_relation')
	},

	/**
	 * @description 提交夫妻共学绑定关系
	 * @param params
	 * @returns {*}
	 */
	commitHomeRelation(params = {}) {
		return fetch('/user/user_homepage/commit_home_relation', {
			params: { ...params }
		})
	},

	/**
	 * @description 大转盘信息
	 * @returns {*}
	 */
	getLotteryInfo() {
		return fetch('/user/lottery_active/get_lottery_info')
	},

	/**
	 * @description 大转盘抽奖
	 * @returns {*}
	 */
	joinLotteryActive() {
		return fetch('/user/lottery_active/join_lottery_active')
	},

	/**
	 * @description 使用积分兑换抽奖次数
	 * @returns {*}
	 */
	exchangeLotteryActiveCount() {
		return fetch('/user/lottery_active/exchange_lottery_active_count')
	},

	/**
	 * @description 获取地址列表
	 * @returns {*}
	 */
	getUserAddressList() {
		return fetch('/user/p/my_info/getUserAddressList')
	},
	/**
	 * @description 线下转卡预估收益明细列表
	 * @param {*} param
	 */
	getTransferAwardRecord(param = {}) {
		return fetch('/user/crm/transfer/card/get_transfer_award_list', {
			params: { ...param }
		})
	},

	/**
	 * @description 用户消费额进度
	 * @returns
	 */
	getUserAchieveProcess() {
		return fetch('/user/crm/user/achieve_process', {
			v2: true
		})
	},
	/**
	 * @description 须回访列表
	 * @param {*} params
	 * @returns
	 */
	getDueSoonList(params = {}) {
		return fetch('/user/crm/user/due_soon_list', {
			params: { ...params }
		})
	},
	/**
	 * @description 软绑定
	 * @param {*} params
	 * @returns
	 */
	getTempBindingList(params = {}) {
		return fetch('/user/crm/user/temp_binding_list', {
			params: { ...params },
			v2: true
		})
	},
	/**
	 * @desc 获取所有异常用户
	 * @param {*} params
	 */
	getAllAbnormalUser(params = {}) {
		return fetch('/user/crm/user_dynamic/all_abnormal_user', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取最新通知
	 * @returns {*}
	 */
	getLastNewsOfNotification() {
		return fetch(`/user/official/notification/get_last_news`, {
			v2: true
		})
	},

	/**
	 * @description 获取升级身份
	 * @returns {*}
	 */
	getUserUpdateInfo() {
		return fetch(`/user/update_role/get_user_update_info`, {
			v2: true
		})
	},

	/**
	 * @description 获取生日弹框相关状态
	 */
	getBirthdayInfo() {
		return fetch(`/user/p/my_info/check_birthday_condition`, {
			v2: true
		})
	},

	/**
	 * @description 更改默认进去经营页
	 * @param params
	 * @returns {*}
	 */
	changeDefaultPage(params = {}) {
		return fetch(`/user/member_page_info/set_default_manage_page`, {
			params: { ...params },
			v2: true
		})
	},

	/**
	 * @description 获取升级信息今日弹框
	 * @returns {Promise}
	 */
	getHaveTodayAlert() {
		return fetch(`/user/update_role/have_today_alert`, {
			v2: true
		})
	},

	/**
	 * @description 屏蔽任务消息
	 * @returns {Promise}
	 */
	blockMissionAlert() {
		return fetch(`/user/update_role/block_mission_alert`, {
			v2: true
		})
	},
	/**
	 * @description 屏蔽任务消息
	 * @returns {Promise}
	 */
	getContinueSubList(params = {}) {
		return fetch(`/user/crm/user/un_continue_sub`, {
			params: { ...params },
			v2: true
		})
	},

	/**
	 * @description 新政策会员列表
	 * @param params
	 * @returns {*}
	 */
	getServiceMemberList(params = {}) {
		return fetch(`/user/crm/user/service_member_list`, {
			params: { ...params },
			v2: true
		})
	},

	/**
	 * @description 获取新会员数
	 * @returns {*}
	 */
	getCommonCount() {
		return fetch(`/user/crm/user/get_common_count`, {
			v2: true
		})
	},

	/**
	 * @description 获取新政策需回访数量
	 * @returns {*}
	 */
	getReturnVisitCount() {
		return fetch(`/user/crm/user/return_visit_cnt`, {
			v2: true
		})
	},

	/**
	 * @description 获取即将到期列表
	 * @param params
	 * @returns {*}
	 */
	getExpireList(params = {}) {
		return fetch(`/user/crm/user/to_be_expire_list`, {
			params: { ...params },
			v2: true
		})
	},

	/**
	 * @description 素材号-首页列表
	 * @param params
	 * @returns {*}
	 */
	homepageLibraryContent(params) {
		return fetch('/user/crm/library/homepage_library_content', {
			params: { ...params },
			v2: true
		})
	},

	/**
	 * @description 素材号-获取tab列表
	 * @returns {*}
	 */
	libraryTagList() {
		return fetch('/user/crm/library/library_tag_list', {
			v2: true
		})
	},

	/**
	 * @description 素材库-根据tagId获取列表
	 * @param params
	 * @returns {*}
	 */
	libraryContentByTag(params) {
		return fetch('/user/crm/library/library_content_by_tag', {
			params: { ...params },
			v2: true
		})
	},

	/**
	 * @description 实习领读者申请列表
	 * @returns {*}
	 */
	internshipApplyList(params = {}) {
		return fetch('/user/app/internship/internship_apply_list', {
			params: { ...params },
			v2: true
		})
	},

	/**
	 * @description 同意实习领读者申请
	 * @param applyId
	 * @returns {*}
	 */
	agreeInternshipApply(applyId = '') {
		return fetch('/user/app/internship/agree_internship_apply', {
			params: { applyId },
			v2: true
		})
	},

	/**
	 * @description 实习领读者申请数量
	 * @returns {*}
	 */
	getAgreeCnt() {
		return fetch('/user/app/internship/get_un_agree_cnt', {
			v2: true
		})
	},

	/**
	 * @description 关键字查询素材
	 * @param params
	 * @returns {*}
	 */
	getLibraryContentByKeyword(params) {
		return fetch('/user/crm/library/get_library_content_by_keyword', {
			v2: true,
			method: 'POST',
			data: { ...params }
		})
	},
	/**
	 * @description 根据1级标签查2级标签
	 * @param tagId
	 * @returns {*}
	 */
	getSecondTagList(tagId) {
		return fetch('/user/crm/library/get_second_tag_list', {
			v2: true,
			params: { tagId }
		})
	},

	/**
	 * @description 根据2级标签查询素材列表
	 * @param params
	 * @returns {*}
	 */
	getLibraryContentBySecondTag(params) {
		return fetch('/user/crm/library/get_library_content_by_second_tag', {
			v2: true,
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 获取会员信息
	 * @returns {*}
	 * @param userId
	 */
	getMemberDetailInfo(userId) {
		return fetch('/user/member_page_info/member_detail_info', {
			v2: true,
			params: { userId }
		})
	},

	/**
	 * @description 创建备注
	 * @returns {*}
	 * @param params
	 */
	createVisitRecord(params = {}) {
		return fetch('/user/crm/user_visit/create_visit_record', {
			method: 'POST',
			v2: true,
			data: { ...params }
		})
	},

	/**
	 * @description 备注详情
	 * @returns {*}
	 * @param id
	 */
	getRecordDetail(id) {
		return fetch('/user/crm/user_visit/record_detail', {
			v2: true,
			params: { id }
		})
	},

	/**
	 * @description 更新备注
	 * @returns {*}
	 * @param params
	 */
	updateVisitRecord(params = {}) {
		return fetch('/user/crm/user_visit/update_visit_record', {
			method: 'POST',
			v2: true,
			data: { ...params }
		})
	},

	/**
	 * @description 搜索会员
	 * @returns {*}
	 */
	searchMember(params = {}) {
		return fetch(`/user/crm/user/search_member`, {
			method: 'POST',
			v2: true,
			data: { ...params }
		})
	},

	/**
	 * @description 是否允许上传素材
	 * @returns
	 */
	allowUploadLibrary() {
		return fetch('/user/crm/library/allow_upload_library', {
			v2: true
		})
	}
}

export default user
