import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import ActionSheet from '../../components/ActionSheet'
import { useStore } from 'reto'
import { CommonStore } from '../../store'
import serv from '../../lib/api'
import { Util } from '../../lib/util'
import { bridge } from '../../lib/bridge'
import html2canvas from 'html2canvas'

const InviteLeadReader = (props) => {
  const { userInfo, runtimeEnv } = useStore(CommonStore)
	const [detail, setDetail] = useState(null)
	const [qrcode, setQrcode] = useState('')
	const [shareVisible, setShareVisible] = useState(false)

	const getDetail = async () => {
		try {
			const res = await serv.getReaderIntroductionInfo(3)
			if (res) {
				const { data } = res
				setDetail(data)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const getQrcode = async () => {
    const { id: userId } = userInfo || {}
		const params = {
			path: 'subPkg/extend/pages/reader-lead/index',
			scene: `m;${userId || ''}`,
			type: 24
		}
		try {
			const res = await serv.getQrCode(params)
			if (res) {
				const { cdnUrl } = res.data || {}
				setQrcode(cdnUrl)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const initData = () => {
		getDetail()
		getQrcode()
	}

	
	useEffect(() => {
		initData() // eslint-disable-next-line
	}, [])

	const showShareVisible = () => {
		setShareVisible(true)
	}

	const closeShareVisible = () => {
		setShareVisible(false)
	}

	const shareWechat = () => {
    const { id: userId } = userInfo || {}
		bridge.appShareWechat({
			title: '邀请你成为好奇说领读者',
			thumb: 'https://cdn.kangarooread.com/resource/ic_read_leader_share.png',
			path: `subPkg/extend/pages/reader-lead/index?shareId=${userId}`
		})
		closeShareVisible()
	}

	const shareImg = () => {
		const node = document.getElementById('poster-wrapper')
		closeShareVisible()
		Util.showLoading('绘制中...')
		html2canvas(node, {
			allowTaint: true,
			useCORS: true,
			scrollY: 0
		})
			.then((canvas) => {
				if (canvas) {
					const src = canvas.toDataURL('image/png')
					bridge.appShareBase64(src)
				}
				Util.hideLoading()
			})
			.catch((err) => {
				console.log(err)
				Util.hideLoading()
			})
	}

	return (
		<div className={styles.wrapper}>
			{detail &&
				detail.rightsImgList.map((img, idx) => (
					<img className={styles.rights_img} src={img} key={idx} alt='' />
				))}
			<div className={styles.img_desc}>
				点击「去邀请」，跳转到微信分享给好友
			</div>
			<div className={styles.btn} onClick={showShareVisible}>
				去邀请
			</div>

			{shareVisible && (runtimeEnv === 'app' || runtimeEnv === 'h5') && (
				<div className={styles.share_img_wrapper}>
					<div
						className={styles.share_img}
						style={{ transform: 'scale(.6)', marginTop: -100 }}>
						<img
							src='https://cdn.kangarooread.com/resource/bg_share_image.jpg'
							className={styles.img}
							alt=''
						/>
						<div className={styles.info_wrapper}>
							<div className={styles.text_wrapper}>
								<div className={styles.text_1}>
									{userInfo && userInfo.nickname}
								</div>
								<div className={styles.text_2}>邀请你成为领读者</div>
							</div>
							<img src={qrcode} className={styles.qr_code} alt='' />
						</div>
					</div>
				</div>
			)}

			<div
				style={{
					position: 'fixed',
					left: -1000,
					top: -99999
				}}>
				<div className={styles.share_img} id='poster-wrapper'>
					<img
						src={`https://cdn.kangarooread.com/resource/bg_share_image.jpg?timeStamp=${new Date().getTime()}`}
						className={styles.img}
						alt=''
						crossOrigin='anonymous'
					/>
					<div className={styles.info_wrapper}>
						<div className={styles.text_wrapper}>
							<div className={styles.text_1}>{userInfo && userInfo.nickname}</div>
							<div className={styles.text_2}>邀请你成为领读者</div>
						</div>
						<img
							src={`${qrcode}?timeStamp=${new Date().getTime()}`}
							className={styles.qr_code}
							alt=''
							crossOrigin='anonymous'
						/>
					</div>
				</div>
			</div>

			<ActionSheet
				visible={shareVisible}
				bg='#F6F6F6'
				showCloseIcon={false}
				onClose={closeShareVisible}>
				<div className={styles.op_wrapper}>
					<div className={styles.item} onClick={shareWechat}>
						<img
							src='https://cdn.kangarooread.com/mp-assets/common/wechat_icon.png'
							alt=''
							className={styles.icon}
						/>
						<div className={styles.label}>微信</div>
					</div>
					<div className={styles.item} onClick={shareImg}>
						<img
							src='https://cdn.kangarooread.com/mp-assets/common/pic_icon.png'
							alt=''
							className={styles.icon}
						/>
						<div className={styles.label}>保存图片</div>
					</div>
				</div>
				<div className={styles.share_close_btn} onClick={closeShareVisible}>
					取消
				</div>
			</ActionSheet>
		</div>
	)
}

export default InviteLeadReader
