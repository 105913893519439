import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import serv from '../../lib/api'
import { Util } from '../../lib/util'
import { useStore } from 'reto'
import { CommonStore } from '../../store'
import { bridge } from '../../lib/bridge'

const TAB_LIST = [
	{ value: 1, label: '书袋动态' },
	{ value: 2, label: '其他动态' },
	{ value: 3, label: '备注' }
]

export default function MemberUserInfo(props) {
	const { showModal, closeModal } = useStore(CommonStore)
	const { runtimeEnv } = useStore(CommonStore)
	const [detail, setDetail] = useState({})
	const [currentTab, setCurrentTab] = useState(1)

	useEffect(() => {
		document.title = '领读者详情'
		getMemberDetailInfo()
	}, []) // eslint-disable-line

	const getMemberDetailInfo = async () => {
		const { id } = props.match.params
		try {
			const res = await serv.getMemberDetailInfo(id)
			if (res) {
				const { data } = res
				setDetail({ ...data, list: data.bagOrderList } || {})
			}
		} catch (e) {
			console.log(e)
		}
	}

	const toDetail = (contextId, userId) => {
		if (runtimeEnv === 'app') {
			bridge.appNavigate({
				id: 143,
				params: `campaign=${contextId}&userId=${userId}`
			})
		}
	}

	const handleCopy = () => {
		Util.showToast('手机号已复制')
	}

	const changeTab = (v) => {
		setCurrentTab(v)
		Util.setSessionStorage('currentTab', v)
		switch (v) {
			case 1:
				setDetail({ ...detail, list: detail.bagOrderList })
				break
			case 2:
				setDetail({ ...detail, list: detail.memberOrderVoList })
				break
			case 3:
				setDetail({ ...detail, list: detail.visitList })
				break
			default:
				break
		}
	}

	const toAddDetail = () => {
		const { push } = props.history
		push(`/member-remark/-1/${props.match.params.id}`)
	}

	const toEditRemarkDetail = (id) => {
		const { push } = props.history
		push(`/member-remark/${id}/${props.match.params.id}`)
	}

	const showGDDModal = () => {
		if (detail.gddStatusValueList.length > 1) {
			showModal({
				title: '多个谷兜兜',
				content: (
					<div>
						{detail.gddStatusValueList.map((it, index) => (
							<div key={index}>{it} x1</div>
						))}
					</div>
				),
				gravity: 1,
				okBtnText: '确定',
				showModalCloseIcon: false,
				onConfirm: () => {
					closeModal()
				}
			})
		}
	}

	const toBagDetail = (bagOrderId = '', sequence) => {
		const { push } = props.history
		bagOrderId && push(`/user-bag-info/${bagOrderId}/${sequence}`)
	}

	return (
		<div className={styles.leader_info}>
			<div className={styles.base_bg}>
				<div className={styles.base_user_info}>
					<div>
						<img
							className={styles.avatar}
							src={
								detail.avatar ||
								'https://cdn-test.kangarooread.com/resource/avatar/default.png'
							}
							alt={''}
						/>
					</div>
					<div className={styles.base_right_info}>
						<div className={styles.nick_wrapper}>
							<div className={styles.nickname}>{detail.nickname || '--'}</div>
							<div className={styles.p_info_wrapper}>
								<div className={styles.p_left}>
									<div className={styles.phone}>{detail.mobile || ''}</div>
									<CopyToClipboard
										onCopy={handleCopy}
										text={detail.mobile || ''}>
										<span className={styles.copy}>复制</span>
									</CopyToClipboard>
								</div>
								<div className={styles.invitor}>
									邀请人：{detail.invitorNickname}
								</div>
							</div>
						</div>
						<div className={styles.service_type} onClick={showGDDModal}>
							订阅服务：{detail.serviceName} ｜ 谷兜兜：
							{detail.gddStatusValueList && detail.gddStatusValueList[0]}
							{detail.gddStatusValueList && detail.gddStatusValueList.length > 1
								? '等多个'
								: ''}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.income_wrapper}>
				<div className={styles.i_item}>
					订阅状态：{detail.availableDeadline}
				</div>
				<div className={styles.i_item}>
					本月已借阅书袋数：{detail.borrowBagCount}
				</div>
				<div className={styles.i_item}>
					待归还书袋数：{detail.returnBagCount}
				</div>
			</div>
			<div className={styles.dynamic_title_wrapper}>
				{TAB_LIST.map((it) => (
					<div
						className={`${styles.dynamic_title} ${
							it.value === currentTab ? styles.active : ''
						}`}
						key={it.value}
						onClick={() => changeTab(it.value)}>
						{it.label}
						{it.value === currentTab && <div className={styles.line} />}
					</div>
				))}
				{currentTab === 3 && (
					<div className={styles.add_visit_btn} onClick={toAddDetail}>
						<div className={styles.add_icon} />
						<div>新增备注</div>
					</div>
				)}
			</div>
			<div className={styles.dynamic_wrapper}>
				{(detail.list || []).map((it, index) => (
					<div className={styles.dynamic_item} key={index}>
						<div className={styles.dy_top_title_wrapper}>
							<div className={styles.dy_icon}>
								<div className={styles.dy_icon_con} />
							</div>
							{currentTab === 1 && <div> 第{it.sequence}个书袋</div>}
							{currentTab === 2 && <div>购买</div>}
							{currentTab === 3 && <div>{it.node}</div>}
						</div>
						<div className={styles.dy_card_wrapper}>
							<div className={styles.dy_line} />
							<div>
								<div
									className={styles.dy_card}
									onClick={() => {
										if (currentTab === 1) {
											toBagDetail(it.contextId, it.sequence)
										} else if (currentTab === 2) {
											toDetail(it.contextId, detail.userId)
										} else if (currentTab === 3) {
											toEditRemarkDetail(it.contextId)
										}
									}}>
									<div className={styles.dy_card_top}>
										<div className={styles.time}>{it.time}</div>
										{it.contextId && (
											<div className={styles.study}>
												{currentTab === 2 && <div>学习情况</div>}
												{currentTab !== 3 && (
													<div className={styles.right_icon} />
												)}
											</div>
										)}
									</div>
									<div className={styles.title}>
										<div className={styles.title_info}>【{it.itemName}】</div>
										{currentTab === 3 && <div className={styles.edit_icon} />}
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
