import React, { useState, useEffect } from 'react'
import styles from './index.module.less'
import serv from '../../lib/api'
import List from '../../components/List'
import { Util } from '../../lib/util'
import GroupMemberItem from './components/GroupMemberItem'

export default function GroupDetail(props) {
	const [list, setList] = useState({
		data: [],
		pageSize: 20,
		pageNum: 1,
		loading: false,
		hasMore: false
	})
	const [loaded, setLoaded] = useState(false)
	const [keyword, setKeyword] = useState('')
	const [modalVisible, setModalVisible] = useState(false)
	const [points, setPoints] = useState('')
	const [selectedUserId, setSelectedUserId] = useState('')

	const handleSetList = (key, value) => {
		list[key] = value
		setList({ ...list })
	}

	useEffect(() => {
		document.title = '会员管理'
		handleSetList('data', [])
		handleSetList('pageNum', 1)
		initData()
		setLoaded(false)
		// eslint-disable-next-line
	}, [])

	const initData = async () => {
		await getDetail()
	}

	/**
	 * @description 新政策会员列表
	 * @returns {Promise<void>}
	 */
	const getDetail = async () => {
		Util.showLoading('数据加载中...')
		const params = {
			groupId: props.match.params.id,
			pageNum: list.pageNum,
			pageSize: list.pageSize
		}
		if (keyword) {
			params.keyword = keyword
		}
		try {
			const res = await serv.getGroupDetail(params)
			if (res) {
				const { data, hasMore } = res || []
				handleSetList('data', list.data.concat(data || []))
				handleSetList('hasMore', hasMore)
				if (hasMore) {
					handleSetList('pageNum', list.pageNum + 1)
				}
			}
			setLoaded(true)
			Util.hideLoading()
		} catch (e) {
			console.log(e)
		}
	}

	const search = (e) => {
		setKeyword(e.target.value)
	}

	/**
	 * @description input回车搜索
	 * @param {*} e
	 */
	const onKeyUp = (e) => {
		if (e.keyCode === 13) {
			handleSetList('data', [])
			handleSetList('pageNum', 1)
			getDetail(keyword)
		}
	}

	const changeInput = (e) => {
		setPoints(e.target.value)
	}

	const transferPoints = async () => {
		if (isNaN(points * 1)) {
			return Util.showToast('请输入数字')
		}
		const params = {
			points,
			userId: selectedUserId,
			remainder: 1,
			skipValidate: true
		}
		try {
			const res = await serv.transferPoints(params)
			if (res) {
				const { data } = res
				if (data) {
					Util.showToast('转赠成功')
				}
				setModalVisible(false)
			}
		} catch (e) {
			console.log(e)
		}
	}

	const showModal = (userId) => {
		setSelectedUserId(userId)
		setModalVisible(true)
	}

	return (
		<>
			<div className={styles.container}>
				<div className={styles.search_wrapper_new}>
					<div className={styles.search}>
						<div>
							<img
								src={`https://cdn.kangarooread.com/mp-assets/m-web/search_icon.png`}
								alt=''
								className={styles.imgIcon}
							/>
						</div>
						<input
							className={styles.input_search}
							placeholder='输入昵称搜索用户'
							value={keyword}
							onKeyUp={onKeyUp}
							onChange={search}
						/>
					</div>
				</div>
				<div className={styles.list_detail}>
					<List
						loaded={loaded}
						dataSource={list.data}
						hasMore={list.hasMore}
						next={getDetail}>
						{list.data.map((i, idx) => (
							<GroupMemberItem data={i} key={idx} showModal={showModal} />
						))}
					</List>
				</div>
			</div>
			{modalVisible && (
				<div className={styles.modal_wrapper}>
					<div className={styles.point_modal_container}>
						<div className={styles.title}>请输入需要转赠的积分数量</div>
						<input className={styles.point_input} onChange={changeInput} />
					</div>
					<div className={styles.btn_wrapper}>
						<div className={styles.btn} onClick={() => setModalVisible(false)}>
							取消转赠
						</div>
						<div
							className={`${styles.btn} ${styles.active}`}
							onClick={transferPoints}>
							确认转赠
						</div>
					</div>
				</div>
			)}
		</>
	)
}
