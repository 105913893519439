import React from 'react'
import styles from '../../index.module.less'
import { bridge } from '../../../../lib/bridge'
import { useStore } from 'reto'
import { CommonStore } from '../../../../store'

export default function FunctionalModule(props) {
  const { runtimeEnv } = useStore(CommonStore)

  // 跳转----->订阅服务
  const toSubscriptionServices = () => {
    if (runtimeEnv === 'app') {
      bridge.appNavigate({ id: 1})
    }
    // window.location.href = ''
  }

  // 跳转----->故事妈妈
  const toCamp = () => {
    if (runtimeEnv === 'app') {
      bridge.appNavigate({ id: 107, params: `id=${props.campId}` })
    }
  }


  //跳转----->邀请成员
  const showInvitationMember = () => {
    // if (runtimeEnv === 'app') {
    //   bridge.appNavigate({ id: 137 })
    // }
    props.history.push('/invite-lead-reader')
  }


  return <>
    {
      props.loaded && props.level && props.level > 0 ? <div className={styles.module_wrapper}>
        <div className={styles.module1} onClick={() => toSubscriptionServices()}/>
        {/* 订阅服务*/}
        <div className={styles.module2} onClick={() => toCamp()}/>
        {/* 故事妈妈*/}
        <div className={styles.module3} onClick={() => showInvitationMember()}/>
        {/* 团队成员*/}
      </div> : null
    }
  </>


}