import moment from 'moment'
import React, { useState } from 'react'
import styles from './index.module.less'

export default function ListItem(props) {
  const { data, id, userId, showSource } = props
  const [allRemark, setAllRemarkVisible] = useState(true)

  return (
    <>
      {data ? (
        <div className={styles.item}>
          <div className={`${styles.c_1} ${showSource ? styles.c_1_t : ''}`}>
            <div className={`${showSource ? styles.title : styles.all_title}`}
                 onClick={() => setAllRemarkVisible(!allRemark)}>{data.title}</div>
            <div className={styles.time}>
              {moment(data.createTime).format('yyyy-MM-DD HH:mm')}
            </div>
          </div>
          {showSource && <div className={styles.c_2}>
            <div className={styles.nickname}>{data.source}</div>
          </div>}

          <div className={`${id === userId ? styles.c_3_t : styles.c_3}`}>
            {
              data.achievemen === null ?
                <span style={{ color: '#FF5454' }}>无</span> :
                data.achievement === 0 ?
                  <span style={{ color: '#FF5454' }}>{`${data.achievement}`}</span> :
                  data.operation === 1 ? <span style={{ color: '#FF5454' }}>{`+${data.achievement}`}</span> :
                    <span style={{ color: '#FFBD34' }}>{`-${data.achievement}`}</span>
            }
          </div>
          <div className={` ${id === userId ? styles.c_4_t : styles.c_4}`}>
            {
              data.performance === null ?
                <span style={{ color: '#FF5454' }}>无</span> :
                data.performance === 0 ?
                  <span style={{ color: '#FF5454' }}>{`${data.performance}`}</span> :
                  data.operation === 1 ? <span style={{ color: '#FF5454' }}>{`+${data.performance}`}</span> :
                    <span style={{ color: '#FFBD34' }}>{`-${data.performance}`}</span>
            }
          </div>
          <div className={styles.split_line}/>
        </div>
      ) : null}
    </>
  )
}
