import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import serv from '../../lib/api'
import moment from 'moment'
import { Util } from '../../lib/util'
import { useStore } from 'reto'
import { CommonStore } from '../../store'
import { bridge } from '../../lib/bridge'

const SERVICE_TYPE = [
  { value: 1, label: '688年卡' },
  { value: 10, label: '月订阅服务' },
  { value: 11, label: '月订阅服务' },
  { value: 12, label: '好读家庭年卡' }
]

export default function LevelUserInfo(props) {
  const { runtimeEnv } = useStore(CommonStore)
  const [detail, setDetail] = useState({})

  useEffect(() => {
    document.title = '领读者详情'
    initData()
  }, [])    // eslint-disable-line


  const initData = async () => {
    const { id } = props.match.params
    try {
      const res = await serv.getMemberReaderDetail(id)
      if (res) {
        const { data } = res
        setDetail(data || {})
      }
    } catch (e) {
      console.log(e)
    }
  }

  const toDetail = (contextId, userId) => {
    if (runtimeEnv === 'app') {
      bridge.appNavigate({ id: 143, params: `campaign=${contextId}&userId=${userId}` })
    }
  }

  const handleCopy = () => {
    Util.showToast('手机号已复制')
  }

  return (
    <div className={styles.leader_info}>
      <div className={styles.base_bg}>
        <div className={styles.base_user_info}>
          <div>
            <img className={styles.avatar}
                 src={detail.avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'} alt={''}/>
          </div>
          <div className={styles.base_right_info}>
            <div className={styles.nick_wrapper}>
              <div className={styles.nickname}>{detail.realName || '--'}</div>
              <div className={styles.phone}>{detail.phone || ''}</div>
              <CopyToClipboard
                onCopy={handleCopy}
                text={detail.phone || ''}>
                <span className={styles.copy}>复制</span>
              </CopyToClipboard>
            </div>
            <div className={styles.service_type}>订阅服务：{
              (SERVICE_TYPE.find((it) => it.value === detail.serviceId) || {}).label || '无'
            }</div>
          </div>
        </div>
      </div>
      <div className={styles.income_wrapper}>
        <div className={styles.income_item}>
          <div className={styles.income_item_case}>
            <div className={styles.value}>{detail.revenue && detail.revenue.total}</div>
            <div className={styles.label}>总收入</div>
          </div>
          <div className={styles.income_item_case}>
            <div className={styles.value}>{detail.revenue && detail.revenue.lastMonthly}</div>
            <div className={styles.label}>上月收入</div>
          </div>
          <div className={styles.income_item_case}>
            <div className={styles.value}>{detail.revenue && detail.revenue.monthly}</div>
            <div className={styles.label}>本月收入</div>
          </div>
        </div>
      </div>
      <div className={styles.member_info_wrapper}>
        <div className={styles.member_info}>
          {
            (detail.memberCountList || []).map((it, index) => (
              <div
                className={styles.member_item}
                key={index}
              >
                <span className={styles.label}>{it.memberServiceTitle}</span>
                <span className={styles.value}>{it.memberCount}</span>
                {
                  it.monthlyMemberCount > 0 && <span className={styles.add_value}>本月+{it.monthlyMemberCount}</span>
                }
              </div>
            ))
          }
        </div>
      </div>
      <div className={styles.dynamic_title}>动态</div>
      <div className={styles.dynamic_wrapper}>
        {
          (detail.memberTrendList || []).map((it, index) => (
            <div className={styles.dynamic_item} key={index}>
              <div className={styles.dy_top_title_wrapper}>
                <div className={styles.dy_icon}>
                  <div className={styles.dy_icon_con}/>
                </div>
                购买
              </div>
              <div className={styles.dy_card_wrapper}>
                <div className={styles.dy_line}/>
                <div className={styles.dy_content}>
                  <div className={styles.dy_card}>
                    <div className={styles.dy_card_top}>
                      <div
                        className={styles.time}>{it.createTime && moment(it.createTime).format('YYYY年MM月DD日 HH:mm')}</div>
                      {
                        it.contextId &&
                        <div className={styles.study} onClick={() => toDetail(it.contextId, detail.userId)}>
                          <div>学习情况</div>
                          <div className={styles.right_icon}/>
                        </div>
                      }
                    </div>
                    <div className={styles.title}>【{it.title}】</div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}