import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import DatePicker from '../../components/DataPicker'
import moment from 'moment'
import { env } from '../../lib/conf'
import MyTeamItem from './components/MyTeamItem'
import serv from '../../lib/api'
import { Util } from '../../lib/util'
import List from '../../components/List'



export default function Achievement(props) {
  const [datePickerVisible, setDatePickerVisible] = useState(false)
  const [date, setDate] = useState(null)
  const [list, setList] = useState({
    dataItem: [],
    data: [],
    size: 20,
    pageNum: 1,
    hasMore: false
  })
  const [searchDate, setSearchDate] = useState(new Date())
  const [achieveOverview, setAchieveOverview] = useState({})
  const [sort, setSort] = useState('MONTHLY_PERFORMANCE_ASC')
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    handleSetList('data', [])
    handleSetList('pageNum', 1)
    document.title = '绩效奖励与明细'
    setLoaded(false)
    iniData()
    // eslint-disable-next-line
  }, [ searchDate, sort])

  const iniData = async () => {
    Util.showLoading('数据加载中...')
    await getAchieveOverview()
    await teamAchieveList()
    Util.hideLoading()
  }

  /**
   * @description setList
   * @param {*} key
   * @param {*} value
   */
  const handleSetList = (key, value) => {
    list[key] = value
    setList({ ...list })
  }

  /**
   * @description 展示日期框
   */
  const showDatePicker = () => {
    setDatePickerVisible(true)
  }

  /**
   * @description 关闭日期框
   */
  const closeDatePicker = () => {
    setDatePickerVisible(false)
  }

  /**
   * @description 日期框改变的回调
   * @param {*} date
   */
  const onChangeDate = async (date) => {
    setSearchDate(date)
    setDate(date)
    setDatePickerVisible(false)
    handleSetList('pageNum', 1)
  }

  /**
   * @description 排序 1、消费额人 2、总消费额
   */
  const handleSort = (type) => {
    setSort(type)
  }

  /**
   * @description 收益信息头部接口
   */
  const getAchieveOverview = async () => {
    const month = moment(searchDate).format('YYYY-MM')
    try {
      const res = await serv.getTeamMonthlyAchievement(month)
      if (res) {
        const { data } = res
        setAchieveOverview(data || {})
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description
   */
  const teamAchieveList = async () => {
    const params = {
      size: list.size,
      pageNum: list.pageNum,
      month: moment(searchDate).format('YYYY-MM')
    }

    if (sort === 1) {
      params.sortRule = 'MONTHLY_ACHIEVEMENT_DESC'
    } else if (sort === 2) {
      params.sortRule = 'MONTHLY_PERFORMANCE_DESC'
    } else {
      params.sortRule = 'TOTAL_ACHIEVEMENT_DESC'
    }

    try {
      const res = await serv.getAchievementList(params)
      if (res) {
        const { data, hasMore } = res || {}
        if (data) {
          handleSetList('hasMore', hasMore)
          handleSetList('data', list.data.concat(data) || [])
          if (hasMore) {
            handleSetList('pageNum', list.pageNum + 1)
          }
        }
        setLoaded(true)
      }
    } catch (err) {
      console.log(err)
      Util.showToast(JSON.stringify(err) || '异常错误')
    }
  }

  /**
   * @description 跳转消费额明细页面
   */
  const showPerDetail = (id) => {
    props.history.push(`/performance-detail/${id}/${moment(searchDate).format('yyyy-MM')}`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.date_wrapper}>
        <img
          src={`${env.IMG}m-web/achi_date_icon.png`}
          alt=""
          className={styles.date_img}
        />

        <DatePicker
          visible={datePickerVisible}
          title="请选择月份"
          mode="month"
          format="YYYY-MM"
          minDate="2021-05-01"
          onClose={closeDatePicker}
          onChange={onChangeDate}>
          <div className={styles.data_picker} onClick={showDatePicker}>
            {date || moment(new Date()).format('YYYY-MM')}&nbsp;
            <i className={`iconfont crm-down-arrow ${styles.crm_down_arrow}`}/>
          </div>
        </DatePicker>
      </div>
      <div className={styles.board_wrapper}>
        <div className={styles.board}>
          <div className={styles.award}>
            <div className={styles.award_item}>
              <div className={styles.num}>
                {Util.formatMoney(achieveOverview.directAchievement || 0)}
              </div>
              <div className={styles.label}>直属团队业绩</div>
            </div>
            <div className={styles.award_item}>
              <div className={styles.num}>{Util.formatMoney(achieveOverview.directPerformance || 0)}</div>
              <div className={styles.label}>直属团队绩效</div>
            </div>
            <div className={styles.award_item}>
              <div className={styles.num}>
                {Util.formatMoney(achieveOverview.equalPerformance || 0)}
              </div>
              <div className={styles.label}>平级团队绩效</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        <div className={styles.team_tab}>
          <div className={styles.team_tab_item}>
            成员
          </div>
          <div className={styles.team_tab_item} onClick={() => handleSort(3)}>
            累计业绩
            {sort === 3 ?
              <i className={`iconfont crm-down-arrow-copy ${styles.down_arrow_copy}`}/> :
              <i className={`iconfont crm-down-arrow ${styles.down_arrow}`}/>}
          </div>
          <div onClick={() => handleSort(1)} className={styles.team_tab_item}>
            本月业绩
            {sort === 1 ?
              <i className={`iconfont crm-down-arrow-copy ${styles.down_arrow_copy}`}/> :
              <i className={`iconfont crm-down-arrow ${styles.down_arrow}`}/>}
          </div>
          <div onClick={() => handleSort(2)} className={styles.team_tab_item}>
            本月绩效
            {sort === 2 ?
              <i className={`iconfont crm-down-arrow-copy ${styles.down_arrow_copy}`}/> :
              <i className={`iconfont crm-down-arrow ${styles.down_arrow}`}/>}
          </div>
        </div>
        <div className={styles.team_line}/>
      </div>
      <List
        loaded={loaded}
        dataSource={list.data}
        hasMore={list.hasMore}
        next={teamAchieveList}>
        {list.data.map((i, idx) =>
          <MyTeamItem data={i} key={idx} redirectUrl={() => showPerDetail(i.userId)}/>
        )}
      </List>
    </div>
  )
}
