import React, { useState, useEffect } from 'react'
import styles from './index.module.less'
import MemberItem from './components/MemberItem'
import { env } from '../../lib/conf'
import serv from '../../lib/api'
import List from '../../components/List'
import { Util } from '../../lib/util'

const NEW_TAB = [
	{ value: 1, label: '月订阅会员' },
	{ value: 2, label: '年卡会员' },
	{ value: 3, label: '课会员' }
]

export default function MemberManagement(props) {
	const [list, setList] = useState({
		data: [],
		size: 20,
		pageNum: 1,
		loading: false,
		hasMore: false
	})
	const [currentTab, setCurrentTab] = useState(1)
	const [keyWords, setKeyWords] = useState('')
	const [loaded, setLoaded] = useState(false)
	const [level, setLevel] = useState(0) //区分新老政策

	const handleSetList = (key, value) => {
		list[key] = value
		setList({ ...list })
	}

	useEffect(() => {
		document.title = '会员管理'
		handleSetList('data', [])
		handleSetList('pageNum', 1)
		initData()
		setLoaded(false)
		// eslint-disable-next-line
	}, [currentTab])

	const initData = async () => {
		const level = props.match.params.level
		setLevel(level * 1)
		if (level && level > 0) {
			await getServiceMemberList()
		} else {
			await getCommonList()
		}
	}

	/**
	 * @description 获取会员列表
	 * @param {*} keyWords
	 */
	const getCommonList = async (keyWords) => {
		const params = {
			pageNum: list.pageNum,
			size: list.size,
			keywords: keyWords
		}
		try {
			const res = await serv.getCommonList(params)
			if (res) {
				const { data, hasMore } = res
				handleSetList('data', list.data.concat(data || []))
				handleSetList('hasMore', hasMore)
				if (hasMore) {
					handleSetList('pageNum', list.pageNum + 1)
				}
			}
		} catch (err) {
			console.log(err)
		} finally {
			setLoaded(true)
		}
	}

	/**
	 * @description 新政策会员列表
	 * @param keywords
	 * @returns {Promise<void>}
	 */
	const getServiceMemberList = async (keywords) => {
		Util.showLoading('数据加载中...')
		const params = {
			pageNum: list.pageNum,
			size: list.size,
			type: currentTab,
			keywords
		}
		try {
			const res = await serv.getServiceMemberList(params)
			if (res) {
				const { data, hasMore } = res || []
				data.forEach((it) => {
					let arr = []
					const { tagList } = it
					tagList &&
						tagList.forEach((it) => {
							if (
								it.indexOf('30') !== -1 ||
								it.indexOf('168') !== -1 ||
								it.indexOf('688') !== -1
							) {
								arr.push({ bgColor: '#FFF1C7', color: '#75310E', label: it })
							} else if (it.indexOf('188') !== -1) {
								arr.push({ bgColor: '#FFE3D3', color: '#873C12', label: it })
							} else {
								arr.push({ bgColor: '#F4F0FC', color: '#5C3995', label: it })
							}
						})
					it.newTagList = arr
				})
				handleSetList('data', list.data.concat(data || []))
				handleSetList('hasMore', hasMore)
				if (hasMore) {
					handleSetList('pageNum', list.pageNum + 1)
				}
				setLoaded(true)
			}
		} catch (e) {
			console.log(e)
		} finally {
			Util.hideLoading()
		}
	}

	/**
	 * @description 获取input值
	 * @param {*} e
	 */
	const search = (e) => {
		setKeyWords(e.target.value)
	}

	/**
	 * @description input回车搜索
	 * @param {*} e
	 */
	const onKeyUp = (e) => {
		const keywords = e.target.value
		if (e.keyCode === 13) {
			const level = props.match.params.level
			handleSetList('data', [])
			handleSetList('pageNum', 1)
			if (level && level > 0) {
				getServiceMemberList(keywords)
			} else {
				getCommonList(keyWords)
			}
		}
	}

	/**
	 * @description 跳转异常会员列表
	 */
	const showAbnormalVo = () => {
		const { history } = props
		history.push('./abnormal-detail')
	}

	/**
	 * @description 标签切换
	 */
	const changeTabNew = (value) => {
		setCurrentTab(value)
	}

	return (
		<div className={styles.container}>
			{(!level || level === 0) && (
				<div className={styles.title} onClick={showAbnormalVo}>
					查看服务异常会员
				</div>
			)}
			{level && level > 0 && (
				<div className={styles.tab_wrapper}>
					{NEW_TAB.map((i) => (
						<div
							className={`${styles.tab_item_new} ${
								currentTab === i.value ? styles.active : ''
							}`}
							key={i.value}
							onClick={() => changeTabNew(i.value)}>
							{i.label}
							<div className={styles.tab_bar} />
						</div>
					))}
					{/*{*/}
					{/*  level && level > 0 && <div className={styles.explain_wrapper} onClick={() => toExplainDetail()}>*/}
					{/*    用户状态*/}
					{/*    <i className={styles.explain_icon}/>*/}
					{/*  </div>*/}
					{/*}*/}
				</div>
			)}
			{level && level > 0 ? (
				<div className={styles.search_wrapper_new}>
					<div className={styles.search}>
						<div>
							<img
								src={`${env.IMG}m-web/search_icon.png`}
								alt=''
								className={styles.imgIcon}
							/>
						</div>

						<input
							className={styles.input_search}
							placeholder='请输入昵称或手机号'
							value={keyWords}
							onKeyUp={onKeyUp}
							onChange={search}
						/>
					</div>
				</div>
			) : (
				<div className={styles.search_wrapper}>
					<div className={styles.search}>
						<div className={styles.data_picker}>按成为会员时间</div>
						<div>
							<img
								src={`${env.IMG}m-web/search_icon.png`}
								alt=''
								className={styles.imgIcon}
							/>
						</div>

						<input
							className={styles.input_search}
							placeholder='请输入昵称或手机号'
							value={keyWords}
							onKeyUp={onKeyUp}
							onChange={search}
						/>
					</div>
				</div>
			)}
			<div className={level && level > 0 ? styles.list_new : styles.list}>
				<List
					loaded={loaded}
					dataSource={list.data}
					hasMore={list.hasMore}
					next={level && level > 0 ? getServiceMemberList : getCommonList}>
					{list.data.map((i, idx) => (
						<MemberItem
							data={i}
							key={idx}
							level={level}
							current={currentTab}
							history={props.history}
						/>
					))}
				</List>
			</div>
		</div>
	)
}
