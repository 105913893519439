import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import moment from 'moment'
import DatePicker from '../../components/DataPicker'
import List from '../../components/List'
import serv from '../../lib/api'
import { Util } from '../../lib/util'

export default function LeadReaderPerformance(props) {
  const [datePickerVisible, setDatePickerVisible] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [totalAchievement, setTotalAchievement] = useState('')
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM'))
  const [list, setList] = useState({
    data: [],
    size: 20,
    pageNum: 1,
    loading: false,
    hasMore: false
  })

  useEffect(() => {
    handleSetList('data', [])
    handleSetList('pageNum', 1)
    getList()
    document.title = '收益明细'
  }, [date]) //eslint-disable-line

  const handleSetList = (key, value) => {
    list[key] = value
    setList({ ...list })
  }

  const onChangeDate = (date) => {
    setDate(date)
    setDatePickerVisible(false)
    handleSetList('pageNum', 1)
  }

  const getList = async () => {
    Util.showLoading()
    const params = {
      pageNum: list.pageNum,
      size: list.size,
      month: date,
    }
    try {
      const res = await serv.getAchievementDetail(params)
      if (res) {
        const { data, hasMore } = res
        if (data) {
          const { itemList, totalAchievement: total } = data
          setTotalAchievement(total)
          handleSetList('data', list.data.concat(itemList || []))
          handleSetList('hasMore', hasMore)
          if (hasMore) {
            handleSetList('pageNum', list.pageNum + 1)
          }
        }
        setLoaded(true)
      }
    } catch (err) {
      console.log(err)
    } finally {
      Util.hideLoading()
    }
  }

  return <div className={styles.l_r_detail_wrapper}>
    <div className={styles.head}>
      <DatePicker
        visible={datePickerVisible}
        title="请选择月份"
        mode="month"
        format="YYYY-MM"
        minDate="2021-05-01"
        onClose={() => setDatePickerVisible(false)}
        onChange={onChangeDate}>
        <div className={styles.data_picker} onClick={() => setDatePickerVisible(true)}>
          {date}
          <div className={styles.date_icon}/>
        </div>
      </DatePicker>
      <div className={styles.info}>总业绩 {totalAchievement || 0}</div>
    </div>
    <div className={styles.list_wrapper}>
      <List
        loaded={loaded}
        dataSource={list.data}
        hasMore={list.hasMore}
        next={getList}>
        {
          list.data && list.data.map((it) => (
            <div>
              <div className={styles.list_item}>
                <div>
                  <div className={styles.title}>{it.title || ''}</div>
                  <div className={styles.date}>{moment(it.createTime).format('YYYY-MM-DD HH:ss')}</div>
                </div>
                {
                  it.achievement === null ?
                    <div className={styles.value}>无</div> : it.achievement === 0 ?
                    <div className={styles.value}>{it.achievement}</div> :
                    it.operation === 1 ? <div className={styles.value}>+{it.achievement}</div> :
                      <div className={styles.value1}>-{it.achievement}</div>
                }
              </div>
              <div className={styles.line}/>
            </div>
          ))
        }
      </List>
    </div>
  </div>
}