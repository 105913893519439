import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'reto'
import { CommonStore } from './store'
import './index.less'
import App from './layout/App'

ReactDOM.render(
  <React.StrictMode>
    <Provider of={CommonStore} memo>
      <App/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
