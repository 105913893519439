import React from 'react'
import styles from '../../index.module.less'
import Card from '../../../../components/Card'

export default function TeamManagement(
  {
    level,
    loaded,
    history,
    teamService,
    commonCount,
    returnVisitCount,
    role,
    directPartnerVo,
    directCommonVo,
    directBranchVo,
    affStatistics
  }) {

  //跳转 ------> 实习领读者
  const toInternshipDetail = () => {
    history.push(`/internship-lead-reader`)
  }

  //跳转----->推广人列表
  const showPromoterDetail = () => {
    history.push(`/promoter-management/${level}`)
  }

  //跳转----->会员列表
  const showMemberDetail = () => {
    history.push(`/member-management/${level}`)
  }

  //跳转----->须回访列表
  const showReturnVisitDetail = () => {
    history.push(`/return-visit/${level}`)
  }

  //跳转----->分会列表
  const showBranchDetail = () => {
    history.push(`/branch-management/${level}`)
  }

  return <>
    {
      loaded && level && level > 0 ?
        <Card title={`${loaded ? '服务团队' : ''}`} extra={<span>实习领读者</span>} onClick={toInternshipDetail}>
          {
            level <= 3 ?
              <div className={styles.l_team_content_new}>
                <div className={styles.l_team_item} onClick={showPromoterDetail}>
                  <div className={styles.l_team_i_top_num}>
                    {(teamService && teamService.primaryCnt) || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>普通领读者</div>
                </div>
                <div className={styles.l_team_item} onClick={showMemberDetail}>
                  <div className={styles.l_team_i_top_num}>
                    {commonCount || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>会员</div>
                </div>
                <div className={styles.l_team_item} onClick={showReturnVisitDetail}>
                  <div className={styles.l_team_i_top_num}>
                    {returnVisitCount || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>须回访</div>
                  <div
                    className={styles.l_team_i_bottom}
                    style={{ backgroundColor: '#fff' }}>
                  </div>
                </div>
              </div> : level >= 4 ? <div className={styles.l_team_content_new}>
                <div className={styles.l_team_item} onClick={showBranchDetail}>
                  <div className={styles.l_team_i_top_num}>
                    {(teamService && teamService.seniorCnt) || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>资深领读者</div>
                </div>
                <div className={styles.l_team_item} onClick={showPromoterDetail}>
                  <div className={styles.l_team_i_top_num}>
                    {(teamService && teamService.primaryCnt) || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>普通领读者</div>
                </div>
                <div className={styles.l_team_item} onClick={showMemberDetail}>
                  <div className={styles.l_team_i_top_num}>
                    {commonCount || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>会员</div>
                </div>
                <div className={styles.l_team_item} onClick={showReturnVisitDetail}>
                  <div className={styles.l_team_i_top_num}>
                    {returnVisitCount || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>须回访</div>
                  <div
                    className={styles.l_team_i_bottom}
                    style={{ backgroundColor: '#fff' }}>
                  </div>
                </div>
              </div> : null
          }
        </Card> : <Card
          title={`${loaded ? '团队' : ''}`}
        >
          {loaded ? role === 2 ? (
            <div className={styles.l_team_content}>
              <div className={styles.l_team_item} onClick={showPromoterDetail}>
                <div className={styles.l_team_i_top}>
                  <div className={styles.l_team_i_top_num}>
                    {(directPartnerVo && directPartnerVo.direct) || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>推广人</div>
                </div>
                <div className={styles.l_team_i_bottom}>
                  <div>
                    本月新增
                    {(directPartnerVo && directPartnerVo.thisMonthInr) || 0}
                  </div>
                </div>
              </div>
              <div className={styles.l_team_item} onClick={showMemberDetail}>
                <div className={styles.l_team_i_top}>
                  <div className={styles.l_team_i_top_num}>
                    {(directCommonVo && directCommonVo.direct) || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>会员</div>
                </div>
                <div className={styles.l_team_i_bottom}>
                  <div>
                    本月新增
                    {(directCommonVo && directCommonVo.thisMonthInr) || 0}
                  </div>
                </div>
              </div>
              <div
                className={styles.l_team_item}
                onClick={showReturnVisitDetail}>
                <div className={styles.l_team_i_top}>
                  <div className={styles.l_team_i_top_num}>
                    {(affStatistics.dueSoonVo && affStatistics.dueSoonVo.count) || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>须回访</div>
                </div>
                <div
                  className={styles.l_team_i_bottom}
                  style={{ backgroundColor: '#fff' }}>
                </div>
              </div>
            </div>
          ) : role === 3 || role === 4 ? (
            <div className={`${styles.l_team_content} ${styles.team_role3}`}>
              <div className={styles.l_team_item} onClick={showBranchDetail}>
                <div className={styles.l_team_i_top}>
                  <div className={styles.l_team_i_top_num}>
                    {(directBranchVo && directBranchVo.count) || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>分会长</div>
                </div>
                <div className={styles.l_team_i_bottom_team_role3}>
                  <div>
                    本月新增
                    {(directBranchVo && directBranchVo.thisMonthInr) || 0}
                  </div>
                </div>
              </div>
              <div className={styles.l_team_item} onClick={showPromoterDetail}>
                <div className={styles.l_team_i_top}>
                  <div className={styles.l_team_i_top_num}>
                    {(directPartnerVo && directPartnerVo.direct) || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>推广人</div>
                </div>
                <div className={styles.l_team_i_bottom_team_role3}>
                  <div>
                    本月新增
                    {(directPartnerVo && directPartnerVo.thisMonthInr) || 0}
                  </div>
                </div>
              </div>
              <div className={styles.l_team_item} onClick={showMemberDetail}>
                <div className={styles.l_team_i_top}>
                  <div className={styles.l_team_i_top_num}>
                    {(directCommonVo && directCommonVo.direct) || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>会员</div>
                </div>
                <div className={styles.l_team_i_bottom_team_role3}>
                  <div>
                    本月新增
                    {(directCommonVo && directCommonVo.thisMonthInr) || 0}
                  </div>
                </div>
              </div>
              <div
                className={styles.l_team_item}
                onClick={showReturnVisitDetail}>
                <div className={styles.l_team_i_top}>
                  <div className={styles.l_team_i_top_num}>
                    {(affStatistics.dueSoonVo && affStatistics.dueSoonVo.count) || 0}
                  </div>
                  <div className={styles.l_team_i_top_name}>须回访</div>
                </div>
                <div
                  className={styles.l_team_i_bottom_team_role3}
                  style={{ backgroundColor: '#fff' }}>
                  <div/>
                </div>
              </div>
            </div>
          ) : null : <div className={styles.default_card2}/>}
        </Card>
    }
  </>


}