import React, { useState, useEffect } from 'react'
import { useStore } from 'reto'
import { CommonStore } from '../../store'
import styles from './index.module.less'
import { Util } from '../../lib/util'
import {storage } from '../../lib/conf'
import { bridge } from '../../lib/bridge'
import wx from 'weixin-js-sdk'
import serv from '../../lib/api'
import CardStock from './components/CardStock'
import NoticeInfo from './components/NoticeInfo'
import FunctionalModule from './components/FunctionalModule'
import Assistant from './components/Assistant'
import GroupManagement from './components/GroupManagement'
import OldEarning from './components/OldEarning'
import TeamManagement from './components/TeamManagement'
import LearnActivity from './components/LearnActivity'
import BottomModule from './components/BottomModule'
import UserInfo from './components/UserInfo'
import UpgradeModal from './components/UpgradeModal'

export default function Dashboard(props) {
  const [id, setId] = useState(0)
  const [user, setUser] = useState({})
  const { runtimeEnv, showModal, closeModal } = useStore(CommonStore)
  const [role, setRole] = useState(0)
  const [affStatistics, setAffStatistics] = useState({})
  const [directPartnerVo, setDirectPartnerVo] = useState({})
  const [directBranchVo, setDirectBranchVo] = useState({})
  const [directCommonVo, setDirectCommonVo] = useState({})
  const [homeData, setHomeData] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [assLoaded, setAssLoaded] = useState(false)
  const [level, setLevel] = useState(0) //区分新老政策
  const [cerealDetailData, setCerealDetailData] = useState({})
  const [lastNewsData, setLastNewsData] = useState({})
  const [birthdayList, setBirthdayList] = useState({})
  const [assistantRole, setAssistantRole] = useState(1)
  const [teamService, setTeamService] = useState({})
  const [commonCount, setCommonCount] = useState(0)
  const [returnVisitCount, setReturnVisitCount] = useState(0)
  const [agreeCount, setAgreeCount] = useState(0)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)

  const [campId, setCampId] = useState('')
  const token = Util.getParams('token')

  const handleSetBirthdayList = (key, value) => {
    birthdayList[key] = value
    setBirthdayList({ ...birthdayList })
  }
  /**
   * @description 环境判断
   */

  const fetchUser = async () => {
    const token = await Util.checkAuth()
    if (token) {
      try {
        const res = await serv.getDashboardUserInfo()
        if (res) {
          const { data } = res
          setUser(data)
          setRole(data.role)
          setId(data.id)
          setLevel(data.level)
          setShowUpgradeModal(data.upgradeNotify)
          if(data.level <= 0 ){
            getAffiliationTeamStatistics(data.id)
          }
          // if(data.level >= 3){
          //   getUpgradeResult()
          // }
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      switch (runtimeEnv) {
        case 'app':
          bridge.loginFromApp().then((userInfo) => {
            if (!userInfo) {
              Util.showToast('请先登录')
            }
          })
          break
        case 'mp':
          wx.miniProgram.navigateTo({
            url: '/pages/login/index'
          })
          break
        case 'h5':
          props.history.push('/login')
          break

        default:
          break
      }
    }
  }

  useEffect(() => {
    initData()
    // eslint-disable-next-line
  }, [])

  const initData = () => {
    Util.showLoading('加载中...')
    Promise.race([
      fetchUser(),
      getHomePage(),
      getCerealDetail(),
      getLastNewsOfNotification(),
      getAssistantInfo(),
      getBirthdayInfo(),
      getLatestCamp(),
      getTeamService(),
      getCommonCount(),
      getReturnVisitCount(),
      getAgreeCnt()
    ]).then(_ => {
      setLoaded(true)
      Util.hideLoading()
    })
  }

  /**
   * @description 获取实习领读者数量
   */
  const getAgreeCnt = async () => {
    try {
      const res = await serv.getAgreeCnt()
      if (res) {
        const { data } = res || {}
        setAgreeCount(data || 0)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取新政策需回访数量
   */
  const getReturnVisitCount = async () => {
    try {
      const res = await serv.getReturnVisitCount()
      if (res) {
        const { data } = res || {}
        setReturnVisitCount(data || 0)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取新政策会员数
   */
  const getCommonCount = async () => {
    try {
      const res = await serv.getCommonCount()
      if (res) {
        const { data } = res || {}
        setCommonCount(data || 0)
      }
    } catch (err) {
      console.log(err)
    }
  }
  /**
   * @description 获取新政策团队信息
   */
  const getTeamService = async () => {
    try {
      const res = await serv.getTeamService()
      if (res) {
        const { data } = res || {}
        setTeamService(data || {})
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取卡及钱包信息
   */
  const getHomePage = async () => {
    try {
      const res = await serv.getHomePage()
      if (res) {
        const { data } = res || {}
        setHomeData(data || {})
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取谷粒详情
   * @returns {Promise<void>}
   */
  const getCerealDetail = async () => {
    try {
      const res = await serv.getCerealDetail()
      if (res) {
        const { data } = res
        setCerealDetailData(data || {})
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取最新通知
   * @returns {Promise<void>}
   */
  const getLastNewsOfNotification = async () => {
    try {
      const res = await serv.getLastNewsOfNotification()
      if (res) {
        const { data } = res
        setLastNewsData(data || {})
      }
    } catch (err) {
      console.log(err)
    }
  }


  /**
   * @description 获取最生日相关通知
   * @returns {Promise<void>}
   */
  const getBirthdayInfo = async () => {
    try {
      const res = await serv.getBirthdayInfo()
      if (res) {
        const { data } = res
        setBirthdayList(data || {})
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取助教身份
   * @returns {Promise<void>}
   */
  const getAssistantInfo = async () => {
    try {
      const res = await serv.getAssistantInfo()
      if (res) {
        const { data } = res
        const { assistantRole } = data || {}
        setAssistantRole(assistantRole)
        setAssLoaded(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取campId
   * @returns {Promise<void>}
   */
  const getLatestCamp = async () => {
    try {
      const res = await serv.getLatestCamp()
      if (res) {
        const { data } = res
        setCampId(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 团队信息
   */
  const getAffiliationTeamStatistics = async (userId) => {
    try {
      const res = await serv.getAffiliationTeamStatistics(userId)
      if (res) {
        const { data } = res || {}
        const { directBranchVo, directCommonVo, directPartnerVo } = data
        setDirectBranchVo(directBranchVo)
        setDirectCommonVo(directCommonVo)
        setDirectPartnerVo(directPartnerVo)
        setAffStatistics(data || {})
      }

    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 确认升级弹窗
   * @returns {Promise<void>}
   */
  const confirmUpgrade = async() => {
    try{
      const res = await serv.confirmUpgrade()
      if(res){
        setShowUpgradeModal(false)
      }
    }catch (e) {
      console.log(e)
    }
  }

  /**
   * @description 退出登录
   */
  const handleSignOut = () => {
    showModal({
      title: '提示',
      content: `是否退出当前帐号`,
      gravity: 1,
      showCancelBtn: true,
      okBtnText: '确认',
      cancelBtnText: '取消',
      onClose: () => {
        closeModal()
      },
      onCancel: () => {
        closeModal()
      },
      onConfirm: () => {
        closeModal()
        localStorage.removeItem(storage.userInfo)
        const { history } = props
        history.replace(`/login`)
      }
    })
  }


  /**
   * @description 关闭生日弹窗
   */
  const closeHappyModal = (e) => {
    e.stopPropagation()
    handleSetBirthdayList('showBirthday', false)
  }

  /**
   * @description 关闭生日收获地址
   */
  const closeGitModal = (e) => {
    e.stopPropagation()
    handleSetBirthdayList('needBirthdayGiftAddress', false)
  }


  //跳转 ——————> 生日贺卡
  const toHappyBirthdayDetail = (e) => {
    e.stopPropagation()
    window.location.href = `${birthdayList.birthdayWebviewUrl}?token=${token}`
    setBirthdayList({
      'birthdayList.showBirthday': false
    })
  }

  //跳转 ——————> 修改信息
  const toEditInfo = (e) => {
    e.stopPropagation()
    window.location.href = `${birthdayList.editInfoWebviewUrl}?token=${token}`
    setBirthdayList({
      'birthdayList.needArea': false
    })
  }

  //跳转 ——————> 跳转生日收获地址
  const toBirthdayDetail = (e) => {
    e.stopPropagation()
    window.location.href = `${birthdayList.birthdayAddressWebviewUrl}?token=${token}`
    setBirthdayList({
      'birthdayList.needBirthdayGiftAddress': false
    })
  }

  // 跳转 => 素材中心
  const toMaterialCenter = () => {
    const { history } = props
    history.push('/material-center-home')
    const params = {
      parent: '',
      current: 'H5CRM_MATERIAL'
    }
    serv.dataTrackingStorage(params)
  }

  //跳转 ------> 实习领读者通知
  const toLevelNotice = () => {
    const { history } = props
    history.push(`/notice-of-motion`)
  }

  //跳转 ------> 添加备注
  const toAddRemark = ()=>{
    const { history } = props
    history.push(`/member-remark/-1/-1`)
  }

  return (
		<>
			<div className={styles.container}>
				<div className={styles.dashboard_bg} />
				{loaded ? (
					<div className={styles.switch_wrapper}>
						{level > 0 && (
							<div className={styles.notice_icon} onClick={toLevelNotice}>
								{agreeCount > 0 && (
									<div className={styles.notice}>{agreeCount || 0}</div>
								)}
							</div>
						)}
					</div>
				) : null}
				{/*个人信息-*/}
				<UserInfo
					loaded={loaded}
					user={user}
					level={level}
					role={role}
					history={props.history}
				/>

				{/*卡包*/}
				<CardStock
					id={id}
					level={level}
					history={props.history}
					homeData={homeData}
					cerealDetailData={cerealDetailData}
					loaded={loaded}
				/>
				{/*政策通知*/}
				<NoticeInfo lastNewsData={lastNewsData} loaded={loaded} />

				{/*功能模块*/}
				<FunctionalModule
					campId={campId}
					loaded={loaded}
					level={level}
					history={props.history}
				/>

				{/*素材中心*/}
				{loaded && level && level > 0 ? (
					<img
						className={styles.material_center_enter}
						src='https://cdn.kangarooread.com/resource/materialCenter/material_center_enter_v2.png'
						alt=''
						onClick={toMaterialCenter}
					/>
				) : null}
				{/*-助教系统*/}
				<Assistant
					campId={campId}
					assistantRole={assistantRole}
					user={user}
					assLoaded={assLoaded}
				/>

				{/*企业微信群*/}
				<GroupManagement history={props.history} user={user} />

				{/*收益管理*/}
				<OldEarning
					role={role}
					history={props.history}
					homeData={homeData}
					loaded={loaded}
					level={level}
				/>

				{/*团队管理-*/}
				<TeamManagement
					loaded={loaded}
					level={level}
					history={props.history}
					role={role}
					commonCount={commonCount}
					teamService={teamService}
					directBranchVo={directBranchVo}
					directCommonVo={directCommonVo}
					directPartnerVo={directPartnerVo}
					returnVisitCount={returnVisitCount}
					affStatistics={affStatistics}
				/>

				{/*学习活跃率*/}
				<LearnActivity loaded={loaded} />

				{/*底部功能模块*/}
				<BottomModule loaded={loaded} />

				<img
					className={styles.add_remark}
					src={
						'https://cdn.kangarooread.com/mp-assets/m-web/add_remark_icon.png'
					}
					alt={''}
					onClick={toAddRemark}
				/>

				{loaded && runtimeEnv === 'h5' ? (
					<div className={styles.sign_out} onClick={handleSignOut}>
						退出登录
					</div>
				) : null}
			</div>
			{loaded && birthdayList.showBirthday ? (
				<div className={styles.happy_birthday_modal_wrapper}>
					<div className={styles.birthday_modal}>
						<div className={styles.icon} onClick={(e) => closeHappyModal(e)} />
						<img
							src='https://cdn.kangarooread.com/mp-assets/birthday-pic/happy_birthday_pic.png'
							className={styles.happy_bg}
							alt={``}
						/>
						<div
							className={styles.btn}
							onClick={(e) => toHappyBirthdayDetail(e)}>
							查看我的生日贺卡
						</div>
					</div>
				</div>
			) : null}
			{loaded && birthdayList.needArea ? (
				<div className={styles.edit_info_wrapper}>
					<div className={styles.edit_info}>
						<div className={styles.title}>经营信息完善</div>
						<div className={styles.desc}>{birthdayList.headerStr}</div>
						<div className={styles.btn} onClick={(e) => toEditInfo(e)}>
							确定
						</div>
					</div>
				</div>
			) : null}
			{loaded && birthdayList.needBirthdayGiftAddress ? (
				<div className={styles.birthday_modal_wrapper}>
					<div className={styles.birthday_modal}>
						<div className={styles.icon} onClick={(e) => closeGitModal(e)} />
						<img
							src='https://cdn.kangarooread.com/mp-assets/birthday_modal_bg.png'
							className={styles.bg}
							alt={``}
						/>
						<div className={styles.btn} onClick={(e) => toBirthdayDetail(e)}>
							提交收货地址
						</div>
					</div>
				</div>
			) : null}
			<UpgradeModal
				visible={showUpgradeModal}
				onClose={confirmUpgrade}
				level={level}
			/>
		</>
	)
}
