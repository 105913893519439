import React, { useEffect, useState } from 'react'
import styles from './index.module.less'
import PromoterItem from './compoonents/PromoterItem'
import { env } from '../../lib/conf'
import serv from '../../lib/api'
import List from '../../components/List'
import { useStore } from 'reto'
import { CommonStore } from '../../store'
import moment from 'moment'
import { Util } from '../../lib/util'

const dataType = [
  { value: 1, label: '本月个人消费额', isChecked: true },
  { value: 2, label: '累计消费额', isChecked: false },
  { value: 3, label: '总收入', isChecked: false },
  { value: 4, label: '本月收入', isChecked: false },
  { value: 5, label: '会员人数', isChecked: false },
  { value: 6, label: '本月直属团队消费额', isChecked: false },
  { value: 7, label: '本月总消费额', isChecked: false },
  { value: 8, label: '升级时间', isChecked: false },
  { value: 9, label: '绩效奖金', isChecked: false }
]

export default function PromoterManagement(props) {
  const { showTypeModal, closeTypeModal } = useStore(CommonStore)
  const [list, setList] = useState({
    data: [],
    size: 10,
    pageNum: 1,
    loading: false,
    hasMore: false
  })
  const [level, setLevel] = useState(0) //区分新老政策
  const [typeValue, setTypeValue] = useState('本月个人消费额')
  const [levelTypeValue, setLevelTypeValue] = useState('本月收益最多')
  const [keyWords, setKeyWords] = useState('')
  const [loaded, setLoaded] = useState(false)
  const levelSearchType = [
    { value: 'MONTHLY_REVENUE_DESC', label: '本月收益最多', isChecked: true },
    { value: 'MONTHLY_REVENUE_ASC', label: '本月收益最少', isChecked: false },
    { value: 'LAST_MONTHLY_REVENUE_DESC', label: '上月收益最多', isChecked: false },
    { value: 'LAST_MONTHLY_REVENUE_ASC', label: '上月收益最少', isChecked: false }
  ]

  const handleSetList = (key, value) => {
    list[key] = value
    setList({ ...list })
  }
  useEffect(() => {
    document.title = '普通领读者'
    handleSetList('data', [])
    handleSetList('pageNum', 1)
    setLoaded(false)
    window.addEventListener('hashchange', () => {
      closeTypeModal()
    })
    initData()
    // eslint-disable-next-line
  }, [typeValue, levelTypeValue])


  const initData = async () => {
    const level = props.match.params.level
    setLevel(level * 1)
    if (level && level > 0) {
      await getMemberReaderList()
    } else {
      await getPartnerList()
    }
  }

  /**
   * @description 获取推广人列表
   * @param {*} keyWords
   */
  const getPartnerList = async (keyWords) => {
    Util.showLoading('数据加载中...')
    const params = {
      pageNum: list.pageNum,
      size: list.size,
      keywords: keyWords,
      role: '2'
    }
    const a = valueType()
    switch (a) {
      case 1:
        params.achieveOrd = true
        break
      case 2:
        params.totalAchieveOrd = true
        break
      case 3:
        params.totalAward = true
        break
      case 4:
        params.awardOrd = true
        break
      case 5:
        params.comCntOrd = true
        break
      case 6:
        params.teamAchieveOrd = true
        break
      case 7:
        params.monthlyTotalAchieveOrd = true
        break
      case 8:
        params.userRoleChangeTimeOrd = true
        break
      case 9:
        params.achieveAwardOrd = true
        break
      default:
        break
    }
    const res = await serv.getPartnerList(params)
    try {
      if (res) {
        const { data, hasMore } = res
        handleSetList('data', list.data.concat(data || []))
        handleSetList('hasMore', hasMore)
        if (hasMore) {
          handleSetList('pageNum', list.pageNum + 1)
        }
        setLoaded(true)
      }
      Util.hideLoading()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取资深令读者信息列表
   * @param {*} keyWords
   */
  const getMemberReaderList = async (keyWords) => {
    Util.showLoading('数据加载中...')
    const params = {
      pageNum: list.pageNum,
      size: list.size,
      keyword: keyWords,
      targetLevel: '1'
    }
    const value = getLevelValueType()
    params.sortRule = value

    const res = await serv.getMemberReaderList(params)
    try {
      if (res) {
        const { data, hasMore } = res
        handleSetList('data', list.data.concat(data || []))
        handleSetList('hasMore', hasMore)
        if (hasMore) {
          handleSetList('pageNum', list.pageNum + 1)
        }
        setLoaded(true)
      }
      Util.hideLoading()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 显示type模态框
   */
  const showTypeModel = (type) => {
    if (type === 1) {
      showTypeModal({
        data: dataType,
        type: 'memType',
        onChecked: (v) => {
          const a = dataType.find((it) => (it.value === v))
          const b = dataType.filter((it) => (it.value !== v))
          setTypeValue(a.label)
          if (a.value === v) {
            a.isChecked = true
            b.map((i) => (i.isChecked = false))
          }
          closeTypeModal()
        },
        onClick: (e) => {
          if (e.target === e.currentTarget) {
            closeTypeModal()
          }
        }
      })
    } else {
      showTypeModal({
        data: levelSearchType,
        type: 'levelType',
        onChecked: (v) => {
          const a = levelSearchType.find((it) => (it.value === v))
          const b = levelSearchType.filter((it) => (it.value !== v))
          setLevelTypeValue(a.label)
          if (a.value === v) {
            a.isChecked = true
            b.map((i) => (i.isChecked = false))
          }
          closeTypeModal()
        },
        onClick: (e) => {
          if (e.target === e.currentTarget) {
            closeTypeModal()
          }
        }
      })
    }
  }

  /**
   * @description 获取单选框选中哪一个
   * @returns
   */
  const valueType = () => {
    return dataType.find((it) => (it.label === typeValue)).value
  }

  const getLevelValueType = () => {
    return levelSearchType.find((it) => (it.label === levelTypeValue)).value
  }

  const search = (e) => {
    setKeyWords(e.target.value)
  }

  /**
   * @description input回车搜索
   * @param {*} e
   */
  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleSetList('data', [])
      handleSetList('pageNum', 1)
      const level = props.match.params.level
      if (level && level > 0) {
        getMemberReaderList(keyWords)
      } else {
        getPartnerList(keyWords)

      }
    }
  }

  const toLeadReaderDetail = () => {
    const { push } = props.history
    push(`/lead-reader-list/${2}`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.fixed}>
        {
          (!level || level === 0) &&
          <div className={styles.top_info} onClick={() => toLeadReaderDetail()}>查看成为领读者的推广人</div>
        }
        <div className={level && level > 0 ? styles.title_new : styles.title_old}>{`数据更新时间：${list.data.length > 0 ?
          moment(list.data && list.data.map((it) => (it.dataTime))[0]).format('YYYY-MM-DD HH:MM:SS') : '--  --'
        }`}</div>
        {
          level && level > 0 ? <div className={styles.search_wrapper_new}>
            <div className={styles.search}>
              <div className={`${styles.data_picker} ${styles.level_modal}`} onClick={() => showTypeModel(2)}>
                {levelTypeValue}&nbsp;
                <i className={`iconfont crm-down-arrow ${styles.crm_down_arrow}`}/>
              </div>
              <div>
                <img
                  src={`${env.IMG}m-web/search_icon.png`}
                  alt=""
                  className={styles.imgIcon}
                /></div>
              <input
                className={styles.input_search}
                placeholder="请输入昵称或手机号"
                value={keyWords}
                onKeyUp={onKeyUp}
                onChange={search}/>
            </div>
          </div> : <div className={styles.search_wrapper_old}>
            <div className={styles.search}>
              <div className={styles.data_picker} onClick={() => showTypeModel(1)}>
                {typeValue}&nbsp;
                <i className={`iconfont crm-down-arrow ${styles.crm_down_arrow}`}/>
              </div>
              <div>
                <img
                  src={`${env.IMG}m-web/search_icon.png`}
                  alt=""
                  className={styles.imgIcon}
                /></div>
              <input
                className={styles.input_search}
                placeholder="请输入昵称或手机号"
                value={keyWords}
                onKeyUp={onKeyUp}
                onChange={search}/>
            </div>
          </div>
        }
      </div>
      <div className={level && level > 0 ? styles.list_new : styles.list_old}>
        <List
          next={level && level > 0 ? getMemberReaderList : getPartnerList}
          loaded={loaded}
          dataSource={list.data}
          hasMore={list.hasMore}>
          {list.data.map((i, idx) => (
            <PromoterItem data={i} key={idx} level={level} history={props.history}
            />
          ))}
        </List>
      </div>


    </div>
  )
}
