import React, { useEffect, useRef, useState } from 'react'
import { Util } from '../../../../lib/util'
import { bridge } from '../../../../lib/bridge'
import styles from '../../index.module.less'
import serv from '../../../../lib/api'
import ActionSheet from '../../../../components/ActionSheet'
import { useStore } from 'reto'
import { CommonStore } from '../../../../store'
import { isProd } from '../../../../lib/conf'

export default function Item(props) {
	const [visible, setVisible] = useState(false)
	const [showBtn, setShowBtn] = useState(false)
	const [trigger, setTrigger] = useState(false)
	const [textContent, setTextContent] = useState('')
	const [qrcode, setQrcode] = useState('')
	const textRef = useRef(null)
	const { qrcode1, qrcode2, qrcode3 } = useStore(CommonStore)

	const { item, needTag, parent } = props

	const initBtnStatus = () => {
		const { content } = item || {}
		setTextContent(content)
		const contentList = content.split('\n')
		if (contentList.length > 1) {
			let lines = 0
			contentList.forEach((it) => {
				lines += Math.ceil(((it.length || 1) * 34) / 550)
			})
			setShowBtn(lines > 4)
		} else {
			const lines = (content.length * 34) / 550
			setShowBtn(lines > 4)
		}
	}

	const getQrcode = () => {
		if (!item.showQrcode) return
		let qrcodeUrl
		switch (item.qrcodeType) {
			case 1:
				qrcodeUrl = qrcode1
				break
			case 2:
				qrcodeUrl = qrcode2
				break
			case 3:
				qrcodeUrl = qrcode3
				break
			default:
				break
		}
		setQrcode(qrcodeUrl)
	}

	useEffect(() => {
		initBtnStatus()
		getQrcode()
	}, []) // eslint-disable-line

	const handleTrigger = () => {
		setTrigger(!trigger)
	}

	const copy = () => {
		bridge
			.appSetClipboardData({ text: item.content })
			.then((_) => {
				Util.showToast('已复制到剪贴板')
			})
			.catch((_) => {
				Util.showToast('复制失败')
			})
		const params = {
			parent,
			current: 'MATERIALITEM_COPY',
			content: JSON.stringify({ text: item.content })
		}
		serv.dataTrackingStorage(params)
	}

	const checkDownloadType = () => {
		if (item.type === 2 && item.showQrcode) {
			setVisible(true)
		} else {
			downloadFiles()
		}
	}

	const downloadFiles = () => {
		const list = [...item.materialUrlList]
		bridge
			.appDownloadImages({ list })
			.then((_) => {
				Util.showToast('已保存到相册')
			})
			.catch((_) => {
				Util.showToast('保存失败')
			})
		const params = {
			parent,
			current: 'MATERIALITEM_DOWNLOAD',
			content: JSON.stringify({ list })
		}
		serv.dataTrackingStorage(params)
	}

	const downloadFilesWidthQrcode = (list) => {
		bridge
			.appDownloadImages({ list })
			.then((_) => {
				Util.showToast('已保存到相册')
			})
			.catch((_) => {
				Util.showToast('保存失败')
			})
		const params = {
			parent,
			current: 'MATERIALITEM_DOWNLOADBYCODE',
			content: JSON.stringify({ list })
		}
		serv.dataTrackingStorage(params)
	}

	const handlePreview = (index) => {
		const params = {
			index,
			list: item.materialUrlList
		}
		bridge.appImagePreviewList(params).catch((_) => {
			Util.showToast('预览失败')
		})
		const jsonStr = {
			parent,
			current: 'MATERIALITEM_PREVIEW',
			content: JSON.stringify({ index, list: item.materialUrlList })
		}
		serv.dataTrackingStorage(jsonStr)
	}

	const renderImgList = async () => {
		Util.showLoading('绘制中...请稍后')
		const list = []
		for (let i = 0; i < item.materialUrlList.length; i++) {
			const url = await handleRenderImg(item.materialUrlList[i])
			url && list.push(url)
		}
		downloadFilesWidthQrcode(list)
		setTimeout(() => {
			Util.hideLoading()
		}, 200)
	}

	const handleRenderImg = (url) => {
		if (!url) return null
		return new Promise((resolve) => {
			const qrcodePath = qrcode.replace(
				isProd
					? 'https://cdn.kangarooread.com/'
					: 'https://cdn-test.kangarooread.com/',
				''
			)
			let pathBase64 = Util.base64encode(
				`${qrcodePath}?x-oss-process=image/resize,P_18`
			)
			pathBase64 = pathBase64.replace(/\+/g, '-')
			pathBase64 = pathBase64.replace(/\//g, '_')
			pathBase64 = pathBase64.replace(/^|(\s|=)+$/g, '')
			const result = `${url}?x-oss-process=image/watermark,image_${pathBase64},t_100,g_se,x_50,y_66`
			resolve(result)
		})
	}

	return (
		<div className={styles.item}>
			<img
				src={
					item.avatar ||
					'https://cdn.kangarooread.com/resource/material_assistant_avatar.png'
				}
				className={styles.icon}
				alt=''
			/>
			<div className={styles.info}>
				<div className={styles.label_wrapper}>
					<div className={styles.label}>{item.nickName || ''}</div>
					{needTag && item.tagName && (
						<div className={styles.tag}>{item.tagName}</div>
					)}
				</div>
				<div className={styles.text_wrapper}>
					{item.description && (
						<div className={styles.desc_wrapper}>
							<span className={styles.desc}>{item.description}</span>
						</div>
					)}
					<span
						ref={textRef}
						className={`${styles.text} ${!trigger && styles.ellipsis}`}
						onClick={copy}>
						{textContent}
					</span>
					{showBtn && (
						<span className={styles.trigger_btn} onClick={handleTrigger}>
							{trigger ? '收起' : '全文'}
						</span>
					)}
				</div>
				{item.type === 2 && (
					<div className={styles.img_list}>
						{item.materialUrlList &&
							item.materialUrlList.length === 1 &&
							item.materialUrlList.map((i, idx) => (
								<img
									src={i}
									className={styles.img_1}
									alt=''
									key={idx}
									onClick={() => handlePreview(idx)}
								/>
							))}
						{item.materialUrlList &&
							item.materialUrlList.length > 1 &&
							item.materialUrlList.map((i, idx) => (
								<div
									className={styles.img_item}
									key={idx}
									onClick={() => handlePreview(idx)}>
									<img src={i} className={styles.img_2} alt='' />
								</div>
							))}
					</div>
				)}
				{item.type === 3 && (
					<div className={styles.img_list}>
						<video className={styles.video} controls>
							<source src={item.materialUrlList && item.materialUrlList[0]} />
						</video>
					</div>
				)}
				<div className={styles.time}>{item.publishTime || ''}</div>
				<div className={styles.btn_wrapper}>
					{item.type !== 1 && (
						<div className={styles.btn} onClick={checkDownloadType}>
							<span className={styles.down_icon} /> 保存
						</div>
						// <div className={styles.btn} onClick={downloadFiles}>
						// 	<span className={styles.down_icon} /> 保存
						// </div>
					)}
					<div className={styles.btn} onClick={copy}>
						<span className={styles.copy_icon} /> 复制
					</div>
				</div>
			</div>

			<ActionSheet
				visible={visible}
				showCloseIcon={false}
				onClose={() => setVisible(false)}>
				<div className={styles.a_content}>
					<div className={styles.a_item} onClick={renderImgList}>
						带小程序码
					</div>
					<div className={styles.a_item} onClick={downloadFiles}>
						直接保存
					</div>
				</div>
			</ActionSheet>

			{/* <div
				style={{
					position: 'fixed',
					left: 2000,
					top: -9999
				}}>
				<div className={styles.render_img_list}>
					{item.materialUrlList &&
						item.materialUrlList.map((img, index) => (
							<div
								className={styles.render_img_item}
								id={`${item.id}_${index}`}
								key={index}>
								<img
									src={`${img}?timeStamp=${new Date().getTime()}`}
									className={styles.render_img}
									alt=''
									crossOrigin='anonymous'
								/>
								<img src={qrcode} className={styles.qrcode} alt='' />
							</div>
						))}
				</div>
			</div> */}
		</div>
	)
}
