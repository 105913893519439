import { Util } from './util'

const { location } = window
const { href } = location

const envOptions = {
	// STAGING: 'https://api-test.kangarooread.com/',
	STAGING: 'https://api.kangarooread.com/',
	PROD: 'https://api.kangarooread.com/',
	sourceDomain: 'https://cdn.kangarooread.com/'
}

const sourceType = {
	IMG: 'mp-assets/',
	VIDEO: 'video/'
}

const prodReg = /^((http|https):\/\/m-web\.kangarooread\.com)/
const isProd = prodReg.test(href)

const serverEnv = isProd ? envOptions.PROD : envOptions.STAGING

const env = {
	isProd,
	DOMAIN: `${serverEnv}v1`,
	DOMAIN_V2: `${serverEnv}v2`,
	APIVERSION: 'v1',
	IMG: `${envOptions.sourceDomain}${sourceType.IMG}`,
	VIDEO: `${envOptions.sourceDomain}${sourceType.VIDEO}`
}

const nativeReturnMsg = {
	SUCCESS: JSON.stringify({
		result: 'ok'
	}),
	ERROR: JSON.stringify({
		result: 'error'
	})
}

const headerConfig = {
	platform: 5, // h5
	appVersion: '1.0.0',
	protocolVersion: 2,
	deviceId: Util.getDeviceId(),
	bigSmarter: {
		action: function () {
			return Util.setMD5(`${this.deviceId}_${this.platform}_${this.appVersion}`)
		}
	}
}

const storage = {
	userInfo: 'user_info',
	accessToken: 'access_token',
	shareId: 'share_id'
}

const ROLE_MAP = [
	{ value: 1, label: '会员' },
	{ value: 2, label: '推广人' },
	{ value: 3, label: '阅读分会' },
	{ value: 4, label: '服务中心' },
	{ value: 5, label: '机构' }
]

const AWARD_TYPE = [
	{ value: 1, label: '购卡返利', color: '#f16d7a' },
	{ value: 2, label: '招募佣金', color: '#ae716e' },
	{ value: 3, label: '训练营返利', color: '#f1ccb8' },
	{ value: 4, label: '阶梯返利', color: '#b7d28d' },
	{ value: 5, label: '提现', color: '#d9b8f1' },
	{ value: 6, label: '稿费', color: '#c2819f' },
	{ value: 7, label: '津贴/薪资', color: '#94bfd5' },
	{ value: 8, label: '奖金', color: '#ddc159' },
	{ value: 9, label: '线下收益', color: '#a7ddec' },
	{ value: 10, label: '谷粒使用', color: '#e7a2bb' },
	{ value: 11, label: '谷粒购买', color: '#a2d8d2' }
]

const LEAD_LEVEL = [
	{ value: 1, label: '一星领读者' },
	{ value: 2, label: '二星领读者' },
	{ value: 3, label: '三星领读者' },
	{ value: 4, label: '四星领读者' },
	{ value: 5, label: '五星领读者' },
	{ value: 6, label: '领读者导师' }
]

const MEMBER_STATUS = [
	{ value: 1, label: '群主', color: '#ffd666' },
	{ value: 2, label: '群助', color: '#85a5ff' },
	{ value: 3, label: '群捧', color: '#85a5ff' },
	{ value: 4, label: '会员', color: '#bfbfbf' },
	{ value: 5, label: '陪伴官', color: '#7d7efa' },
	{ value: 6, label: '公司工作人员', color: '#5cdbd3', name: '团队' },
	{ value: 7, label: '团队工作人员', color: '#eabc77', name: '公司' },
	{ value: 8, label: '异常人员', color: '#ff4d4f' },
	{ value: 11, label: '重复人员', color: '#ff4d4f' },
	{ value: 9, label: '过期人员', color: '#ff4d4f' }
]

const SCROLL_THRESHOLD = '100px' // 触发长列表下载下一页的阈值

const DEFAULT_AVATAR =
	'https://cdn-test.kangarooread.com/resource/avatar/default.png'

export {
	env,
	isProd,
	storage,
	headerConfig,
	nativeReturnMsg,
	SCROLL_THRESHOLD,
	ROLE_MAP,
	AWARD_TYPE,
	LEAD_LEVEL,
	DEFAULT_AVATAR,
	MEMBER_STATUS
}
