import React, { useEffect, useState } from 'react'
import serv from '../../lib/api'
import styles from './index.module.less'

const UserBagInfo = (props) => {
	const [list, setList] = useState([])

	const { sequence } = props.match.params
	document.title = `第${sequence}个书袋`

	const getList = async () => {
		try {
			const res = await serv.getSimpleDetail({
				bagOrderId: props.match.params.bagOrderId,
				excludeBox: true
			})
			if (res) {
				const { data } = res
				setList(data || [])
			}
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		if (list.length === 0) {
			getList()
		}
	}, [list]) // eslint-disable-line

	return (
		<div className={styles.user_bag_list}>
			{list &&
				list.map((book, index) => (
					<div className={styles.item} key={index}>
						<img className={styles.cover} src={book.coverPicture} alt='' />
						<div className={styles.title}>{book.title}</div>
					</div>
				))}
		</div>
	)
}

export default UserBagInfo
