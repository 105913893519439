import React, { useState, useEffect } from 'react'
import styles from './index.module.less'
import EarningsItem from './components/earningsItem'
import DatePicker from '../../components/DataPicker'
import serv from '../../lib/api'
import moment from 'moment'
import List from '../../components/List'

const TAB = [
	{ value: 1, label: '线上收益' },
	{ value: 2, label: '预估线下收益' }
]

export default function Earnings(props) {
	const typeTab = props.location.pathname.slice(-1) * 1
	const [currentTab, setCurrentTab] = useState(typeTab)
	const [datePickerVisible, setDatePickerVisible] = useState(false)
	const [date, setDate] = useState(null)
	const [searchDate, setSearchDate] = useState(new Date())
	const [list, setList] = useState({
		data: [],
		dataItem: [],
		size: 20,
		pageNum: 1,
		hasMore: false
	})
	const [loaded, setLoaded] = useState(false)

	const handleSetList = (key, value) => {
		list[key] = value
		setList({ ...list })
	}

	useEffect(() => {
		document.title = '收益列表'
		handleSetList('data', [])
		handleSetList('dataItem', [])
		handleSetList('pageNum', 1)
		setLoaded(false)
		if (currentTab === 1) {
			onlineList()
		} else {
			offlineList()
		}
    // eslint-disable-next-line
	}, [currentTab, searchDate])

	/**
	 * tab改变
	 * @param {*} value
	 */
	const changeTab = (value) => {
		setCurrentTab(value)
	}

	/**
	 * 展示日期框
	 */
	const showDatePicker = () => {
		setDatePickerVisible(true)
	}

	/**
	 * 关闭日期框
	 */
	const closeDatePicker = () => {
		setDatePickerVisible(false)
	}
	/**
	 * 日期改变的回调
	 * @param {*} date
	 */
	const onChangeDate = async (date) => {
		setSearchDate(date)
		setDate(date)
		setDatePickerVisible(false)
		handleSetList('pageNum', 1)
	}

	/**
	 * @description 线上收益接口
	 * @export
	 * @return {*}
	 */
	const onlineList = async () => {
		const params = {
			size: list.size,
			pageNum: list.pageNum,
			month: moment(searchDate).format('YYYY-MM')
		}
		try {
			const res = await serv.getAwardList(params)
			if (res) {
				const { data, hasMore } = res
				const { itemList } = data || {}
				handleSetList('hasMore', hasMore)
				handleSetList('dataItem', list.dataItem.concat(itemList || []))
				handleSetList('data', data)
				if (hasMore) {
					handleSetList('pageNum', list.pageNum + 1)
				}
				setLoaded(true)
			}
		} catch (err) {
			console.log(err)
		}
	}
	/**
	 * @description 线下收益接口
	 *
	 */
	const offlineList = async () => {
		const params = {
			size: list.size,
			pageNum: list.pageNum,
			month: moment(searchDate).format('YYYY-MM')
		}
		try {
			const res = await serv.getTransferAwardRecord(params)
			if (res) {
				const { data, hasMore } = res
				const { itemList } = data || {}
				handleSetList('hasMore', hasMore)
				handleSetList('dataItem', list.dataItem.concat(itemList ||  []))
				handleSetList('data', data)
				if (hasMore) {
					handleSetList('pageNum', list.pageNum + 1)
				}
				setLoaded(true)
			}
		} catch (err) {
			console.log(err)
		}
	}
	return (
		<div className={styles.container}>
			<div className={styles.tab_wrapper}>
				{TAB.map((i) => (
					<div
						className={`${styles.tab_item} ${
							currentTab === i.value ? styles.active : ''
						}`}
						key={i.value}
						onClick={() => changeTab(i.value)}>
						{i.label}
						<div className={styles.tab_bar} />
					</div>
				))}
			</div>
			<div className={styles.info}>
				<DatePicker
					visible={datePickerVisible}
					title='请选择月份'
					mode='month'
					format='YYYY-MM'
					onClose={closeDatePicker}
					onChange={onChangeDate}>
					<div className={styles.date_picker} onClick={showDatePicker}>
						{date || moment(new Date()).format('YYYY-MM')}&nbsp;
						<i className={`iconfont crm-down-arrow ${styles.crm_down_arrow}`} />
					</div>
				</DatePicker>
				<div className={styles.earnings}>{list.data.award}</div>
			</div>
      <div className={styles.list}>
			<List
				loaded={loaded}
				dataSource={list.dataItem }
				hasMore={list.hasMore}
				next={currentTab === 1 ? onlineList : offlineList}>
				{list.dataItem.map((i, idx) => (
					<EarningsItem data={i} type={currentTab} key={idx} />
				))}
			</List>
      </div>
		</div>
	)
}
