import React from 'react'
import styles from './index.module.less'
import { MEMBER_STATUS } from '../../../../lib/conf'
import { bridge } from '../../../../lib/bridge'
import { useStore } from 'reto'
import { CommonStore } from '../../../../store'

export default function GroupMemberItem(props) {
	const { runtimeEnv } = useStore(CommonStore)

	const { data, showModal } = props
	const { avatar, nickname, userId, groupMemberRole, userEnterGroupTime } =
		data || {}

	/**
	 * @description 跳转原生用户详情页面
	 */
	const toUserDetail = () => {
		if (runtimeEnv === 'app') {
			bridge.appNavigate({ id: 111, params: `id=${userId}` })
		}
	}

	const getLabel = () => {
		const item = MEMBER_STATUS.find((it) => it.label === groupMemberRole) || {}
		if (item) {
			if (item.label === '团队工作人员' || item.label === '公司工作人员') {
				return item.name || ''
			} else {
				return item.label || ''
			}
		}
	}

	return (
		<>
			{data ? (
				<div className={styles.item_wrapper} onClick={toUserDetail}>
					<div className={styles.item}>
						<img
							className={styles.img1}
							src={
								avatar ||
								'https://cdn-test.kangarooread.com/resource/avatar/default.png'
							}
							alt=''
						/>
						<div className={styles.new_info}>
							<div className={styles.tag_wrapper}>
								<div
									className={styles.new_tab}
									style={{
										background:
											(
												MEMBER_STATUS.find(
													(it) => it.label === groupMemberRole
												) || {}
											).color || '#fff',
										color: '#fff'
									}}>
									{getLabel()}
								</div>
								<div className={styles.new_name}>{nickname || ''}</div>
							</div>
							<div className={styles.date}>{userEnterGroupTime || ''}入群</div>
						</div>
						<i
							className={`iconfont crm-change_point ${styles.point_icon}`}
							onClick={(e) => {
								e.stopPropagation()
								showModal(userId)
							}}
						/>
					</div>
					<div className={styles.line} />
				</div>
			) : null}
			{/* {modalVisible && (
				<div className={styles.modal_wrapper}>
					<div className={styles.point_modal_container}>
						<div className={styles.title}>请输入需要转赠的积分数量</div>
						<input className={styles.point_input} onChange={changeInput} />
					</div>
					<div className={styles.btn_wrapper}>
						<div className={styles.btn} onClick={() => setModalVisible(false)}>
							取消转赠
						</div>
						<div
							className={`${styles.btn} ${styles.active}`}
							onClick={transferPoints}>
							确认转赠
						</div>
					</div>
				</div>
			)} */}
		</>
	)
}
