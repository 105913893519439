import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './index.module.less'
import { env } from '../../lib/conf'

export default function List(props) {
  const { dataSource, hasMore, loaded, next, children } = props
  return (
    <>
      <InfiniteScroll
        dataLength={dataSource.length}
        next={next}
        hasMore={hasMore}
        loader={<div className={styles.loading}>正在努力加载中...</div>}
        scrollThreshold="50px">
        {children}
      </InfiniteScroll>
      {loaded && !hasMore && dataSource.length > 0 ? (
        <div className={styles.loading}>没有更多信息了~</div>
      ) : null}
      {(loaded && dataSource.length === 0) ? (
        <div className={styles.no_earning}>
          <div>
            <img src={`${env.IMG}m-web/record_no_data.png`} alt=""/>
          </div>
          <div>暂时还没有相关信息哦 ~</div>
        </div>
      ) : null}
    </>
  )
}
