/**
 * @description 公共组件-Modal框
 * @author Joe.shen
 * @augments visible boolean 显示
 * @augments options object 对象
 */

import React from 'react'
import './index.less'

export default function Modal(props) {
  const { options, visible } = props
  const {
    title = '',
    content = '',
    okBtnText = '确定',
    cancelBtnText = '取消',
    showCancelBtn = false,
    showModalCloseIcon = true,
    gravity = 0,
    onClose = () => {},
    onCancel = () => {},
    onConfirm = () => {}
  } = options || {}

  return (
    <div className={`modal-wrapper ${visible ? 'show' : ''}`}>
      <div className={`modal ${visible ? 'show' : ''}`}>
        {
          showModalCloseIcon &&  <i className="iconfont crm-close modal-close-icon" onClick={onClose}/>

        }
	      {title && <div className="title">{title}</div>}
        {content ? <div className={`content content-${gravity}`}>
          {content}
        </div> : null}
        <div className="btn-wrapper">
          {showCancelBtn ? <div className="cancel" onClick={onCancel}>{cancelBtnText}</div> : null}
          <div className="confirm" onClick={onConfirm}>{okBtnText}</div>
        </div>
      </div>
    </div>
  )
}
